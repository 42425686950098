import { Heading4, P, TextLink } from '@ovotech/nebula';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom-v5';
import { FootnoteProvider } from '@/src/components';
import {
  PAY_AS_YOU_DRIVE,
  PAY_AS_YOU_DRIVE_VOLVO,
} from '@/src/constants/feature-flags';
import { State } from '@/src/types/State';
import { useFeature } from '@/src/utils/optimizely';
import {
  Container,
  Reason,
  Reasons,
  StyledSubtitle,
  StyledTitle,
} from './OvoDriveAnytimeEligibilityConfirmation.styled';
import { AnalyticsClick, useAnalytics } from '@ovotech/ui-tools';
import { EV } from '@/src/constants/analytics';
import { SMART_BOOKING_URL } from '@/src/constants/endpoints';
import {
  EV_ANYTIME_SMART_CHARGER_LINK,
  SWITCH_TO_OVO_ELECTRICITY,
} from '@/src/constants/links';
import { useCheckEligibilityForPayAsYouDrive } from '@/src/pages/EVs/hooks/useCheckEligibilityForPayAsYouDrive';
import { hasSmartMeter as hasSmartMeterSelector } from '@/src/pages/Renewal/selectors';
import { SkeletonCard } from '@/src/components/SkeletonLoading/SkeletonCard';
import { useEligibilityCheck } from '@monovo/ev-anytime';
import { SmartMeterReadFrequency } from '@/src/pages/EVs/components/EVAnytime/SmartMeterReadFrequency';
import { SolarPanelsIncompatible } from '@/src/pages/EVs/components/EVAnytime/SolarPanelsIncompatible';
import { ReturnToHomePage } from '@/src/components/ReturnToHomePage/ReturnToHomePage';
import { TechnicalGlitch } from '@/src/pages/EVs/components/Common/TechnicalGlitch';
import { ROUTE_EV_ANYTIME } from '@/src/constants/routes';
import { routes } from '@/src/pages/EVs/OvoDriveAnytime';

const Loading = () => {
  return <SkeletonCard />;
};

const ChooseYourCharger = () => (
  <AnalyticsClick
    name={
      EV.ELIGIBILITY
        .EVS_OVO_CHARGE_ANYTIME_OUTCOME_INELIGIBLE_GET_SMART_CHARGER_CLICKED
    }
  >
    <TextLink
      data-event-name={
        'Click-EVS - EVS OVO Drive Anytime Smart charger offer button clicked [Orion Web]'
      }
      href={EV_ANYTIME_SMART_CHARGER_LINK}
      opensInNewWindow
    >
      Choose your EV charger today
    </TextLink>
  </AnalyticsClick>
);

export const OvoDriveAnytimeEligibilityConfirmation = () => {
  const { dispatch: analyticsDispatch } = useAnalytics();

  const history = useHistory();

  const { selectedAccountId: accountId } = useSelector(
    (state: State) => state.user,
  );

  const {
    smartChargerSelection,
    carMake,
    carModel,
    carYear,
    internetAccess,
    solarPanels,
  } = useSelector(({ evAnytime }: State) => evAnytime);

  const hasSmartMeter = useSelector(hasSmartMeterSelector);

  const [payAsYouDriveActive] = useFeature(PAY_AS_YOU_DRIVE);
  const [payAsYouDriveVolvoEnabled] = useFeature(PAY_AS_YOU_DRIVE_VOLVO);

  const {
    ineligibleNotSmartMeter,
    ineligibleInvalidMeterReadFrequency,
    ineligibleOnlyGas,
    isFetching,
    isError,
    ineligibleOther,
  } = useCheckEligibilityForPayAsYouDrive();

  const invalidOther = payAsYouDriveActive && ineligibleOther;
  const ineligibleReasons = useEligibilityCheck({
    make: carMake,
    model: carModel,
    year: Number(carYear),
    ineligibleOnlyGas,
    smartCharger: smartChargerSelection,
    solarPanels,
    ineligibleNotSmartMeter,
    hasSmartMeter,
    ineligibleInvalidMeterReadFrequency,
    ineligibleOther,
    internetAccess,
    payAsYouDriveActive,
    payAsYouDriveVolvoEnabled,
  });

  const eligibleVehicle = !ineligibleReasons.incompatibleCar;
  const isIneligible = ineligibleReasons.numberOfReasons > 0;

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: isIneligible
        ? EV.ELIGIBILITY
            .EVS_OVO_CHARGE_ANYTIME_CONFIRMATION_INELIGIBILITY_VIEWED
        : EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_CONFIRMATION_ELIGIBILITY_VIEWED,
      properties: {
        smartChargerSelection: smartChargerSelection,
        make: carMake,
        model: carModel,
        year: carYear,
        eligibleVehicle: eligibleVehicle,
        hasSolarPanels: solarPanels,
        hasInternetAccess: internetAccess,
        hasSmartMeter: hasSmartMeter,
        hasValidMeterReadFrequency: !ineligibleInvalidMeterReadFrequency,
        hasOvoElectricity: !ineligibleOnlyGas,
      },
    });
    // `dispatch` method is not memoized, and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: isIneligible
        ? 'EVS - EVS OVO Charge Anytime D2V Outcome Ineligible Viewed'
        : 'EVS - EVS OVO Charge Anytime D2V Outcome eligible Viewed',
      properties: {
        smartChargerSelection,
        carMake,
        carModel,
        carYear,
        internetAccess,
        solarPanels,
      },
    });
    // `dispatch` method is not memoized, and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching) {
    return <Loading />;
  }

  if (isError) {
    return <TechnicalGlitch />;
  }

  const renderConfirmation = () => {
    if (!isIneligible) {
      history.push(ROUTE_EV_ANYTIME + routes.consent);
    }

    const smartMeterBookingRoute = `${SMART_BOOKING_URL}?accountId=${accountId}&utm_source=anytimeEligibility&utm_medium=MyOVO&utm_campaign=smart-meter-booking`;

    return (
      <Container>
        <Reasons>
          {invalidOther ? (
            <Reason data-testid="invalid-other">
              <StyledTitle>
                Sorry, we can’t bring you Charge Anytime at the moment.
              </StyledTitle>
              <P>
                We’re working hard to make Charge Anytime available to more
                customers.
              </P>
              <P>
                Please check back soon to see if Charge Anytime is available for
                you.
              </P>
              <ReturnToHomePage />
            </Reason>
          ) : (
            <Reason>
              <StyledTitle>Your setup isn’t compatible right now.</StyledTitle>
              <StyledSubtitle>
                {ineligibleReasons.numberOfReasons > 1
                  ? `Here are ${ineligibleReasons.numberOfReasons} things you can do to get Charge Anytime`
                  : 'Here’s what you can do to get Charge Anytime'}
              </StyledSubtitle>
            </Reason>
          )}
          {ineligibleReasons?.notSmartMeter && (
            <Reason data-testid="smart-meter-offer">
              <Heading4>Save money with a smart meter</Heading4>
              <P>
                Charge Anytime needs a smart meter that sends half-hourly
                readings to connect your EV or charger to your OVO account, and
                the grid. If you don’t already have an electricity smart meter,
                we’ll install one for free.
              </P>
              <AnalyticsClick
                name={
                  EV.ELIGIBILITY
                    .EVS_OVO_CHARGE_ANYTIME_OUTCOME_INELIGIBLE_GET_SMART_METER_CLICKED
                }
              >
                <TextLink
                  data-event-name={
                    'EVS - EVS OVO Charge Anytime D2V Outcome Ineligible Get Smart Meter clicked'
                  }
                  opensInNewWindow
                  href={smartMeterBookingRoute}
                >
                  Get a smart meter
                </TextLink>
              </AnalyticsClick>
            </Reason>
          )}
          {ineligibleReasons?.onlyGasSupply && (
            <Reason data-testid={'gas-only'}>
              <Heading4>Get electricity with OVO</Heading4>
              <P>
                At the moment you’re on a gas-only plan. Switch to OVO for your
                electricity and add Charge Anytime to your plan for free.
              </P>
              <AnalyticsClick
                name={
                  EV.ELIGIBILITY
                    .EVS_OVO_CHARGE_ANYTIME_OUTCOME_INELIGIBLE_GET_ELECTRICITY_CLICKED
                }
              >
                <TextLink
                  data-event-name={
                    'EVS - EVS OVO Drive Anytime Electricity offer button clicked'
                  }
                  href={SWITCH_TO_OVO_ELECTRICITY}
                  opensInNewWindow
                >
                  Get an electricity quote
                </TextLink>
              </AnalyticsClick>
            </Reason>
          )}
          {ineligibleReasons?.doesntChargeEvAtHome && (
            <>
              <Reason data-testid="smart-charger-offer">
                <Heading4>You don't charge your EV at home</Heading4>
                <P>
                  Make the most of your EV with the next-generation Indra Smart
                  PRO or Ohme chargers – now available at an exclusive price for
                  OVO members.
                </P>
                <ChooseYourCharger />
              </Reason>
            </>
          )}
          {ineligibleReasons?.incompatibleCar && (
            <Reason data-testid="incompatible-car">
              <Heading4>Connect through a smart EV charger</Heading4>
              <P>
                Your EV isn’t compatible right now, but you could still get
                Charge Anytime through a compatible smart EV charger – and we
                can install it for you.
              </P>
              <ChooseYourCharger />
            </Reason>
          )}
          {ineligibleReasons?.hasSolarPanels && (
            <Reason data-testid="has-solar-panels">
              <SolarPanelsIncompatible />
            </Reason>
          )}
          {ineligibleReasons?.hasNoInternetAccess && (
            <Reason data-testid="has-no-internet-access">
              <Heading4>
                Make sure your EV connects to the internet while smart charging
              </Heading4>
              <P>
                If your EV has an internet connection, make sure you park where
                you can get a clear signal.
              </P>
              <P>
                If your EV doesn’t have an internet connection, you could still
                get Charge Anytime through one of our compatible smart EV
                chargers – and we’ll install it too.
              </P>
              <ChooseYourCharger />
            </Reason>
          )}
          {ineligibleReasons?.invalidMeterReadFrequency && (
            <Reason data-testid="half-hourly-reads-offer">
              <SmartMeterReadFrequency />
            </Reason>
          )}
        </Reasons>
      </Container>
    );
  };

  return (
    <FootnoteProvider markerType="number">
      <div>{renderConfirmation()}</div>
    </FootnoteProvider>
  );
};
