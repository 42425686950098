import { Col, Row } from '@ovotech/nebula';
import React, { ReactNode } from 'react';
import Media from 'react-media';
import {
  SideNavigationWrapper,
  NavAwareCol,
} from './SideNavigationLayout.styled';
import SideNavigationContainer from '@/src/components/Navigation/SideNavigationContainer';
import { breakpoints } from '@/src/utils/mediaQuery';

export const SideNavigationLayout = ({ children }: { children: ReactNode }) => (
  <Row data-testid="side-navigation-layout">
    <Media query={`${breakpoints.desktopNav}`}>
      {matches =>
        matches ? (
          <NavAwareCol as={Col} desktopNav={3}>
            <SideNavigationWrapper>
              <SideNavigationContainer />
            </SideNavigationWrapper>
          </NavAwareCol>
        ) : null
      }
    </Media>
    <NavAwareCol as={Col} id="main" desktopNav={9}>
      {children}
    </NavAwareCol>
  </Row>
);
