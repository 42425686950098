export const hubTestIDs = {
  carbonComparison: 'carbon-comparison',
  breakdownWidget: 'breakdown-widget',
  breakdownYourHome: 'breakdown-your-home',
  breakdownSimilarHome: 'breakdown-similar-home',
  breakdownCTA: 'breakdown-cta',
  carbonIntensityWidget: 'carbon-intensity-widget',
  nonSmartTips: 'non-smart-tips',
  halfHourlyOptIn: 'half-hourly-opt-in',
  halfHourlyOptInDefaultText: 'half-hourly-opt-in-default',
  halfHourlyOptInSuccessText: 'half-hourly-opt-in-success',
  halfHourlyOptInFailureText: 'half-hourly-opt-in-failure',
};
