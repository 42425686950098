import React, { Fragment } from 'react';
import {
  StyledEvEverywhereIcon,
  StyledGreenBundleIcon,
  StyledGreenElectricityIcon,
  StyledGreenGasIcon,
  StyledOvoFoundationIcon,
  StyledBPPulseIcon,
  StyledOvoHomeplanIcon,
  StyledAnytimeIcon,
} from './ProductIconAndLabel.styled';
import { PRODUCT_DISPLAY_NAMES, PRODUCT_IDS } from '@/src/constants/products';
import { ProductId } from '@/src/types/Products';
import { isChargeAnytimeProduct } from '@/src/pages/EVs/hooks/useChargeAnytimeBoltonState';

type Props = { id: ProductId };
export const ProductIconAndLabel = ({ id }: Props) => (
  <Fragment>
    <Icon id={id} /> {PRODUCT_DISPLAY_NAMES[id]}
  </Fragment>
);

type IconProps = { id: ProductId };
const iconComponents = {
  [PRODUCT_IDS.greenBundle]: <StyledGreenBundleIcon aria-hidden="true" />,
  [PRODUCT_IDS.evBundle]: <StyledEvEverywhereIcon aria-hidden="true" />,
  [PRODUCT_IDS.greenElectricity]: (
    <StyledGreenElectricityIcon aria-hidden="true" />
  ),
  [PRODUCT_IDS.greenGas]: <StyledGreenGasIcon aria-hidden="true" />,
  [PRODUCT_IDS.polarPlus]: <StyledBPPulseIcon aria-hidden="true" />,
  [PRODUCT_IDS.ovoFoundation]: <StyledOvoFoundationIcon aria-hidden="true" />,
  [PRODUCT_IDS.boilerCover]: <StyledOvoHomeplanIcon aria-hidden="true" />,
};

function Icon({ id }: IconProps) {
  const iconComponent = iconComponents[id];

  if (iconComponent) {
    return iconComponent;
  } else if (isChargeAnytimeProduct(id)) {
    return <StyledAnytimeIcon aria-hidden="true" />;
  } else {
    return null;
  }
}
