import {
  Card,
  Heading4,
  Margin,
  P,
  Strong,
  Notification,
} from '@ovotech/nebula';
import Big from 'big.js';
import React, { Fragment } from 'react';

import { ForecastOption } from '../constants';
import { LiveRegion, ProjectedBalanceChart } from '@/src/components';
import {
  CurrencyValueResponse,
  ProjectedCostsResponse,
} from '@/src/types/Response';
import { recDdIsHigherThanMinimum } from '@/src/utils/shouldShowRecommendedDD';

import { formatCurrencyValue, formatPounds } from '@/src/utils/money';

type Props = {
  balance: CurrencyValueResponse;
  projectedCosts: ProjectedCostsResponse;
  newDirectDebitAmount: string;
  selectedForecastOption: ForecastOption;
  payingRightAmount: boolean;
  isVariable: boolean;
};

const nullSafeLength = (array: any[] | null) => {
  return array ? array.length : 0;
};

const getChartData = (
  balance: CurrencyValueResponse,
  {
    electricity,
    gas,
    boltOns,
    recommendedDirectDebit: { remainingDirectDebits },
  }: ProjectedCostsResponse,
  newDirectDebit: CurrencyValueResponse,
) => {
  if (electricity == null && gas == null && boltOns == null) {
    return [];
  }

  const months = Math.max(
    nullSafeLength(electricity),
    nullSafeLength(gas),
    nullSafeLength(boltOns),
  );

  const totalCosts = [...Array(months)].map((_, i) => {
    const electricityData = electricity && electricity[i];
    const gasData = gas && gas[i];
    const boltOnData = boltOns && boltOns[i];

    return Big(electricityData ? electricityData?.cost?.amount : 0)
      .plus(gasData ? gasData?.cost?.amount : 0)
      .plus(boltOnData ? boltOnData?.cost?.amount : 0);
  });

  const firstMonthWithCostIndex = totalCosts.findIndex(e => !e.eq(0));

  const firstDataDate = (index: number) => {
    const fuelData =
      (electricity || []).length > (gas || []).length ? electricity : gas;

    const data =
      (boltOns || []).length > (fuelData || []).length ? boltOns : fuelData;

    return data![index].date;
  };

  return totalCosts
    .reduce<Array<{ date: string; balance: Big }>>((acc, cost, index) => {
      // skip months with no cost
      const date = firstDataDate(index);
      if (index < firstMonthWithCostIndex) {
        acc.push({ date, balance: Big(0) });
        return acc;
      }

      const startingBalance =
        index === firstMonthWithCostIndex
          ? Big(balance ? balance?.amount : 0)
          : acc[index - 1].balance;

      const firstMonthWithDD =
        firstMonthWithCostIndex +
        (remainingDirectDebits.includesCurrentMonth ? 0 : 1);
      const lastMonthWithDD = firstMonthWithDD + remainingDirectDebits.count;

      const hasDirectDebit =
        index >= firstMonthWithDD && index < lastMonthWithDD;

      const monthBalance = startingBalance
        .plus(hasDirectDebit ? newDirectDebit?.amount : 0)
        .minus(cost);

      acc.push({ date, balance: monthBalance });
      return acc;
    }, [])
    .filter(e => !e.balance.eq(0));
};

type AdvicePanelProps = {
  newDirectDebit: CurrencyValueResponse;
  selectedForecastOption: string;
  payingRightAmount: boolean;
  projectedCosts: ProjectedCostsResponse;
  predictedBalance: Big;
  isVariable: boolean;
};

const AdvicePanel = ({
  predictedBalance,
  newDirectDebit,
  projectedCosts: { recommendedDirectDebit },
  payingRightAmount,
  selectedForecastOption,
}: AdvicePanelProps) => {
  const inDebt = predictedBalance
    ? Math.round(Number(predictedBalance)) < 0
    : false;

  const liveContent = (
    <Heading4 style={{ fontWeight: 'normal' }}>
      A monthly payment of: <Strong>{formatPounds(newDirectDebit)}</Strong>
      <br /> would bring your balance to:{' '}
      <Strong>
        {predictedBalance?.round(0, 2).gte(0)
          ? `£${predictedBalance?.round(0, 2)}`
          : `-£${predictedBalance?.round(0, 2).abs()}`}
      </Strong>
      <br />
      {selectedForecastOption === ForecastOption.BY_31_OF_MARCH
        ? 'by 31 March'
        : 'by the end of your contract'}
      .
    </Heading4>
  );

  const statusList = recommendedDirectDebit.state || [];

  if (statusList.includes('AccountInLoss')) {
    return (
      <Notification
        id="advice-notification"
        variant={inDebt ? 'error' : 'info'}
      >
        <P>
          <Strong>
            Because you’re switching away, we’re not able to show you a payment
            recommendation.
          </Strong>
        </P>
      </Notification>
    );
  } else if (
    statusList.includes('BillingOnHold') ||
    statusList.includes('AmountOverSensibleUpperLimit') ||
    recommendedDirectDebit === null ||
    !predictedBalance
  ) {
    return (
      <Notification
        id="advice-notification"
        variant={inDebt ? 'error' : 'info'}
      >
        <P>
          <Strong>
            Sorry, we’re not able to show you a payment recommendation right
            now.
          </Strong>
          We’re working super hard to fix this.
        </P>
      </Notification>
    );
  } else if (statusList.includes('RecentlyOnboarded')) {
    return (
      <Notification
        id="advice-notification"
        variant={inDebt ? 'error' : 'info'}
      >
        <P>
          <Strong>
            Once you’ve been with us for 8 weeks, we can start showing you
            payment recommendations.
          </Strong>
        </P>
        <P>
          By that point, we should have a nice, clear picture of your monthly
          energy use.
        </P>
      </Notification>
    );
  } else {
    return (
      <Notification
        id="advice-notification"
        variant={inDebt ? 'error' : 'info'}
      >
        <LiveRegion>{liveContent}</LiveRegion>
        {liveContent}
        {payingRightAmount ? (
          <P>
            We think you’re paying the right amount for the energy you’re using.
          </P>
        ) : !recDdIsHigherThanMinimum(recommendedDirectDebit.friendlyAmount) ? (
          <P>
            We recommend you change your Direct Debit to <Strong>£5</Strong> –
            our minimum amount.
          </P>
        ) : (
          <P>
            A monthly payment of{' '}
            <Strong>
              {formatCurrencyValue(
                { unit: 'GBP', dp: 0, ceil: true },
                recommendedDirectDebit.friendlyAmount!,
              )}
            </Strong>{' '}
            would keep your balance healthy.
          </P>
        )}
      </Notification>
    );
  }
};

export const ProjectedBalance = ({
  balance,
  projectedCosts,
  newDirectDebitAmount,
  selectedForecastOption,
  payingRightAmount,
  isVariable,
}: Props) => {
  const newDirectDebit: CurrencyValueResponse = {
    amount: newDirectDebitAmount || '0',
    currencyUnit: 'GBP',
  };

  const chartData = getChartData(balance, projectedCosts, newDirectDebit);

  return (
    <Fragment>
      <AdvicePanel
        data-testid="dd-calc-projected-balance"
        newDirectDebit={newDirectDebit}
        selectedForecastOption={selectedForecastOption}
        projectedCosts={projectedCosts}
        predictedBalance={chartData[chartData.length - 1]?.balance}
        payingRightAmount={payingRightAmount}
        isVariable={isVariable}
      />
      <Margin top={2}>
        <Card>
          <ProjectedBalanceChart data={chartData} />
        </Card>
      </Margin>
    </Fragment>
  );
};
