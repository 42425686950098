import {
  Heading2,
  Heading3,
  Heading4,
  P,
  Strong,
  TextLink,
} from '@ovotech/nebula';
import { useAnalytics } from '@ovotech/ui-tools';
import React, { useEffect } from 'react';
import Media from 'react-media';
import {
  DesktopContainer,
  LeftContent,
  MobileContainer,
  RightContent,
} from './EVAnytimeDownload.styled';
import { EV } from '@/src/constants/analytics';
import { breakpoints } from '@/src/utils/mediaQuery';
import {
  EV_ANYTIME_APP_STORE_URL,
  EV_ANYTIME_GOOGLE_PLAY_URL,
  CHARGE_ANYTIME_TERMS_AND_CONDITIONS_LINK,
} from '@/src/constants/links';
import { ReactComponent as AppStoreBadge } from '@/src/resources/apple-store-badge.svg';
import { ReactComponent as EVAnytimeHeroVector } from '@/src/resources/ev-anytime-circle.svg';
import EVAnytimeDownloadHeroMobile from '@/src/resources/ev-anytime-download-hero-mobile.png';
import EVAnytimeDownloadHero from '@/src/resources/ev-anytime-download-hero.png';
import { ReactComponent as GooglePlayBadge } from '@/src/resources/google-play-badge.svg';
import { useSelector } from 'react-redux';
import { State } from '@/src/types/State';

export const EVAnytimeDownload = () => {
  const { dispatch } = useAnalytics();
  const { smartChargerSelection, carMake, carModel, carYear, solarPanels } =
    useSelector(({ evAnytime }: State) => evAnytime);

  useEffect(() => {
    dispatch({
      type: 'view',
      name: EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_APP_DOWNLOAD_VIEWED,
      properties: {
        smartChargerSelection,
        carMake,
        carModel,
        carYear,
        solarPanels,
      },
    });
    // `dispatch` method is not memoized and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: 'view',
      name: 'EVS - EVS OVO Charge Anytime D2V App Download Viewed',
      properties: {
        smartChargerSelection,
        carMake,
        carModel,
        carYear,
        solarPanels,
      },
    });
    // `dispatch` method is not memoized and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendMixpanelClickEvent = (OS: 'IOS' | 'Android') => {
    dispatch({
      type: 'click',
      name: EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_APP_DOWNLOAD_CTA_CLICKED,
      properties: { CTA: OS },
    });

    dispatch({
      type: 'click',
      name: 'EVS - EVS OVO Charge Anytime D2V App Download CTA clicked',
      properties: { CTA: OS },
    });
  };

  const renderDownloadAppLinks = () => (
    <>
      <a
        target="_blank"
        rel="noreferrer"
        data-testid="app-store-badge"
        href={EV_ANYTIME_APP_STORE_URL}
        onClick={() => sendMixpanelClickEvent('IOS')}
      >
        <AppStoreBadge style={{ height: '50px' }} />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        data-testid="google-play-badge"
        href={EV_ANYTIME_GOOGLE_PLAY_URL}
        onClick={() => sendMixpanelClickEvent('Android')}
      >
        <GooglePlayBadge style={{ height: '50px' }} />
      </a>
    </>
  );

  return (
    <Media query={`${breakpoints.mediumUp}`}>
      {matches =>
        matches ? (
          <DesktopContainer>
            <LeftContent>
              <Heading2>Great news - you can get Charge Anytime!</Heading2>
              <Heading4 style={{ color: '#555C6B', margin: 0 }}>
                You're a few steps away from intelligent charging.
              </Heading4>
              <Heading4 style={{ marginBottom: 0 }}>Get it now:</Heading4>
              {renderDownloadAppLinks()}
              <Strong>We'll guide you through set-up in the app.</Strong>
              <P>
                View{' '}
                <TextLink href={CHARGE_ANYTIME_TERMS_AND_CONDITIONS_LINK}>
                  Charge Anytime terms and conditions
                </TextLink>
                .
              </P>
            </LeftContent>
            <RightContent>
              <EVAnytimeHeroVector />
              <img src={EVAnytimeDownloadHero} alt="EV Anytime Download Hero" />
            </RightContent>
          </DesktopContainer>
        ) : (
          <MobileContainer>
            <Heading3>Great news - you can get Charge Anytime!</Heading3>
            <img
              src={EVAnytimeDownloadHeroMobile}
              alt="EV Anytime Download Hero"
            />
            <P>
              You're a few steps away from intelligent charging. Get it now. To
              get started, download the Charge Anytime app.
            </P>
            {renderDownloadAppLinks()}
            <P>We'll guide you through set-up in the app.</P>
          </MobileContainer>
        )
      }
    </Media>
  );
};
