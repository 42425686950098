import { Strong } from '@ovotech/nebula';
import React, { useEffect } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { useTheme } from 'styled-components';
import { ChartContainer, ToolTipContainer } from './TenSecondChart.styled';
import { TenSecondSample } from './Types';
import {
  chartTickDateFormatter,
  getTimeTicks,
  toolTipLabelDateFormatter,
  wattsToKWhFormatter,
} from './util';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import AnalyticsService from '@/src/services/analyticsService';

type TenSecondChartProps = {
  width?: number; // just for unit tests
  data: TenSecondSample[];
};

export const TenSecondChart = ({ width, data }: TenSecondChartProps) => {
  const theme = useTheme();

  useEffect(() => {
    AnalyticsService.dispatch(ENERGY_INSIGHTS.VIEWED_TEN_SECOND_GRAPH);
  }, []);

  return (
    <div aria-label="10 second energy use graph" data-testid="ten-second-chart">
      <ChartContainer>
        <ResponsiveContainer width={width || '100%'} height={450}>
          <AreaChart
            data={data}
            margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
          >
            <Area
              dataKey="reading"
              stroke={theme.colors.brand.electricity.base}
              fill="rgba(231, 162, 52, 0.6)"
              type="monotone"
              isAnimationActive={false}
            />
            <CartesianGrid
              strokeDasharray="5"
              stroke="rgba(100, 100, 100, 0.08)"
              vertical={false}
            />
            <XAxis
              dataKey="readingTime"
              tickFormatter={chartTickDateFormatter}
              type="number"
              scale="time"
              domain={['auto', 'auto']}
              ticks={getTimeTicks(data)}
            />
            <YAxis
              type="number"
              dataKey="reading"
              tickFormatter={wattsToKWhFormatter}
              tickLine={true}
              scale={'linear'}
              domain={[0, 'dataMax + 100']}
              label={<KiloWattLabel />}
            ></YAxis>
            <Tooltip content={ToolTip} isAnimationActive={false} />
          </AreaChart>
        </ResponsiveContainer>
      </ChartContainer>
    </div>
  );
};

function ToolTip(props: TooltipProps<number, number>) {
  const payload = props.payload || [];
  if (payload[0] && payload[0].payload && props.active) {
    const { reading, readingTime } = payload[0].payload;
    return (
      <ToolTipContainer>
        {toolTipLabelDateFormatter(readingTime)}
        <Strong>{wattsToKWhFormatter(reading)}kW</Strong>
      </ToolTipContainer>
    );
  }
  return null;
}

function KiloWattLabel() {
  return (
    // foreignObject, width, and height are needed because reasons.
    // Label won't render otherwise
    <foreignObject x={25} y={20} width={100} height={100}>
      <Strong>kW</Strong>
    </foreignObject>
  );
}
