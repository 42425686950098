import { TextLink } from '@ovotech/nebula';
import React from 'react';
import {
  ABS_PATH_TO_ZERO_TERMS_LINK,
  ABS_TERMS_LINK,
} from '../constants/constants';
import { FootnoteReferencedContent } from '@/src/components';
import { BOILER_COVER_REWARD_TERMS } from '@/src/constants/links';

const annualBoilerServiceOffer = (
  <>
    Path to Zero Offer: to be eligible for our £1 a month offer, you must be an
    existing OVO Energy customer and meet the eligibility criteria set out in
    our{' '}
    <TextLink href={ABS_TERMS_LINK} opensInNewWindow>
      Core terms and conditions
    </TextLink>
    . This offer is available until Thursday 30 November 2023. We reserve the
    right to amend or withdraw this Offer and these Offer Terms at any time.
    Find out more by viewing our{' '}
    <TextLink href={ABS_PATH_TO_ZERO_TERMS_LINK} opensInNewWindow>
      terms and conditions
    </TextLink>
    .
  </>
);

export const footnoteBoilerServicePromo: FootnoteReferencedContent = {
  id: 'BoilerServicePromo',
  body: [annualBoilerServiceOffer],
};

const homeRecoverDisclaimer = (
  <>
    Terms, conditions, eligibility criteria, and exclusions and cancellation
    charges apply.
  </>
);

export const footnoteHomeRecoverPromo: FootnoteReferencedContent = {
  id: 'HomeRecoverPromo',
  body: [homeRecoverDisclaimer],
};

const homeRecoverQ1Savings = (
  <>
    Saving based on our zero excess cover. £50 excess still applies to taps and
    toilet repairs. Offer ends 21/03/24.
  </>
);

const homeRecoverQ1Disclaimer = (
  <>Terms, exclusions and cancellation fees apply.</>
);

export const footnoteHomeRecoverQ1PromoSavings: FootnoteReferencedContent = {
  id: 'HomeRecoverPromoSavings',
  body: [homeRecoverQ1Savings],
};
export const footnoteHomeRecoverQ1PromoDisclaimer: FootnoteReferencedContent = {
  id: 'HomeRecoverPromoDisclaimer',
  body: [homeRecoverQ1Disclaimer],
};

const freeForFourMonthsNoClaims = (
  <>
    No claims can be made in the first 30 days. Terms, conditions, eligibility
    criteria, exclusions and cancellation charges apply. Offer available to new
    customers only. Offer expires 12/12/2023.
  </>
);

export const footnoteFreeForFourMonthsNoClaims: FootnoteReferencedContent = {
  id: 'FreeForFourMonthsNoClaims',
  body: [freeForFourMonthsNoClaims],
};

const freeForFourMonthsTerms = (
  <>
    The £128 claim is based on a customer on our £0 excess Complete Plan,
    costing £32 per month. Note: a £50 excess still applies to external drains
    and taps and toilets claims.
  </>
);

export const footnoteFreeForFourMonthsTerms: FootnoteReferencedContent = {
  id: 'FreeForFourMonthsNoTerms',
  body: [freeForFourMonthsTerms],
};

const FreeForFourMonthsEnergyCredit = (
  <>
    To be eligible for this credit, you must meet the eligibility criteria set
    out in the{' '}
    <TextLink href={BOILER_COVER_REWARD_TERMS} opensInNewWindow>
      reward offer terms.
    </TextLink>
  </>
);

export const footNotesFreeForFourMonthsEnergyCredit: FootnoteReferencedContent =
  {
    id: 'footNotesFreeForFourMonthsEnergyCredit',
    body: [FreeForFourMonthsEnergyCredit],
  };

const freeForSixMonthsNoClaims = (
  <>
    No claims can be made in the first 30 days. Terms, conditions, eligibility
    criteria, exclusions and cancellation charges apply. Offer available to new
    customers only. Offer expires 30/11/2023.
  </>
);

export const footnoteFreeForSixMonthsNoClaims: FootnoteReferencedContent = {
  id: 'FreeForSixMonthsNoClaims',
  body: [freeForSixMonthsNoClaims],
};

const ohsQ1PromoOfferNoClaims = (
  <>
    No claims can be made in the first 30 days. 12 month contract. Terms apply.
    Offer ends 21/03/24.
  </>
);

export const footnoteOHSQ1PromoOfferNoClaims: FootnoteReferencedContent = {
  id: 'ohsQ1PromoOfferNoClaims',
  body: [ohsQ1PromoOfferNoClaims],
};

const ohsQ2PromoOfferNoClaims = (
  <>
    Offer ends 25/07/24. Customers signing up by offer end date of 25/07/24 will
    be offered a service date prior to 01/12/24
  </>
);

export const footnoteABSQ2Promo: FootnoteReferencedContent = {
  id: 'ohsQ2PromoOfferNoClaims',
  body: [ohsQ2PromoOfferNoClaims],
};

const ohsFixedQ1PromoOfferNoClaims = (
  <>
    Terms and eligibility criteria apply. 6 months half price offer ends
    21/03/2024. No claims accepted in the first 30 days.
  </>
);

export const footnoteOHSFixedQ1PromoOfferNoClaims: FootnoteReferencedContent = {
  id: 'ohsFixedQ1PromoOfferNoClaims',
  body: [ohsFixedQ1PromoOfferNoClaims],
};

const ohsFixedQ1PromoOfferSavings = (
  <>
    Saving based on 6 months of our Complete cover plan without excess and
    energy credit combined.
  </>
);

export const footnoteOHSFixedQ1PromoOfferSavings: FootnoteReferencedContent = {
  id: 'ohsFixedQ1PromoOfferSavings',
  body: [ohsFixedQ1PromoOfferSavings],
};
