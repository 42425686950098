import { useMutation } from 'react-query';
import { apiService } from '@ovotech/energy-cx';
import { createChargeAnytimeActivationUrl } from '@/src/constants/endpoints';
import { SmartChargers } from '@monovo/ev-anytime';

interface ChargeAnytimeAcquisitionBody {
  make: string;
  model?: string;
  year?: string;
  hasInternetAccess: boolean;
  hasSolarPanels: boolean;
  charger: SmartChargers;
}

export const useChargeAnytimeAcquisitionMutation = () => {
  return useMutation({
    mutationFn: (body: ChargeAnytimeAcquisitionBody) => {
      return apiService.post({
        url: createChargeAnytimeActivationUrl(),
        body,
      });
    },
  });
};
