import { TextLink } from '@ovotech/nebula';
import React from 'react';

import { EXTERNAL_ROUTE_HELP_INTEREST_REWARD_CALCULATION } from '@/src/constants/routes';

export const InterestRewardLink = () => {
  return (
    <TextLink
      href={EXTERNAL_ROUTE_HELP_INTEREST_REWARD_CALCULATION}
      opensInNewWindow
    >
      Interest Reward
    </TextLink>
  );
};
