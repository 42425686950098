import { PRODUCT_IDS } from '@/src/constants/products';
import { chargeAnytimeProducts } from '@/src/pages/EVs/hooks/useChargeAnytimeBoltonState';
import { ProductId } from '@/src/types/Products';
import { ProductsActivated, ProductsUpdate } from '@/src/types/State';

const supportedProducts: Array<ProductId> = [
  PRODUCT_IDS.greenElectricity,
  PRODUCT_IDS.greenGas,
  PRODUCT_IDS.greenBundle,
  PRODUCT_IDS.evBundle,
  PRODUCT_IDS.ovoFoundation,
  PRODUCT_IDS.greenerEnergy,
  PRODUCT_IDS.greenerElectricity,
  ...chargeAnytimeProducts,
];

const selector = (
  productsActivated: ProductsActivated,
  cancellation: null | ProductsUpdate,
) => ({
  boltons:
    productsActivated?.data?.boltons?.filter(b => {
      return supportedProducts.includes(b.productId) && b.status === 'Active';
    }) || [],
  bundle:
    productsActivated?.data?.bundle &&
    supportedProducts.includes(productsActivated.data.bundle.productId)
      ? productsActivated.data.bundle
      : undefined,
  cancellationStates: cancellation || {},
  showSuccessMessage: Boolean(
    cancellation &&
      Object.keys(cancellation).some(
        // @ts-ignore yep, object.keys iterates keyof. surprise surprise TS
        (key: keyof ProductsUpdate) =>
          cancellation && cancellation[key] && cancellation[key]!.success,
      ),
  ),
});

export default selector;
