import { addSeconds, startOfMinute } from 'date-fns';
import {
  TenSecondDataResponse,
  toTenSecondSample,
} from '../../src/pages/EnergyInsights/pages/Hub/Components/TenSecondChart/Types';

export const createTenSecondDataResponse = (
  date: Date,
): TenSecondDataResponse => ({
  data: Array(180)
    .fill(null)
    .map((_, index) => ({
      readingTime: addSeconds(date, index * 10).toISOString(),
      reading: Math.floor(Math.random() * 100 + 100), // random [100,200]
    })),
});

export const getTenSecondData = (response: TenSecondDataResponse) =>
  response.data.map(toTenSecondSample);

export const tenSecondData = getTenSecondData(
  createTenSecondDataResponse(startOfMinute(new Date())),
);
