import { P } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import React from 'react';

import { InterestRewardContent } from './InterestRewardContent';
import { useInterestRewardQuery } from '@/src/api';

export const InterestRewardStatus = () => {
  const { accountId } = useAccountContext();
  const { data, isError, isLoading } = useInterestRewardQuery(accountId);

  const interestReward = data?.billingSummary?.interestReward;

  if (isError) return <P>Something has gone wrong.</P>;
  if (isLoading || !interestReward) return null;

  return (
    <InterestRewardContent percentage={interestReward?.currentPercentage} />
  );
};
