import { Card, Heading4 } from '@ovotech/nebula';
import React from 'react';
import Media from 'react-media';
import styled from 'styled-components';
import CarbonIntensityCurrent from './CurrentIntensity/CurrentIntensity';
import { carbonIntensityAssets } from './CurrentIntensity/CurrentIntensity.assets';
import { StyledCarbonIntensityBanner } from './CurrentIntensity/CurrentIntensity.styled';
import CarbonIntensityFooter from './Footer/CarbonIntensityFooter';
import ForecastGraphSlideshow from './ForecastGraph/ForecastGraphSlideshow';
import { CarbonIntensityResponse } from '@/src/types/Response';
import { breakpoints } from '@/src/utils/mediaQuery';

type Props = CarbonIntensityResponse;

const StyledCard = styled(Card)`
  padding: 0;
`;

const CarbonIntensityComponent: React.ComponentType<any> = ({
  current,
  forecast,
}: Props) => (
  <StyledCard>
    <div data-testid="carbon-intensity-banner">
      <StyledCarbonIntensityBanner intensity={current}>
        <Heading4>{carbonIntensityAssets[current].bannerText}</Heading4>
      </StyledCarbonIntensityBanner>
    </div>
    <CarbonIntensityCurrent intensity={current} />
    <Media query={breakpoints.smallDown}>
      {isMobile => (
        <ForecastGraphSlideshow forecastData={forecast} isMobile={isMobile} />
      )}
    </Media>
    <CarbonIntensityFooter />
  </StyledCard>
);

export default CarbonIntensityComponent;
