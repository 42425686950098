import { PlansResponse } from '@ovotech/energy-cx';
import {
  P,
  CTALink,
  PrimaryCTALink,
  Heading4,
  Ul,
  Li,
  Strong,
  TextLink,
  Margin,
  Heading3,
  Small,
  Card,
} from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom-v5';
import { AnytimeNotEligibleSection } from '../../components/AnytimeNotEligible/AnytimeNotEligibleSection';
import { EVAnytimeHeader } from '../../components/EVAnytime';
import { useCheckEligibilityForAnytime } from '../../hooks/useCheckEligibilityForAnytime';
import { roundTo2DP } from '@/src/utils/number';
import { EVAnytimeFAQ } from './EVAnytimeFAQ';
import {
  StyledFeaturesCard,
  StyledFeaturesTextWrapper,
  StyledFeaturesText,
  StyledFeaturesImage,
  ButtonGroupWrapper,
  ChargeSmartCard,
  StyledFeaturesDiv,
  KaluzaCard,
  KaluzaCardBody,
  ChargeSmartTextWrapper,
  ChargeSmartImageWrapper,
  StyledFeaturesImageWrapper,
  KaluzaImageWrapper,
  StyledTermsCheckbox,
} from './EVAnytimeHomepage.styled';
import { useAnytimeBoltonPricingQuery, usePlansQuery } from '@/src/api';
import { PageWithNavigation } from '@/src/components';
import { ButtonGroup } from '@/src/components/ButtonGroup/ButtonGroup';
import { EV } from '@/src/constants/analytics';
import { MY_EV_PAGE_ALPHA } from '@/src/constants/feature-flags';
import { ROUTE_EV_ANYTIME, ROUTE_EV } from '@/src/constants/routes';
import { State } from '@/src/types/State';
import { useFeature } from '@/src/utils/optimizely';
import {
  OVO_ENERGY_TERMS,
  CHARGE_ANYTIME_TERMS_AND_CONDITIONS_LINK,
} from '@/src/constants/links';
import { routes } from '@/src/pages/EVs/OvoDriveAnytime';
import FeaturesCoverImageMobile from '@/src/resources/anytime-cover-image-mobile.png';
import FeaturesCoverImage from '@/src/resources/anytime-cover-image.png';
import ChargeSmartIcon from '@/src/resources/charge-money-icon.png';
import EVChargingImageMobile from '@/src/resources/ev-charging-mobile.png';
import { ReactComponent as KaluzaIcon } from '@/src/resources/kaluza-dark-icon.svg';
import EVChargingImage from '@/src/resources/ev-charging.png';

export const EVAnytimeHomepage = () => {
  const accountId = useSelector((state: State) => state.user.selectedAccountId);
  const plans = usePlansQuery(accountId);

  const [hasMyEv] = useFeature(MY_EV_PAGE_ALPHA);
  const history = useHistory();
  const { smartMeter, hasAnytimeBolton, hasSmartCharger, isFetching } =
    useCheckEligibilityForAnytime();
  const anytimeBoltonPricing = useAnytimeBoltonPricingQuery();

  const { smartChargerSelection } = useSelector(
    ({ evAnytime }: State) => evAnytime,
  );

  const [consent, setConsent] = useState(false);
  const [consentError, setConsentError] = useState(false);

  if (plans.status !== 'success' || plans.isFetching || plans.isError)
    return null;

  if (hasAnytimeBolton) {
    history.push(ROUTE_EV);
  }

  const isEligible = smartMeter && !hasAnytimeBolton && hasSmartCharger;

  const goToConsentPage = () => {
    if (consent) {
      setConsentError(false);
      history.push(ROUTE_EV_ANYTIME + routes.consent);
    } else {
      setConsentError(true);
    }
  };
  const goToEligibilityPage = () => {
    history.push(ROUTE_EV_ANYTIME + routes.eligibility);
  };
  let unitRate: string | undefined = '';
  const { electricity } = plans.data as PlansResponse;
  if (electricity.length) {
    unitRate = electricity[0].unitRates.find(
      unitRate => unitRate.name === 'day' || unitRate.name === 'anytime',
    )?.unitRate.amount;
  }

  const tariffRate = unitRate
    ? (roundTo2DP((Number(unitRate) * 100).toString()) as unknown as number)
    : null;

  const changeConsent = () => {
    setConsent(!consent);
  };

  const getContent = () => {
    if (anytimeBoltonPricing.isError)
      return 'Smart charge and save with our super-low Charge Anytime rate';
    if (anytimeBoltonPricing.isLoading)
      return (
        <>
          Enjoy a super-low EV charging rate of <Strong>XXp/kWh</Strong>{' '}
          whenever you smart charge your car.
        </>
      );

    if (anytimeBoltonPricing.data)
      return (
        <>
          Enjoy a super-low EV charging rate of{' '}
          <Strong>{anytimeBoltonPricing.data.formattedPence}</Strong> whenever
          you smart charge your car.
        </>
      );
  };

  return isEligible ? (
    <PageWithNavigation title="Charge Anytime">
      <EVAnytimeHeader title="Charge Anytime" />
      <StyledFeaturesCard data-testId="add-anytime-card">
        <StyledFeaturesDiv>
          <StyledFeaturesTextWrapper>
            <StyledFeaturesText>
              <Heading4>Charge up. Zoom off.</Heading4>
              <P>{getContent()}</P>
              <Ul>
                <Li>
                  Keep paying your current energy rates for your home energy use
                </Li>
                {tariffRate && (
                  <Li>
                    If you need to boost charge your EV, you'll be charged your
                    standard energy rate
                  </Li>
                )}
                {!hasMyEv && (
                  <Li>
                    Get insights about your charging habits, carbon savings, and
                    much more, on your all-new <Strong>Your EV hub</Strong>
                  </Li>
                )}
              </Ul>
            </StyledFeaturesText>
            <StyledTermsCheckbox
              id="agreeAnyTimeConditions"
              label="I agree to the Charge Anytime terms and conditions"
              checked={consent}
              onChange={changeConsent}
              error={!consent && consentError && 'Please tick to continue'}
              data-testId="agreeAnyTimeConditions"
            />
            <Margin top={5}>
              <Small>
                To add Charge Anytime you’ll need to be ok with your smart meter
                sharing half-hourly readings with us.{' '}
                <TextLink
                  href={CHARGE_ANYTIME_TERMS_AND_CONDITIONS_LINK}
                  opensInNewWindow
                >
                  View terms and conditions
                </TextLink>
                .
              </Small>
            </Margin>
            <Margin top={5}>
              <ButtonGroupWrapper>
                <ButtonGroup>
                  <AnalyticsClick
                    name={
                      EV.ELIGIBILITY
                        .EVS_OVO_CHARGE_ANYTIME_HOMEPAGE_GO_TO_ELIGIBILITY_PAGE_BUTTON_CLICKED
                    }
                    properties={{
                      smartChargerSelection: smartChargerSelection,
                    }}
                  >
                    <CTALink
                      iconLeft="chevron-left"
                      iconRight="off"
                      data-event-name={
                        'Click-EVS - EVS OVO Drive Anytime homepage navigate to eligibility page button clicked [Orion Web]'
                      }
                      data-event-props={JSON.stringify({
                        smartChargerSelection: smartChargerSelection,
                      })}
                      onClick={goToEligibilityPage}
                      variant="missionSecondary"
                    >
                      Back
                    </CTALink>
                  </AnalyticsClick>
                  <AnalyticsClick
                    name={EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_HOMEPAGE_ADD_CLICKED}
                    properties={{
                      smartChargerSelection,
                    }}
                  >
                    <PrimaryCTALink
                      data-testId="add-anytime-button"
                      variant="missionPrimary"
                      data-event-name={
                        'Click-EVS - EVS OVO Drive Anytime homepage add button clicked [Orion Web]'
                      }
                      onClick={goToConsentPage}
                    >
                      Next
                    </PrimaryCTALink>
                  </AnalyticsClick>
                </ButtonGroup>
              </ButtonGroupWrapper>
            </Margin>
          </StyledFeaturesTextWrapper>
          <StyledFeaturesImageWrapper>
            <StyledFeaturesImage
              image={FeaturesCoverImage}
              imageMobile={FeaturesCoverImageMobile}
            />
          </StyledFeaturesImageWrapper>
        </StyledFeaturesDiv>
      </StyledFeaturesCard>
      <Margin top={6}>
        <Card>
          <ChargeSmartCard>
            <ChargeSmartTextWrapper>
              <Heading3>How it works</Heading3>
              <P>
                To give you the cheapest rate possible, each month, you’ll
                receive <Strong>Charge Anytime charging credit</Strong>.
              </P>
              <P>
                The credit is based on how much of your total electricity went
                into smart charging your car, and means that your total bill
                will always reflect an EV smart-charging rate of{' '}
                {anytimeBoltonPricing?.data?.formattedPence ?? 'XXp/kWh'}.
              </P>
              <P>
                Find out more about{' '}
                <TextLink
                  href="https://www.ovoenergy.com/electric-cars/anytime-credit-explained"
                  opensInNewWindow
                >
                  charging credit
                </TextLink>
              </P>
            </ChargeSmartTextWrapper>
            <ChargeSmartImageWrapper>
              <img src={ChargeSmartIcon} alt="" />
            </ChargeSmartImageWrapper>
          </ChargeSmartCard>
        </Card>
      </Margin>

      <Margin top={6}>
        <KaluzaCard>
          <KaluzaImageWrapper>
            <StyledFeaturesImage
              image={EVChargingImage}
              imageMobile={EVChargingImageMobile}
            />
          </KaluzaImageWrapper>

          <KaluzaCardBody>
            <KaluzaIcon />
            <Heading4>Connect. Control. Optimise.</Heading4>
            <P>
              To get the most out of Charge Anytime, you’ll need to connect your
              smart charger to your OVO account and opt in to smart charging
              with Kaluza – the intelligent energy platform.
            </P>
            <P>
              Kaluza will then use real-time data¹ to optimise your EV charging.
              This’ll make sure your car is ready to go when you need it, and
              that the electricity used is as clean and green as it can be².
            </P>
            <TextLink href="https://www.kaluza.com/" opensInNewWindow>
              Learn all about Kaluza
            </TextLink>
          </KaluzaCardBody>
        </KaluzaCard>
      </Margin>
      <Margin top={5}>
        <Small>
          ¹Kaluza will process your data in accordance with{' '}
          <TextLink
            href="https://www.kaluza.com/privacy-policy/"
            opensInNewWindow
          >
            Kaluza’s privacy policy
          </TextLink>
        </Small>
        <br />
        <Small>
          ²Kaluza will make sure your car is charged when demand on the National
          Grid is lower. This helps reduce reliance on dirty fossil fuels and
          means your energy is more likely to come from renewable sources.
        </Small>
      </Margin>
      <Margin top={11} bottom={4}>
        <Heading3>Frequently asked questions</Heading3>
      </Margin>
      <EVAnytimeFAQ />
      <Margin top={11}>
        <Heading4>Terms and conditions</Heading4>
        <Small>
          Full terms and conditions can be found{' '}
          <TextLink href={`${OVO_ENERGY_TERMS}/OVO-Drive-Flex`}>here</TextLink>
        </Small>
      </Margin>
    </PageWithNavigation>
  ) : !isFetching ? (
    <AnytimeNotEligibleSection />
  ) : null;
};
