import React from 'react';
import {
  Card,
  Heading2,
  SecondaryCTALink,
  SkeletonText,
} from '@ovotech/nebula';
import { ROUTE_EDIT_METER_READ_FREQUENCY } from '@/src/constants/routes';
import { ENABLE_MHHS_READ_FREQUENCY_SETTINGS } from '@/src/constants/feature-flags';
import { RouterLink } from '../RouterLink/RouterLink';
import { useFeature } from '@/src/utils/optimizely';
import { useConsentQuery } from '@/src/api';

const SetMeterReadsBanner = () => {
  const [enabled] = useFeature(ENABLE_MHHS_READ_FREQUENCY_SETTINGS);
  const consentQuery = useConsentQuery();

  // return early just not to show the skeleton
  if (!enabled) return null;

  if (consentQuery.isFetching) {
    return (
      <Card>
        <SkeletonText lines={3} />
      </Card>
    );
  }

  // no requirement to present something to the user in case of error, so silent fail
  if (consentQuery.isError) return null;

  if (consentQuery.data !== undefined && !consentQuery.data.hasAValidMeterType)
    return null;

  return (
    <Card>
      <Heading2>Set how often meter readings send</Heading2>
      <SecondaryCTALink
        to={ROUTE_EDIT_METER_READ_FREQUENCY}
        as={RouterLink}
        style={{ marginTop: '1.5rem' }}
      >
        View settings
      </SecondaryCTALink>
    </Card>
  );
};

export default SetMeterReadsBanner;
