import {
  Strong,
  Card,
  Heading3,
  Heading4,
  P,
  PrimaryCTALink,
} from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import React from 'react';
import { ActionCentreBanner, Banner } from './Banner';
import { CollapsibleBlock } from './CollapsibleBlock';
import { useInterestRewardQuery, useMoveOutDateQuery } from '@/src/api';
import { ContactUsLink, ErrorSection } from '@/src/components';
import { BANNER_CTA_CLICKED } from '@/src/constants/analytics';
import { OVO_QUOTE_AND_SWITCH } from '@/src/constants/endpoints';
import { MOVE_OUT_IN_PROGRESS_ERROR } from '@/src/constants/error-section-ids';
import { SkeletonCard } from '@/src/pages/Home/components/SkeletonCard';
import { MoveOutDateResponse } from '@/src/types/MoveOut';
import { InterestRewardGraphqlResponse } from '@/src/types/Response';
import { formatFullDate } from '@/src/utils/date';
import { useProfileQueryKapi } from '@/src/api/kapi/use-profile-query';
import { ProfileNextV1Query } from '@/src/api/kapi/__generated__/graphql';
import { formatPostalAddress } from '@/src/utils/format-postal-address';

const Data = ({
  data: [moveOut, profile, interestRewardResponse],
}: {
  data: readonly [
    MoveOutDateResponse,
    ProfileNextV1Query,
    InterestRewardGraphqlResponse | null,
  ];
}) => {
  // Ruleset enforced moveout data to exist
  const moveOutDateCopy = formatFullDate(new Date(moveOut.moveOutDate!));
  const formattedAddress = formatPostalAddress(
    profile?.customer_nextV1.contactAddress?.postalAddress,
  );

  const interestReward =
    interestRewardResponse?.billingSummary.interestReward.currentPercentage ||
    '';

  return (
    <Banner icon="loss">
      <Heading3 as="h2">Goodbye from us!</Heading3>
      <P>
        Thanks for letting us know you’re moving out of{' '}
        <Strong>
          {formattedAddress ? formattedAddress : 'unknown address'}
        </Strong>{' '}
        on the <Strong>{moveOutDateCopy}</Strong>. We'll end your contract on
        this date, create a final statement and refund any money left on your
        account. You've been an awesome OVO member!
      </P>
      <P>
        Something not right? Moving out date changed?{' '}
        <ContactUsLink>Please let us know</ContactUsLink>
      </P>

      <CollapsibleBlock bannerId="moveOutInProgress">
        <Heading4 as="h3">Want us to power your new home?</Heading4>
        <P>
          We'd love that! Stay on this carbon-kicking journey with us and keep
          enjoying 100% renewable electricity with a smart meter, our
          {interestReward}% OVO Interest Reward - and lightening your footprint
          with our Carbon Tracker.
        </P>
        <PrimaryCTALink
          data-event-name={BANNER_CTA_CLICKED}
          data-event-props={JSON.stringify({
            bannerId: 'moveOutInProgress',
            ctaName: 'Get a quote',
          })}
          href={OVO_QUOTE_AND_SWITCH}
          opensInNewWindow
        >
          Get a quote
        </PrimaryCTALink>
      </CollapsibleBlock>
    </Banner>
  );
};

export const MoveOutInProgress: ActionCentreBanner = () => {
  const { accountId } = useAccountContext();

  const interestRewardQueryResult = useInterestRewardQuery(accountId || '');
  const moveOutQuery = useMoveOutDateQuery(accountId || '');
  const profile = useProfileQueryKapi();

  const hasError =
    interestRewardQueryResult?.isError ||
    moveOutQuery?.isError ||
    profile?.isError;

  if (hasError) {
    return (
      <Card>
        <ErrorSection alertLevel="error" id={MOVE_OUT_IN_PROGRESS_ERROR} />
      </Card>
    );
  }

  if (
    moveOutQuery.isError ||
    moveOutQuery.isLoading ||
    profile.isLoading ||
    profile.isError ||
    !interestRewardQueryResult?.data ||
    !profile.data
  ) {
    return <SkeletonCard testId="loader" />;
  }

  return (
    <Data
      data={[
        moveOutQuery.data as MoveOutDateResponse,
        profile.data,
        interestRewardQueryResult.data as InterestRewardGraphqlResponse,
      ]}
    />
  );
};
