import mixpanel, { Mixpanel } from 'mixpanel-browser';
import React, { ComponentType } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { MixpanelEventsQueue } from '../mixpanel-utils';
import AnalyticsService from '@/src/services/analyticsService';
import 'url-search-params-polyfill';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';

function run(App: ComponentType) {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000);
  }

  mixpanel.init(process.env.NX_MIXPANEL_ID!, {
    opt_out_tracking_by_default: true,
    stop_utm_persistence: true,
  });

  const mixpanelTracker: Mixpanel = {
    ...mixpanel,
    identify: (id: string) => mixpanel.identify(id),
    register: (properties: Record<string, unknown>) =>
      mixpanel.register(properties),
    reset: () => mixpanel.reset(),
    track: (name: string, props?: { [key: string]: any }) => {
      const data = {
        ...props,
        brand: 'ORION',
        brandEntity: 'OVO Energy',
      };

      MixpanelEventsQueue.track(name, data);
    },
  };

  AnalyticsService.initialize([mixpanelTracker]);

  const container = document.getElementById('app');
  const root = createRoot(container!);
  root.render(<App />);
}

export { run };
