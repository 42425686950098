import { useEffect } from 'react';

import { ErrorSectionId } from '@/src/types/ErrorSectionIds';
import { withRUM } from '@/src/utils/rum';

const errorAlertThreshold = 2000;

/*
 * - alertLevel:
 *    - critical => will alert, PagerDuty
 *    - error =>  will alert
 *    - warn => event sent but not actionned
 */
type AlertLevel = 'critical' | 'error' | 'warn';

function useError(
  hasError: boolean,
  alertLevel: AlertLevel = 'warn',
  id: ErrorSectionId,
  data?: { [key: string]: any },
) {
  useEffect(() => {
    if (hasError) {
      setTimeout(() => {
        withRUM(rum => {
          rum.addAction('ErrorSectionRendered', {
            alertLevel,
            sectionId: id,
            ...(data ? { data } : {}),
          });
        });
      }, errorAlertThreshold);
    }
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);
}

export default useError;
