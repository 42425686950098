import React from 'react';
import { URL_BEYOND } from '../../Shop/Shop';
import {
  StyledDivContainer,
  StyledHeading3,
  StyledP,
  StyledSecondaryCTALink,
} from './BeyondBanner.styled';

type BeyondBannerProps = {
  heading: string;
  subHeading: string;
  ctaText: string;
};

const BeyondBanner = ({ heading, subHeading, ctaText }: BeyondBannerProps) => (
  <StyledDivContainer color="black">
    <StyledHeading3 color="white">{heading}</StyledHeading3>
    <StyledP>{subHeading}</StyledP>
    <StyledSecondaryCTALink href={URL_BEYOND} target="_blank">
      {ctaText}
    </StyledSecondaryCTALink>
  </StyledDivContainer>
);

export default BeyondBanner;
