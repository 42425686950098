import { P } from '@ovotech/nebula';

import React from 'react';
import { hubTestIDs } from '../../testIDs';
import {
  CarbonComparisonTextCard,
  CarbonComparisonFlexCardContent,
  StyledFlexCard,
  StyledNoMarginH2,
  StyledNoMarginP,
  StyledContainers,
} from './CarbonComparison.styled';

import { comparisonAssets } from './CarbonComparisonAssets';
import CarbonComparisonGraph from './CarbonComparisonGraph';
import { ReactComponent as LeafIcon } from '@/src/resources/leaf_icon.svg';

import { GridContainer } from '@/src/pages/EnergyInsights/components';
import { CarbonFigures } from '@/src/pages/EnergyInsights/types';

type Props = {
  carbonFigures: CarbonFigures | null;
};

export const CarbonComparison = ({ carbonFigures }: Props) => {
  if (!carbonFigures) {
    return null;
  }
  const { usedCarbonTonnes, savedCarbonTonnes } = carbonFigures;

  const carbonTonnesForGraph = savedCarbonTonnes || usedCarbonTonnes;

  if (!carbonTonnesForGraph) {
    return null;
  }

  return (
    <GridContainer>
      <StyledNoMarginH2>Your energy’s yearly carbon footprint</StyledNoMarginH2>
      <StyledNoMarginP>
        Here’s how much carbon your home energy use will create this year.
      </StyledNoMarginP>
      <StyledFlexCard data-testid={hubTestIDs.carbonComparison}>
        <StyledContainers>
          <CarbonComparisonFlexCardContent>
            <CarbonComparisonGraph
              electricityCarbonTonnes={carbonTonnesForGraph.electricity || 0}
              gasCarbonTonnes={carbonTonnesForGraph.gas || 0}
            />
          </CarbonComparisonFlexCardContent>
        </StyledContainers>
        <StyledContainers>
          <CarbonComparisonTextCard>
            <div>
              <LeafIcon />
              <P
                data-testid="comparison-text"
                aria-label={comparisonAssets.ariaLabel}
              >
                {comparisonAssets.text}
              </P>
            </div>
          </CarbonComparisonTextCard>
        </StyledContainers>
      </StyledFlexCard>
    </GridContainer>
  );
};
