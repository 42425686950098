import { CTALink, Stack } from '@ovotech/nebula';
import React from 'react';
import {
  StyledShape,
  StyledIcon,
  StyledCard,
  StyledHeading,
  StyledBody,
} from './ActionCard.styled';
import { ReactComponent as MeterIcon } from '@/src/resources/meter-icon.svg';
import { ReactComponent as StrangeRect } from '@/src/resources/strange-rect.svg';

type Props = {
  title: string;
  body: string | JSX.Element;
  handleOnClickActionButton?: () => void;
  handleOnClickSecondaryActionButton?: () => void;
  buttonTitle?: string;
  buttonStyle?: string;
  secondaryButtonTitle?: string;
  buttonLink?: string;
  secondaryButtonLink?: string;
  additionalLink?: JSX.Element;
  testId?: string;
};

export const ActionCard = ({
  handleOnClickActionButton,
  handleOnClickSecondaryActionButton,
  body,
  title,
  buttonTitle,
  buttonStyle = 'missionPrimary',
  secondaryButtonTitle,
  buttonLink,
  secondaryButtonLink,
  additionalLink,
  testId,
}: Props) => {
  return (
    <StyledCard data-testid={testId}>
      <StyledShape>
        <StrangeRect />
      </StyledShape>
      <div>
        <StyledIcon>
          <MeterIcon />
        </StyledIcon>
        <Stack spaceBetween={4}>
          <StyledHeading>{title}</StyledHeading>
          <StyledBody>{body}</StyledBody>
          {buttonTitle ? (
            <>
              <CTALink
                href={buttonLink}
                variant={buttonStyle}
                onClick={() => {
                  handleOnClickActionButton && handleOnClickActionButton();
                }}
              >
                {buttonTitle}
              </CTALink>
              {secondaryButtonTitle ? (
                <CTALink
                  href={secondaryButtonLink}
                  variant="secondary"
                  onClick={() => {
                    handleOnClickSecondaryActionButton &&
                      handleOnClickSecondaryActionButton();
                  }}
                >
                  {secondaryButtonTitle}
                </CTALink>
              ) : null}
            </>
          ) : null}
          {additionalLink}
        </Stack>
      </div>
    </StyledCard>
  );
};
