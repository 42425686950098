import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { createMetersEndpoint } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import { MetersResponse, ReadingFrequency } from '@/src/types/Response';

type QueryCacheKey = `account-meters-${string}`;

type Body = {
  readingFrequency: ReadingFrequency;
};

export const useAccountMetersQuery = (
  accountId: string | null | undefined,
): UseQueryResult<MetersResponse> => {
  return useQuery(
    `account-meters-${accountId}` as QueryCacheKey,
    () =>
      apiService.get({
        url: createMetersEndpoint(accountId ?? ''),
      }),
    {
      enabled: !!accountId,
    },
  );
};

export const useAccountMeterReadFrequencyMutation = (
  accountId: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: Body) =>
      apiService.patch({
        url: createMetersEndpoint(accountId ?? ''),
        body,
      }),
    mutationKey: `meters-mutation-${accountId}`,
    onSuccess: () => {
      onSuccess?.();
      queryClient.invalidateQueries(`account-meters-${accountId}`);
    },
    onError: () => {
      onError?.();
    },
  });
};
