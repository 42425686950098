import { Icon, Margin, P, SecondaryCTAButton } from '@ovotech/nebula';
import {
  AnalyticsClick,
  AnalyticsView,
  useAccountContext,
} from '@ovotech/ui-tools';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { Styled } from './HomeProfileWidget.styled';
import testIDs from './testIDs';
import { CARBON_ZERO } from '@/src/constants/analytics';
import { HomeProfileModal } from '@/src/pages/EnergyInsights/components';
import { HomeProfileData } from '@/src/pages/EnergyInsights/types';
import CardImage from '@/src/resources/lifestyle-photography-3.png';

type Props = {
  analyticsId: string;
  heading: string;
  body: string;
  onClose: VoidFunction;
  answers: HomeProfileData | null;
};

export const HomeProfileWidget = ({
  analyticsId,
  heading,
  body,
  onClose,
  answers,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { accountId } = useAccountContext();
  const { colors } = useTheme();

  if (!accountId) {
    return null;
  }

  return (
    <>
      {modalOpen && (
        <HomeProfileModal
          homeProfile={answers}
          onClose={() => setModalOpen(false)}
        />
      )}
      <AnalyticsView
        name={CARBON_ZERO.HOME_PROFILE_WIDGET.VIEWED}
        properties={{ analyticsId }}
      >
        <Styled.Card data-testid={analyticsId}>
          <AnalyticsClick
            name={CARBON_ZERO.HOME_PROFILE_WIDGET.CLOSE_BUTTON_CLICKED}
            properties={{ analyticsId }}
          >
            <Styled.CloseButton
              data-testid={testIDs.closeButton}
              onClick={onClose}
              aria-label="Dismiss"
            >
              <Icon name="cross" color={colors.primary} size={16} />
            </Styled.CloseButton>
          </AnalyticsClick>
          <Styled.LeftContent>
            <Styled.Heading data-testid={testIDs.heading}>
              {heading}
            </Styled.Heading>

            <Margin bottom={4} right={4}>
              <P data-testid={testIDs.body}>{body}</P>
            </Margin>

            <AnalyticsClick
              name={CARBON_ZERO.HOME_PROFILE_WIDGET.CTA_CLICKED}
              properties={{ analyticsId }}
            >
              <SecondaryCTAButton
                data-testid={testIDs.cta}
                fullWidth="never"
                onClick={() => setModalOpen(true)}
              >
                View your profile
              </SecondaryCTAButton>
            </AnalyticsClick>
          </Styled.LeftContent>

          <Styled.Image src={CardImage} />
        </Styled.Card>
      </AnalyticsView>
    </>
  );
};
