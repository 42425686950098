import React from 'react';
import Media from 'react-media';
import { AnimatedIconsDesktop } from './AnimatedIconsDesktop';
import { AnimatedIconsMobile } from './AnimatedIconsMobile';
import { StyledGreenerEnergyAnimation } from './GreenerEnergyAnimation.styled';
import { breakpoints } from '@/src/utils/mediaQuery';

export const GreenerEnergyAnimation = () => (
  <StyledGreenerEnergyAnimation>
    <Media query={breakpoints.largeUp}>
      <AnimatedIconsDesktop />
    </Media>
    <Media query={breakpoints.largeDown}>
      <AnimatedIconsMobile />
    </Media>
  </StyledGreenerEnergyAnimation>
);
