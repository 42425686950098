import { PlansResponse } from '@ovotech/energy-cx';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { createPlansEndpoint } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';

type QueryCacheKey = `orion-plans-${string}`;

export const usePlansQuery = (
  accountId: string | null | undefined,
  options?: UseQueryOptions<
    PlansResponse,
    unknown,
    PlansResponse,
    QueryCacheKey
  >,
): UseQueryResult<PlansResponse> =>
  useQuery(
    `orion-plans-${accountId}` as QueryCacheKey,
    () =>
      apiService.get<PlansResponse>({
        url: createPlansEndpoint(accountId ?? ''),
        headers: {
          'orion-endpoint-version': '2',
        },
      }),
    {
      enabled: !!accountId,
      ...options,
    },
  );
