import { Card, Checkbox } from '@ovotech/nebula';
import styled from 'styled-components';

export const StyledFeaturesCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 0 !important;
`;

export const StyledFeaturesDiv = styled.div`
  ${({ theme: { mediaQueries } }) => `
      display: flex;
      margin 0 auto 0 0;
      flex-direction: column-reverse;
    ${mediaQueries.mediumAndUp} {
      display: flex;
      margin 0 0 0 0;
      flex-direction: row;
    };
    `}
`;

export const StyledFeaturesTextWrapper = styled.div`
  ${({ theme: { mediaQueries } }) => `
display: flex;
  flex-direction: column;
  padding: 1.6rem;
  ${mediaQueries.mediumAndUp} {
      flex: 0 0 50%;
    };
      padding: 3.2rem;
  `}
`;

export const StyledFeaturesText = styled.div`
  margin-bottom: 20px;
`;

export const StyledFeaturesImage = styled.img<{ image: any; imageMobile: any }>`
  ${({ theme: { mediaQueries }, image, imageMobile }) => `
      max-width: 100%;
      object-fit: contain;
      width: 100%;
      content: url(${imageMobile});

    ${mediaQueries.mediumAndUp} {
      flex: 0 0 50%;
      object-fit: unset;
      margin-bottom: 0;
      content: url(${image});
    };
  `}
`;

export const StyledFeaturesImageWrapper = styled.div`
  ${({ theme: { mediaQueries } }) => `

    ${mediaQueries.mediumAndUp} {
      max-width: 50%;


    };
  `}
`;

export const ButtonGroupWrapper = styled.div`
  ${({ theme: { mediaQueries } }) => `
    margin-top: auto;
    div {
      display: flex;
      flex-direction: column-reverse;
      a {
        margin-bottom: 2rem;
      }
    }

${mediaQueries.mediumAndUp} {
  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    a {
      height: 50%;
    }
  }
};
`}
`;

export const ChargeSmartCard = styled(Card)`
  position: relative;
  background-color: #f9f9f9;
  box-shadow: none;
  display: flex;
  svg {
    margin-right: -3.2rem;
    margin-top: -3.2rem;
  }
`;

export const ChargeSmartTextWrapper = styled.div`
  flex: 0 0 80%;
  @media only screen and (max-width: 550px) {
    flex: 0 0 70%;
  }
`;

export const ChargeSmartImageWrapper = styled.div`
  max-width: 20%;
  position: absolute;
  top: 0;
  right: 0;
  img {
    object-fit: contain;
  }
  @media only screen and (max-width: 550px) {
    max-width: 30%;
  }
`;

export const KaluzaCard = styled(Card)`
  ${({ theme: { mediaQueries } }) => `
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  ${mediaQueries.mediumAndUp} {
    display: flex;
    margin 0 auto;
    flex-direction: row;
  };
  img {

    ${mediaQueries.mediumAndUp} {
      height: 100%;
    }
  }
  `}
`;

export const KaluzaCardBody = styled.div`
  ${({ theme: { mediaQueries } }) => `
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    padding: 3.2rem;

    ${mediaQueries.mediumAndUp} {
        padding: 3.2rem;
      }
`}
`;

export const KaluzaImageWrapper = styled.div`
  max-height: 530px;
  img {
    object-fit: cover;
  }
`;

export const StyledTermsCheckbox = styled(Checkbox)`
  position: absolute;
  margin-top: 100px;
`;

export const Divider = styled.div`
  ${({ theme: { colors } }) => `
    border-top: 1px solid ${colors.brand.grey.base};
  `}
  margin-bottom: 3.2rem;
`;
