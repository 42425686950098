import { PlanFamily } from '@monovo/energy-renewals';
import { useAccountContext } from '@ovotech/ui-tools';
import { useSelector } from 'react-redux';
import { useContractsQuery, useProductsQuery } from '@/src/api';
import { PRODUCT_IDS } from '@/src/constants/products';
import { hasSmartMeter as hasSmartMeterSelector } from '@/src/pages/Renewal/selectors';
import { SmartChargers, State } from '@/src/types/State';
import { hasSpecificPlanActive } from '@/src/utils/contracts';
import { hasActiveBolton } from '@/src/utils/products';

/**
 * @deprecated should only be used for legacy code that only cares about
 * anytime, and will be deleted once payasyoudrive has been fully migrated to
 */
export function useCheckEligibilityForAnytime() {
  const { accountId } = useAccountContext();
  const contracts = useContractsQuery(accountId);
  const products = useProductsQuery(accountId);
  const boltons = products?.data?.boltons;
  const hasAnytimeBolton =
    boltons !== undefined
      ? hasActiveBolton(boltons, PRODUCT_IDS.anytime)
      : null;

  const hasSmartMeter = useSelector(hasSmartMeterSelector);

  const { smartChargerSelection, isFetching } = useSelector(
    ({ evAnytime }: State) => evAnytime,
  );

  const isOVODriveCustomer = hasSpecificPlanActive(
    contracts?.data,
    PlanFamily.drive,
  );

  const hasSmartCharger = [SmartChargers.Indra, SmartChargers.Ohme].includes(
    smartChargerSelection,
  );

  return {
    smartMeter: hasSmartMeter,
    isOVODriveCustomer,
    hasAnytimeBolton,
    hasSmartCharger,
    isFetching:
      products.isFetching ||
      contracts.isFetching ||
      hasAnytimeBolton === null ||
      isFetching,
  };
}
