import { Margin, Row } from '@ovotech/nebula';

import React from 'react';

import {
  EnergyInsightsTermsLink,
  HomeProfilePromptHubContainer,
  GenericPageError,
  PageHeaderWithCarbonIntensity,
} from '../../components';
import { useHomeProfilePromptPosition } from '../../hooks/useHomeProfilePromptPosition';
import {
  EnergyInsightsPageName,
  CarbonIntensityLevel,
  HubScreenResponse,
} from '../../types';
import BreakdownWidget from './Components/BreakdownWidget/BreakdownWidget';
import { CarbonComparison } from './Components/CarbonComparison/CarbonComparison';
import CarbonIntensityWidget from './Components/CarbonIntensityWidget/CarbonIntensityWidget';

import { HalfHourlyOptIn } from './Components/HalfHourlyOptIn/HalfHourlyOptIn';
import NonSmartTopTips from './Components/NonSmartTopTips/NonSmartTopTips';
import ConnectedTenSecChart from './Components/TenSecondChart/ConnectedTenSecChart';
import { useEnergyInsightsHubQuery } from '@/src/api/use-energy-insights-hub-query';
import { PageWithNavigationAndSectionPadding } from '@/src/components/Pages/Pages';
import { energyInsightsStrings as strings } from '@/src/pages/EnergyInsights/strings';

interface Props extends HubScreenResponse {
  hideTenSecondChart?: boolean; // mainly for ease of testing
}

export const HubPage = () => (
  <PageWithNavigationAndSectionPadding
    title={strings.productPageHeading}
    analytics={{ name: EnergyInsightsPageName.Hub }}
  >
    <Content />
    <Margin bottom={6} />
    <EnergyInsightsTermsLink />
  </PageWithNavigationAndSectionPadding>
);

function Content() {
  const { data, error } = useEnergyInsightsHubQuery();

  if (error) {
    return <HubError />;
  }

  if (!data) {
    return <NoData />;
  }

  return <Hub {...data} />;
}

export function Hub({
  hideTenSecondChart,
  hasSmartMeter,
  hasSolarPanels,
  homeProfile,
  halfHourlyReads,
  carbonFigures,
  breakdownWidget,
  hasBreakdownData,
  currentIntensity,
  carbonGreentime,
}: Props) {
  const { homeProfilePromptPosition } =
    useHomeProfilePromptPosition(homeProfile);

  return (
    <>
      <Header carbonIntensityLevel={currentIntensity?.index} />
      {homeProfilePromptPosition === 'top' && (
        <HomeProfilePromptHubContainer homeProfile={homeProfile} />
      )}
      {!hideTenSecondChart && <ConnectedTenSecChart />}
      <CarbonComparison carbonFigures={carbonFigures} />
      <HalfHourlyOptIn
        halfHourlyReads={halfHourlyReads}
        hasSmartMeter={hasSmartMeter}
      />
      {!hasBreakdownData ? <NonSmartTopTips /> : null}
      <Row isNested={true}>
        <BreakdownWidget
          widgetData={breakdownWidget}
          hasSolarPanels={hasSolarPanels}
        />
        <CarbonIntensityWidget
          greentime={carbonGreentime?.greentime}
          currentIntensity={currentIntensity?.index}
        />
      </Row>
      {homeProfilePromptPosition === 'bottom' && (
        <HomeProfilePromptHubContainer homeProfile={homeProfile} />
      )}
    </>
  );
}

function HubError() {
  return (
    <>
      <Header />
      <Margin bottom={4} />
      <GenericPageError />
    </>
  );
}

function NoData() {
  return (
    <>
      <Header />
    </>
  );
}

function Header({
  carbonIntensityLevel,
}: {
  carbonIntensityLevel?: CarbonIntensityLevel;
}) {
  return (
    <PageHeaderWithCarbonIntensity
      mainTitle={strings.productPageHeading}
      subTitle="Free insights and simple energy-saving advice (formerly known as OVO Greenlight)"
      currentIntensity={carbonIntensityLevel}
    />
  );
}
