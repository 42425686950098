import { Margin, Heading3, P, Card, CTALink } from '@ovotech/nebula';
import React from 'react';
import { useCustomerInformationCardQuery } from '@/src/api/contentful/use-customer-information-card-query';
import { getDocumentAsReactNode } from './utils/getDocumentAsReactNode';
import { Document } from '@contentful/rich-text-types';
import { getFragmentData } from '@/src/api/contentful/__generated__';
import {
  CallToAction,
  PackCTAFragment,
  getCallToAction,
} from '@/src/utils/contentful/getCallToAction';
import { SkeletonLoader } from './SkeletonLoader';
import { AnalyticsClick } from '@ovotech/ui-tools';
import { CUSTOMER_INFORMATION_CARD_EVENTS } from '@/src/constants/analytics';

export const CustomerInformationCard = () => {
  const { data, isLoading, isError } = useCustomerInformationCardQuery();

  if (isLoading) {
    return <SkeletonLoader />;
  }

  const cardData =
    data?.buildCustomerInformationCard?.actionCardsCollection?.items[0];

  const header = cardData?.text?.header;

  const cta = getFragmentData(PackCTAFragment, cardData?.cta);
  const ctaInfo = getCallToAction(cta);

  if (isError || !header || !ctaInfo || !ctaInfo.label) {
    return null;
  }

  const bodyText = getDocumentAsReactNode(
    cardData?.text?.text?.json as Document,
  );
  const mixpanelId = cardData?.mixpanelId;

  const CustomerInformationCardButton = ({
    ctaInfo,
  }: {
    ctaInfo: CallToAction;
  }) => (
    <CTALink href={ctaInfo.url} target="_blank">
      {ctaInfo.label}
    </CTALink>
  );

  return (
    <Margin top={6}>
      <Card data-testid="customer-information-card">
        <Heading3>{header}</Heading3>
        {!!bodyText && <P>{bodyText}</P>}
        {mixpanelId ? (
          <AnalyticsClick
            name={CUSTOMER_INFORMATION_CARD_EVENTS.CLICKED_CTA}
            properties={{
              cardName: mixpanelId,
              ctaUrl: ctaInfo.url,
            }}
          >
            <CustomerInformationCardButton ctaInfo={ctaInfo} />
          </AnalyticsClick>
        ) : (
          <CustomerInformationCardButton ctaInfo={ctaInfo} />
        )}
      </Card>
    </Margin>
  );
};
