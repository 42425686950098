import React from 'react';
import {
  Redirect,
  Route,
  useRouteMatch,
  useLocation,
} from 'react-router-dom-v5';

import { EVsInlifePageInsights } from './pages/EVsInlifePageInsights';
import { EVsInlifePageMonthly } from './pages/EVsInlifePageMonthly';
import { EVsInlifePageNewsAndOffers } from './pages/EVsInlifePageNewsAndOffers';
import { EVsInlifeSmartChargerDisconnect } from './pages/EVsInlifeSmartChargerDisconnect';
import { defaultDateRedirectPath } from './utils';
import { SwitchWithNotFound } from '@/src/components';
import { MY_EV_PAGE_ALPHA } from '@/src/constants/feature-flags';
import { OptimizelyFeature } from '@/src/utils/optimizely';
import { useChargeAnytimeBoltonState } from './hooks/useChargeAnytimeBoltonState';

const MyEvsInlife = () => {
  const { path } = useRouteMatch();
  const { search } = useLocation();
  const { anytimeBoltonActive } = useChargeAnytimeBoltonState();

  return (
    <OptimizelyFeature feature={MY_EV_PAGE_ALPHA}>
      {isEnabled => {
        return (
          (isEnabled || anytimeBoltonActive) && (
            <SwitchWithNotFound>
              <Route
                exact
                path={`${path}/:year(\\d{4})/:month(\\d{2})`}
                component={EVsInlifePageMonthly}
              />
              <Route
                path={`${path}/disconnect-smartcharger`}
                component={EVsInlifeSmartChargerDisconnect}
              />
              <Route
                path={`${path}/news-and-offers`}
                component={EVsInlifePageNewsAndOffers}
              />
              <Route
                path={`${path}/insights`}
                component={EVsInlifePageInsights}
              />
              <Redirect
                to={{
                  pathname: defaultDateRedirectPath(path, new Date()),
                  search,
                }}
              />
            </SwitchWithNotFound>
          )
        );
      }}
    </OptimizelyFeature>
  );
};

export default MyEvsInlife;
