import {
  CurrencyField,
  Radio,
  RadioField,
  Card,
  Row,
  Col,
  Margin,
} from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import { format } from 'date-fns';
import React, { FormEvent } from 'react';
import { Redirect } from 'react-router-dom-v5';
import { ReducedPaddingCard } from './SettingsPanel.styled';
import { ForecastOption, GraphType } from './constants';
import { DIRECT_DEBIT_CALCULATOR } from '@/src/constants/analytics';
import { ROUTE_HOME } from '@/src/constants/routes';
import { areContractsVariable } from '@/src/utils/contracts';
import { ContractsResponse } from '@/src/types/Response';
import { getEpochDaysDate } from '@/src/utils/date';
import { isRelatedMPANCustomer } from '@/src/utils/supplyPoints';
import { useSupplyPointsQuery } from '@/src/api/kapi/use-supply-points-query';

type SettingsPanelProps = {
  newDirectDebitAmount: string;
  selectedForecastOption: ForecastOption;
  selectedGraphType: GraphType;
  contracts: ContractsResponse;
  handleAmountChange: (e: FormEvent<HTMLInputElement>) => void;
  handlePeriodChange: (value: ForecastOption) => void;
  handleGraphTypeChange: (value: GraphType) => void;
};

const SettingsPanel = ({
  newDirectDebitAmount,
  selectedForecastOption,
  selectedGraphType,
  contracts,
  handleAmountChange,
  handlePeriodChange,
  handleGraphTypeChange,
}: SettingsPanelProps) => {
  const { accountId } = useAccountContext();
  const supplyPointsQueryResult = useSupplyPointsQuery();

  if (!accountId) return null;

  if (supplyPointsQueryResult.isLoading) return null;

  if (supplyPointsQueryResult.isError || !supplyPointsQueryResult.data) {
    return null;
  }

  const activeOrFutureContracts = contracts.electricity
    .concat(contracts.gas)
    .filter(
      c =>
        c.expiryDate == null ||
        getEpochDaysDate(c.expiryDate).getTime() > Date.now(),
    );

  if (
    !activeOrFutureContracts.length ||
    isRelatedMPANCustomer(
      supplyPointsQueryResult.data?.account?.accountSupplyPoints ?? [],
    )
  ) {
    return <Redirect to={ROUTE_HOME} />;
  }

  const isVariable = areContractsVariable(contracts);

  if (isVariable) {
    const analyticsProps = JSON.stringify({
      newDirectDebit: newDirectDebitAmount,
      isVariable: isVariable,
    });

    return (
      <Margin bottom={6}>
        <Card as={ReducedPaddingCard} data-testid="dd-calc-settings-variable">
          <Row isNested>
            <Col large={12} medium={12} small={6}>
              <CurrencyField
                id="amount"
                label="Enter amount:"
                value={newDirectDebitAmount}
                onChange={handleAmountChange}
                min={1}
                max={2000}
                characters={8}
              />
            </Col>
            <Col large={12} medium={12} small={6}>
              <RadioField
                name="graphType"
                label="Compare balance and cost:"
                // @ts-ignore it accepts only string | undefined
                onValueChange={handleGraphTypeChange}
                defaultValue={selectedGraphType}
              >
                <Radio
                  id={GraphType.MONTHLY_END_BALANCE}
                  label="balance"
                  value={GraphType.MONTHLY_END_BALANCE}
                  data-event-name={
                    DIRECT_DEBIT_CALCULATOR.MONTHLY_SHOW_BALANCE_RADIO
                  }
                  data-event-props={analyticsProps}
                />
                <Radio
                  id={GraphType.MONTHLY_ESTIMATED_COST}
                  label="cost"
                  value={GraphType.MONTHLY_ESTIMATED_COST}
                  data-event-name={
                    DIRECT_DEBIT_CALCULATOR.MONTHLY_SHOW_COST_RADIO
                  }
                  data-event-props={analyticsProps}
                />
              </RadioField>
            </Col>
          </Row>
        </Card>
      </Margin>
    );
  }

  const maxExpiryDate = activeOrFutureContracts.sort(
    (a, b) => b.expiryDate! - a.expiryDate!,
  )[0].expiryDate!;

  const analyticsProps = JSON.stringify({
    contractExpiry: getEpochDaysDate(maxExpiryDate),
    newDirectDebit: newDirectDebitAmount,
    isVariable: isVariable,
  });

  return (
    <Margin bottom={6}>
      <Card as={ReducedPaddingCard} data-testid="dd-calc-settings">
        <Row isNested>
          <Col large={12} medium={12} small={12}>
            <CurrencyField
              id="amount"
              label="Enter amount:"
              value={newDirectDebitAmount}
              onChange={handleAmountChange}
              min={1}
              max={2000}
              characters={10}
            />
          </Col>
          <Col large={12} medium={12} small={6}>
            <RadioField
              name="period"
              label="Choose how long:"
              defaultValue={selectedForecastOption}
              // @ts-ignore it accepts only string | undefined
              onValueChange={handlePeriodChange}
            >
              <Radio
                id={ForecastOption.END_OF_CONTRACTS}
                label="until end of contract"
                hint={format(getEpochDaysDate(maxExpiryDate), 'Do MMM YYYY')}
                value={ForecastOption.END_OF_CONTRACTS}
                data-event-name={
                  DIRECT_DEBIT_CALCULATOR.FORECAST_END_OF_CONTRACT
                }
                data-event-props={analyticsProps}
              />
              <Radio
                id={ForecastOption.NEXT_12_MONTHS}
                label="12 months&rsquo; time"
                value={ForecastOption.NEXT_12_MONTHS}
                data-event-name={
                  DIRECT_DEBIT_CALCULATOR.FORECAST_NEXT_TWELVE_MONTHS
                }
                data-event-props={analyticsProps}
              />
            </RadioField>
          </Col>
          <Col large={12} medium={12} small={6}>
            <RadioField
              name="graphType"
              label="Compare balance and cost:"
              defaultValue={selectedGraphType}
              // @ts-ignore it accepts only string | undefined
              onValueChange={handleGraphTypeChange}
            >
              <Radio
                id={GraphType.MONTHLY_END_BALANCE}
                label="balance"
                value={GraphType.MONTHLY_END_BALANCE}
                data-event-name={
                  DIRECT_DEBIT_CALCULATOR.MONTHLY_SHOW_BALANCE_RADIO
                }
                data-event-props={analyticsProps}
              />
              <Radio
                id={GraphType.MONTHLY_ESTIMATED_COST}
                label="cost"
                value={GraphType.MONTHLY_ESTIMATED_COST}
                data-event-name={
                  DIRECT_DEBIT_CALCULATOR.MONTHLY_SHOW_COST_RADIO
                }
                data-event-props={analyticsProps}
              />
            </RadioField>
          </Col>
        </Row>
      </Card>
    </Margin>
  );
};

export default SettingsPanel;
