import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';
import { NewNavItemBadge } from '../../Badges/NewNavItemBadge';
import NavLink from '@/src/components/Navigation/Links/NavLink';
import { MY_EV_PAGE_ALPHA } from '@/src/constants/feature-flags';
import { ROUTE_EV } from '@/src/constants/routes';
import { OptimizelyFeature } from '@/src/utils/optimizely';
import { useLocalStorage } from '@/src/utils/useLocalStorage';
import { useChargeAnytimeBoltonState } from '@/src/pages/EVs/hooks/useChargeAnytimeBoltonState';

type Props = {
  closeMobileMenu?: () => void;
  path: string;
};

const MyEVLinkWrapper = ({ path, closeMobileMenu }: Props) => {
  const { anytimeBoltonActive } = useChargeAnytimeBoltonState();
  const [isNewMyEVLinkBadgeDismissed, updateNewMyEVLinkBadgeDismissed] =
    useLocalStorage('new_my_ev_link_badge_dismissed', false);
  const renderNewMyEVBadge = !isNewMyEVLinkBadgeDismissed;

  const myMyEVLinkClick = () => {
    closeMobileMenu && closeMobileMenu();
    if (renderNewMyEVBadge) {
      updateNewMyEVLinkBadgeDismissed(true);
    }
  };
  return (
    <OptimizelyFeature feature={MY_EV_PAGE_ALPHA}>
      {isEnabled => {
        return isEnabled || anytimeBoltonActive ? (
          <li>
            <AnalyticsClick name={'EVsInlifeNavLink'}>
              <NavLink
                path={path}
                route={ROUTE_EV}
                label="Your EV"
                data-testid="your_ev"
                onClick={myMyEVLinkClick}
                showBadge={renderNewMyEVBadge}
                badge={<NewNavItemBadge />}
              />
            </AnalyticsClick>
          </li>
        ) : null;
      }}
    </OptimizelyFeature>
  );
};

export default MyEVLinkWrapper;
