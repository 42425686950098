import styled from 'styled-components';

export const ChartContainer = styled.div`
  // hides the highest tick to give space for the label
  .recharts-wrapper .yAxis .recharts-cartesian-axis-tick:last-child,
  // hides the top three grid lines so that line aren't added where there is no ticks
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:nth-last-child(3),
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:nth-last-child(2),
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:nth-last-child(1) {
    display: none;
  }
`;

export const ToolTipContainer = styled.div`
  ${({ theme: { colors, fontSizes, space } }): string => `
  background-color: ${colors.brand.grey.lightest};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  padding: ${space[4]};
  font-size: ${fontSizes[3]};
  box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.25);
`}
`;
