const testIDs = {
  heading: 'home-profile-widget-heading',
  body: 'home-profile-widget-body',
  cta: 'home-profile-widget-cta',
  closeButton: 'home-profile-widget-close-button',
  widgetPositionedAtTheTop: 'widget-positioned-at-the-top',
  widgetPositionedAtTheBottom: 'widget-positioned-at-the-bottom',
};

export default testIDs;
