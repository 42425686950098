import React, { useMemo } from 'react';
import Media from 'react-media';
import { analyticEvents } from './Components/TipInteractionButtonResources';
import Tip from './Tip';
import { StyledActionSlide } from './TipSlideshow.styled';

import { ENERGY_INSIGHTS } from '@/src/constants/analytics';

import { breakpoints } from '@/src/utils/mediaQuery';
import { Slideshow } from '@/src/components/Slideshow/Slideshow';
import { UseGetTipInteractionsFromLocalStorage } from '@/src/pages/EnergyInsights/hooks/useGetTipInteractionsFromLocalStorage';
import { RandomTipsAllCategories } from '@/src/pages/EnergyInsights/types';
import { AnyUsageCategory } from '@/src/pages/EnergyInsights/types/common.types';
import { TipContent } from '@/src/pages/EnergyInsights/utils/tips';
import AnalyticsService from '@/src/services/analyticsService';

type TipSlideshowBaseProps = {
  tips: JSX.Element[];
  cards: number;
  category: AnyUsageCategory | RandomTipsAllCategories;
};

const TipSlideshow = ({ tips, cards, category }: TipSlideshowBaseProps) => {
  return (
    <Slideshow
      dataTestId={`ActionsSlideshow-cards-${cards}`}
      trackingOptions={{
        shiftLeft: () => {
          AnalyticsService.dispatch(
            ENERGY_INSIGHTS.spotlightsClickedShiftLeft(category),
          );
        },
        shiftRight: () => {
          AnalyticsService.dispatch(
            ENERGY_INSIGHTS.spotlightsClickedShiftRight(category),
          );
        },
      }}
    >
      {tips
        .map((tip, i) => {
          if (i % cards === 0) {
            const tipRight = tips[i + 1];
            return (
              <StyledActionSlide key={i}>
                {tip}
                {cards >= 2 ? tipRight : null}
              </StyledActionSlide>
            );
          }
          return null;
        })
        .filter(el => el !== null)}
    </Slideshow>
  );
};

export type TipSlideshowProps = {
  tips: Array<TipContent>;
  category: AnyUsageCategory | RandomTipsAllCategories;
  tipInteractions: UseGetTipInteractionsFromLocalStorage;
};

const TipSlideshowWrapper = ({
  tips,
  category,
  tipInteractions: { tipInteractionStatuses, setTipInteractionStatus },
}: TipSlideshowProps) => {
  const TipComponents = useMemo(
    () =>
      tips.map((tip, index) => (
        <Tip
          key={index}
          {...tip}
          tipInteractionStatus={tipInteractionStatuses[tip.title]}
          handleUpdateTipInteractionStatus={tipTitle => interactionStatus => {
            AnalyticsService.dispatch(analyticEvents[interactionStatus], {
              tipTitle,
            });
            setTipInteractionStatus(tipTitle)(interactionStatus);
          }}
        />
      )),
    [tips, tipInteractionStatuses, setTipInteractionStatus],
  );

  return (
    <Media query={breakpoints.smallDown}>
      {matches =>
        matches ? (
          <div>
            <TipSlideshow tips={TipComponents} cards={1} category={category} />
          </div>
        ) : (
          <div>
            <TipSlideshow tips={TipComponents} cards={2} category={category} />
          </div>
        )
      }
    </Media>
  );
};

export default TipSlideshowWrapper;
