import { Col, P, PrimaryCTALink, TextLink } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import React, { useMemo } from 'react';
import {
  ABS_PATH_TO_ZERO_TERMS_LINK,
  ABS_TERMS_LINK,
  ABS_TERTIARY_LINK,
  ANNUAL_BOILER_SERVICE_DESCRIPTION,
  ANNUAL_BOILER_SERVICE_GET_SERVICE_PLAN,
  ANNUAL_BOILER_SERVICE_INFO_LINK_TEXT,
  ANNUAL_BOILER_SERVICE_TITLE,
  BOILER_LIST,
} from '../constants';
import { footnoteBoilerServicePromo } from './FooterTermsAndConditions';
import { Price } from './Price';
import { PtzOffer } from './PtzOffer';
import { UnorderedListSection } from './UnorderedListSection';
import { DisplayFlex } from '@/src/components';
import {
  CLICKED_OFFER_EVENT,
  NEW_OFFERS_PAGE,
} from '@/src/constants/analytics';
import analyticsService from '@/src/services/analyticsService';
import AnnualBoilerServiceImage from '@/src/resources/annual-boiler-service.png';

export const AnnualBoilerService = () => {
  const { accountId } = useAccountContext();

  const createServicePlanLink = useMemo(
    (promoCode = 'OCEAN1') => {
      return `${process.env.NX_KANTAN_ABS_SERVICE_PLAN_JOURNEY_URL}/?promoCode=${promoCode}&accountId=${accountId}&utm_medium=banner&utm_source=offers-and-upgrades&utm_campaign=abs`;
    },
    [accountId],
  );
  return (
    <Col medium={6} key={`offer-annual-boiler-service`} as={DisplayFlex}>
      <PtzOffer
        trackingId={NEW_OFFERS_PAGE.BOILER_SERVICE_ID}
        imagePath={AnnualBoilerServiceImage}
        imageAlt={ANNUAL_BOILER_SERVICE_TITLE}
        title={ANNUAL_BOILER_SERVICE_TITLE}
        description={ANNUAL_BOILER_SERVICE_DESCRIPTION}
      >
        <UnorderedListSection
          title={'Your boiler must be:'}
          list={BOILER_LIST}
        />
        <Price
          price="£1 a month, for 12 months"
          strikeOutText="£7 a month"
          footerNoteContent={footnoteBoilerServicePromo}
        />
        <P>
          View the{' '}
          <TextLink href={ABS_TERMS_LINK} opensInNewWindow>
            annual boiler service terms
          </TextLink>{' '}
          and{' '}
          <TextLink href={ABS_PATH_TO_ZERO_TERMS_LINK} opensInNewWindow>
            Path to Zero terms
          </TextLink>
        </P>
        <PrimaryCTALink
          href={createServicePlanLink}
          opensInNewWindow
          iconRight="off"
          onClick={() => {
            analyticsService.dispatch(CLICKED_OFFER_EVENT, {
              offerType: NEW_OFFERS_PAGE.BOILER_SERVICE_ID,
            });
          }}
        >
          {ANNUAL_BOILER_SERVICE_GET_SERVICE_PLAN}
        </PrimaryCTALink>
        <TextLink
          style={{ textAlign: 'center' }}
          href={ABS_TERTIARY_LINK}
          opensInNewWindow
        >
          {ANNUAL_BOILER_SERVICE_INFO_LINK_TEXT}
        </TextLink>
      </PtzOffer>
    </Col>
  );
};
