import { Card, Heading2 } from '@ovotech/nebula';
import React from 'react';
import { EVAnytimeHeader } from '../../components/EVAnytime';

export const EVAnytimeIsUnavailable = () => (
  <>
    <EVAnytimeHeader title={'Charge Anytime'} showDescription={false} />
    <Card>
      <Heading2>The Charge Anytime bolt on is currently unavailable</Heading2>
    </Card>
  </>
);
