import {
  Card,
  Heading3,
  Margin,
  SkeletonCTA,
  SkeletonHeading,
  SkeletonText,
} from '@ovotech/nebula';
import React from 'react';

export const SkeletonLoader = () => (
  <Margin top={6}>
    <Card data-testid="customer-information-card-loader">
      <SkeletonHeading headingComponent={Heading3} />
      <SkeletonText />
      <SkeletonCTA />
    </Card>
  </Margin>
);
