import { P } from '@ovotech/nebula';
import React from 'react';

import { InterestRewardLink } from './InterestRewardLink';

export const InterestRewardContent = ({
  percentage,
}: {
  percentage?: null | number;
}) => {
  switch (percentage) {
    case 3:
      return (
        <P data-testid="interest-reward-specific">
          We pay a 3% <InterestRewardLink /> for keeping your account in credit.
          This increases to 4% in year two, and 5% in your third year with us,
          and every year after.
        </P>
      );
    case 4:
      return (
        <P data-testid="interest-reward-specific">
          As it&rsquo;s your second year with us, we pay a 4%{' '}
          <InterestRewardLink /> for keeping your account in credit. This
          increases to 5% in your third year with us, and every year after.
        </P>
      );
    case 5:
      return (
        <P data-testid="interest-reward-specific">
          As you&rsquo;ve been with us for three years or more, we&rsquo;re
          paying you a 5% <InterestRewardLink /> for keeping your account in
          credit.
        </P>
      );
    default:
      return (
        <P data-testid="interest-reward-generic">
          We pay <InterestRewardLink /> to your account to reward you for being
          in credit. You&rsquo;ll receive 3% in your first year with us, 4% in
          your second year and 5% every year after.
        </P>
      );
  }
};
