import { Margin, Card, Strong, TextLink, Small } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';

import { Link } from 'react-router-dom-v5';
import { EVHeaderCard } from '../components/EVHeaderCard/EVHeaderCard';
import { EVSavings } from '../components/EVSavings/EVSavings';
import { PageWithNavigation, ScrollToTopOnMount } from '@/src/components';
import {
  EVS_INLIFE_INSIGHTS,
  EVS_INLIFE_DISCONNECT_LINK,
} from '@/src/constants/analytics-subjects';
import { ROUTE_EV, ROUTE_EV_DISCONNECT } from '@/src/constants/routes';
import { useChargeAnytimeBoltonState } from '../hooks/useChargeAnytimeBoltonState';

export const EVsInlifePageInsights = () => {
  const { anytimeBoltonActive } = useChargeAnytimeBoltonState();

  return (
    <PageWithNavigation
      analytics={{ name: EVS_INLIFE_INSIGHTS }}
      title="Your EV - Monthly"
    >
      <>
        <ScrollToTopOnMount />
        <EVHeaderCard
          title="EV insights"
          description="Find out everything there is to know about your EV charging habits."
          linkUrl={ROUTE_EV}
          linkText="< Back to Your EV hub"
        />

        <Margin top={8}>
          <EVSavings />
          {!anytimeBoltonActive && (
            <AnalyticsClick name={EVS_INLIFE_DISCONNECT_LINK}>
              <Margin top={3} bottom={3}>
                <Card>
                  <Strong>Don’t want to get EV insights anymore?</Strong> No
                  problem. Visit{' '}
                  <TextLink
                    to={ROUTE_EV_DISCONNECT}
                    as={Link}
                    data-testid="disconnect-smart-charger"
                  >
                    this page
                  </TextLink>{' '}
                  and we’ll sort it.
                </Card>
              </Margin>
            </AnalyticsClick>
          )}
          <Small>
            Your insights are just estimates. They’re based on the info your
            smart charger is sending us.
          </Small>
        </Margin>
      </>
    </PageWithNavigation>
  );
};
