import { scaleTime } from 'd3-scale';
import { timeMinute } from 'd3-time';
import { format } from 'date-fns';
import { TenSecondSample } from './Types';

export const chartTickDateFormatter = (date: Date) => format(date, 'h:mma');
export const toolTipLabelDateFormatter = (date: Date) =>
  format(date, 'h:mm:ss, D MMM');
export const wattsToKWhFormatter = (w: number) =>
  !Number.isNaN(w) && w !== 0.0 ? Number(w / 1000).toFixed(2) : '0.00';

export const getTimeTicks = (data: TenSecondSample[]) => {
  if (data?.length > 0) {
    const first = data[0].readingTime;
    const last = data.slice(-1)[0].readingTime;
    const domain = scaleTime().domain([first, last]);
    const interval = timeMinute.every(5)!;
    return domain.ticks(interval).map(t => t.valueOf());
  }
  return [];
};
