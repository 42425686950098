import { PlanFamily } from '@monovo/energy-renewals';
import { ContractsResponse } from '@/src/types/Response';

/**
 * Checks if customer has a specific plan active in their contracts.
 *
 * @param contracts list of contracts to search
 * @param planName name of plan to check is active
 * @returns boolean of true: yes, false: no
 */
export const hasSpecificPlanActive = (
  contracts: ContractsResponse | null | undefined,
  planName: PlanFamily,
): boolean => {
  if (!contracts) return false;

  const electricityContracts = contracts?.electricity ?? [];
  const gasContracts = contracts?.gas ?? [];

  return [...electricityContracts, ...gasContracts].some(
    plan => plan.status.active && plan.planName.includes(planName),
  );
};
