import React from 'react';
import styled from 'styled-components';
import { ActionCard } from '@/src/components';
import { sandwichIds } from '@/src/constants/sandwich-ids';
import { InterestRewardStatus } from '@/src/components/Billing/InterestReward/InterestRewardStatus';
import { PlanDisclaimer } from './PlanDisclaimer';
import { OvoFoundationForm } from './components/OvoFoundationForm';
import { Products } from './components/Products';

// Temporary adjustment to the action centre layout on this page to account for
// the weird section padding stuff.
const StyledActionCentreLayoutFix = styled.div(
  ({ theme: { space, mediaQueries } }) => `
    margin-top: -${space[6]};
    margin-bottom: ${space[4]};

    ${mediaQueries.smallAndUp} {
      margin-top: -${space[8]};
      margin-bottom: ${space[8]};
    }

    &:empty {
      display: none;
    }
`,
);

const modify = {
  [sandwichIds.planPage.intro]: ({ children }: any) => (
    <>
      {children}
      <InterestRewardStatus />
    </>
  ),
  [sandwichIds.planPage.page]: ({ children }: any) => (
    <>
      <StyledActionCentreLayoutFix>
        <ActionCard
          cardsToShowByActionCode={['_service_banner_next_plan']}
          utmSource="my-plan"
        />
      </StyledActionCentreLayoutFix>
      {children}
      <PlanDisclaimer />
      <Products />
      <OvoFoundationForm />
    </>
  ),
};

export { modify };
