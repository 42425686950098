/* eslint-disable */
import * as types from './graphql';



/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query nextBestActionsQuery($nbaContainerId: String!, $preview: Boolean) {\n    buildNextBestAction(id: $nbaContainerId, preview: $preview) {\n      actionCardsCollection {\n        ...ShowableCardsFragment\n      }\n    }\n  }\n": types.NextBestActionsQueryDocument,
    "\n  query customerInformationCardQuery($containerId: String!, $preview: Boolean) {\n    buildCustomerInformationCard(id: $containerId, preview: $preview) {\n      actionCardsCollection(limit: 1) {\n        items {\n          text {\n            header\n            text {\n              json\n            }\n          }\n          mixpanelId\n          cta {\n            ...PackCTAFragment\n          }\n        }\n      }\n    }\n  }\n": types.CustomerInformationCardQueryDocument,
    "\n    query tescoQuestionAnswerQuery($containerId: String!, $preview: Boolean) {\n      buildFaQs(id: $containerId, preview: $preview) {\n        structuredTextCollection {\n          items {\n            ... on PackStructuredText {\n              sys {\n                id\n              }\n              header\n              text {\n                json\n                links {\n                  ...EntryHyperLinkFragment\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  ": types.TescoQuestionAnswerQueryDocument,
    "\n  fragment ShowableCardsFragment on BuildNextBestActionActionCardsCollection {\n    items {\n      actionCode\n      text {\n        header\n        text {\n          json\n        }\n      }\n      nonRoundedEdges\n      canBeDismissed\n      image {\n        ... on WrapperImage {\n          altText\n          desktopImage {\n            url\n            width\n          }\n        }\n      }\n      optimizelyFeatureFlag\n      inverted\n      ctaBehaviour\n      cta {\n        ...PackCTAFragment\n      }\n      mixpanelId\n    }\n  }\n": types.ShowableCardsFragmentFragmentDoc,
    "\n  fragment EntryHyperLinkFragment on PackStructuredTextTextLinks {\n    entries {\n      hyperlink {\n        sys {\n          id\n        }\n        __typename\n        ... on WrapperUrl {\n          url\n        }\n        ... on BuildWebpage {\n          slug\n        }\n        ... on BuildCategoryWebpage {\n          pathname\n        }\n        ... on WrapperDocument {\n          document {\n            url\n          }\n        }\n        ... on PackCta {\n          ...PackCTAFragment\n        }\n      }\n    }\n  }\n": types.EntryHyperLinkFragmentFragmentDoc,
    "\n  fragment PackCTAFragment on PackCta {\n    label\n    utmSource\n    utmMedium\n    utmContent\n    utmCampaign\n    queryStringExtras\n    destination {\n      __typename\n      ... on WrapperUrl {\n        url\n      }\n      ... on BuildWebpage {\n        slug\n      }\n      ... on BuildCategoryWebpage {\n        pathname\n      }\n      ... on WrapperDocument {\n        document {\n          url\n        }\n      }\n    }\n    destinationAnchor {\n      __typename\n      ... on ComponentContentBlock {\n        anchor\n      }\n      ... on ComponentContactAndUsefulLinks {\n        anchor\n      }\n    }\n  }\n": types.PackCtaFragmentFragmentDoc,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query nextBestActionsQuery($nbaContainerId: String!, $preview: Boolean) {\n    buildNextBestAction(id: $nbaContainerId, preview: $preview) {\n      actionCardsCollection {\n        ...ShowableCardsFragment\n      }\n    }\n  }\n"): typeof import('./graphql').NextBestActionsQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query customerInformationCardQuery($containerId: String!, $preview: Boolean) {\n    buildCustomerInformationCard(id: $containerId, preview: $preview) {\n      actionCardsCollection(limit: 1) {\n        items {\n          text {\n            header\n            text {\n              json\n            }\n          }\n          mixpanelId\n          cta {\n            ...PackCTAFragment\n          }\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').CustomerInformationCardQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query tescoQuestionAnswerQuery($containerId: String!, $preview: Boolean) {\n      buildFaQs(id: $containerId, preview: $preview) {\n        structuredTextCollection {\n          items {\n            ... on PackStructuredText {\n              sys {\n                id\n              }\n              header\n              text {\n                json\n                links {\n                  ...EntryHyperLinkFragment\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').TescoQuestionAnswerQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ShowableCardsFragment on BuildNextBestActionActionCardsCollection {\n    items {\n      actionCode\n      text {\n        header\n        text {\n          json\n        }\n      }\n      nonRoundedEdges\n      canBeDismissed\n      image {\n        ... on WrapperImage {\n          altText\n          desktopImage {\n            url\n            width\n          }\n        }\n      }\n      optimizelyFeatureFlag\n      inverted\n      ctaBehaviour\n      cta {\n        ...PackCTAFragment\n      }\n      mixpanelId\n    }\n  }\n"): typeof import('./graphql').ShowableCardsFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment EntryHyperLinkFragment on PackStructuredTextTextLinks {\n    entries {\n      hyperlink {\n        sys {\n          id\n        }\n        __typename\n        ... on WrapperUrl {\n          url\n        }\n        ... on BuildWebpage {\n          slug\n        }\n        ... on BuildCategoryWebpage {\n          pathname\n        }\n        ... on WrapperDocument {\n          document {\n            url\n          }\n        }\n        ... on PackCta {\n          ...PackCTAFragment\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').EntryHyperLinkFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PackCTAFragment on PackCta {\n    label\n    utmSource\n    utmMedium\n    utmContent\n    utmCampaign\n    queryStringExtras\n    destination {\n      __typename\n      ... on WrapperUrl {\n        url\n      }\n      ... on BuildWebpage {\n        slug\n      }\n      ... on BuildCategoryWebpage {\n        pathname\n      }\n      ... on WrapperDocument {\n        document {\n          url\n        }\n      }\n    }\n    destinationAnchor {\n      __typename\n      ... on ComponentContentBlock {\n        anchor\n      }\n      ... on ComponentContactAndUsefulLinks {\n        anchor\n      }\n    }\n  }\n"): typeof import('./graphql').PackCtaFragmentFragmentDoc;


export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}
