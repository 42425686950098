import { Card, Col, Heading1, Heading4, Margin, P, Row } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import { isAfter, isBefore } from 'date-fns';
import React, { useContext, useEffect } from 'react';
import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';
import {
  Anytime,
  GreenerEnergyOfferOneClick,
  HomeHeatOffer,
  HomePlanDiscountPromoOffer,
  HomePlanOffer,
  OffersLoading,
  SmartCharger,
  SmartMeterBooking,
} from './components';
import { PtzOffers } from './components/PtzOffers';

import { AnnualBoilerService } from './components/cards/AnnualBoilerService';
import { AbsHalfPriceOffer } from './components/cards/AbsHalfPriceOffer';
import {
  DISCOUNT_END_DATE,
  DISCOUNT_START_DATE,
  OHS_Q1_OFFER_END_DATE,
  OHS_Q1_OFFER_START_DATE,
} from './constants';
import { getIsPTZOffersAvailable } from './utils';
import { useContractsQuery } from '@/src/api/use-contracts-query';
import { useDirectDebitQuery } from '@/src/api/use-direct-debit-query';
import { useProfileQueryKapi } from '@/src/api/kapi/use-profile-query';

import {
  AnalyticsWrapper,
  DisplayFlex,
  FootnoteCommon,
  FootnoteProvider,
  FootnoteYield,
  Section,
} from '@/src/components';

import { VIEWED_OFFERS_SCREEN } from '@/src/constants/analytics';

import {
  DRIVE_ANYTIME,
  ENERGY_CREDITS_PROMO,
  ENERGY_CREDITS_PROMO_FIXED,
  HOME_RECOVER_OFFER,
  HOMEPLAN_DISCOUNT_PROMO,
  HOMEPLAN_OFFER,
  HOMEPLAN_Q1_PROMO_OFFER,
  SHOW_ABS_IN_MORE_OFFERS,
  SHOW_GREENER_ENERGY,
  SHOW_PATH_TO_ZERO_OFFERS,
  HOMEPLAN_ABS_Q2_OFFER,
  REMOVE_ENERGY_CREDITS_1_YEAR_FIXED,
  SHOW_GREENER_ELECTRICITY,
} from '@/src/constants/feature-flags';
import { PRODUCT_IDS } from '@/src/constants/products';
import { OFFERS_AND_UPGRADES_SECTION_ID } from '@/src/constants/sectionIds';
import { useCorgiProductsQuery } from '@/src/api/use-corgi-products-query';
import { breakpoints } from '@/src/utils/mediaQuery';
import { HomeRecoverOffer } from '@/src/pages/Offers/components/cards/HomeRecoverOffer';
import { getProductCatalogueStart } from '@/src/redux/ducks/products/products';
import { getDateRangeMonthlyUsageStart } from '@/src/redux/ducks/usage/monthly-usage';
import { State } from '@/src/types/State';

import { hasActiveContract, hasActivePlan } from '@/src/utils/contracts';
import {
  OptimizelyContext,
  OptimizelyFeature,
  useDecision,
  useFeature,
} from '@/src/utils/optimizely';
import {
  getEligibleProductFromCatalogue,
  getZeroCarbonHeatingOffer,
} from '@/src/utils/products';
import { useChargeAnytimeBoltonState } from '../EVs/hooks/useChargeAnytimeBoltonState';
import { hasExactActivePlan } from '@/src/utils/contracts/hasExactActivePlan';
import { useEligibilityInfo } from '../SmartThermostats/hooks/useEligibilityInfo';
import BeyondBanner from '@/src/pages/Offers/components/BeyondBanner';
import { useBeyondAccountStatusQuery } from '@/src/api/use-beyond-account-status';
import { BeyondAccountStatus } from '@/src/types/Response';
import { GreenerElectricityOffer } from './components/cards/GreenerElectricityOffer';

const corgiPromos = [
  'homePlanPromo',
  'homePlanDiscountPromo',
  'homerecover',
  'homeheat',
];

export const OffersAndUpgrades = () => {
  const { accountId } = useAccountContext();
  const { data: beyondAccountStatusData } = useBeyondAccountStatusQuery();
  const { optimizely } = useContext(OptimizelyContext);

  const dispatch = useDispatch();

  const catalogue = useSelector((state: State) => state.products.catalogue);
  const smartMeter = useSelector(
    (state: State) =>
      state.app.gasSupply?.supplyPointInfo?.isSmart ||
      state.app.elecSupply?.supplyPointInfo?.isSmart,
  );

  const [pathToZeroOffersDecision] = useDecision(SHOW_PATH_TO_ZERO_OFFERS);
  const [homeplanQ1PromoOfferDecision] = useDecision(HOMEPLAN_Q1_PROMO_OFFER);
  const [HomeplanABSQ2OfferDecision] = useDecision(HOMEPLAN_ABS_Q2_OFFER);
  const [removeEnergyCredits1YearFixedDecision] = useDecision(
    REMOVE_ENERGY_CREDITS_1_YEAR_FIXED,
  );

  const [showGreenerEnergy] = useFeature(SHOW_GREENER_ENERGY);
  const [showGreenerElectricity] = useFeature(SHOW_GREENER_ELECTRICITY);
  const [energyCreditsPromo] = useFeature(ENERGY_CREDITS_PROMO);
  const [energyCreditsPromoFixed] = useFeature(ENERGY_CREDITS_PROMO_FIXED);
  const [homeplanDiscountPromo] = useFeature(HOMEPLAN_DISCOUNT_PROMO);
  const [homeplanOffer] = useFeature(HOMEPLAN_OFFER);

  const smartThermostatsEligibility = useEligibilityInfo().eligible;
  const profile = useProfileQueryKapi();
  const contracts = useContractsQuery(accountId);
  const directDebit = useDirectDebitQuery(accountId);
  const corgiProducts = useCorgiProductsQuery();
  const { hasChargeAnytime } = useChargeAnytimeBoltonState();

  const showPathToZeroOffers =
    pathToZeroOffersDecision.enabled &&
    getIsPTZOffersAvailable(
      pathToZeroOffersDecision.variables,
      smartThermostatsEligibility,
    );

  const hasDirectDebit = directDebit.data?.amount !== undefined;

  const currentYear = new Date(Date.now()).getFullYear();
  const years = [currentYear, currentYear - 1];

  const hasHomePlan =
    corgiProducts.data && corgiProducts.data.includes('CorgiHomePlan');

  const offers: React.ReactNode[] = [];

  const offerPromos = [];

  useEffect(() => {
    dispatch(getProductCatalogueStart());
    dispatch(getDateRangeMonthlyUsageStart(years.map(year => year.toString())));
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProductCatalogueStart, getDateRangeMonthlyUsageStart]);

  const isLoading =
    !catalogue.data ||
    corgiProducts.isFetching ||
    corgiProducts.isLoading ||
    contracts.isLoading ||
    profile.isLoading;

  if (isLoading) {
    return <OffersLoading />;
  }

  const isPostCodeSouthEast = getZeroCarbonHeatingOffer(
    profile?.data?.customer_nextV1.contactAddress?.postalAddress.postalCode ??
      '',
  );

  const hasGreenerEnergyOffer =
    showGreenerEnergy &&
    catalogue.data &&
    !!getEligibleProductFromCatalogue(
      catalogue.data,
      PRODUCT_IDS.greenerEnergy,
    );

  const hasGreenerElectricityOffer =
    showGreenerElectricity &&
    catalogue.data &&
    !!getEligibleProductFromCatalogue(
      catalogue.data,
      PRODUCT_IDS.greenerElectricity,
    );

  const isQ2AbsOnlyOffer = HomeplanABSQ2OfferDecision.enabled && !hasHomePlan;

  if (isQ2AbsOnlyOffer) {
    offers.push(<AbsHalfPriceOffer />);
    offerPromos.push('homeplanabsq2offer');
  }

  if (hasGreenerEnergyOffer) {
    offers.push(<GreenerEnergyOfferOneClick />);
  }

  if (hasGreenerElectricityOffer) {
    offers.push(<GreenerElectricityOffer />);
  }

  const hasGas = contracts.data && hasActiveContract(contracts.data.gas);

  const showHomePlanSvtOffer =
    contracts.data &&
    hasActiveContract(contracts.data.electricity) &&
    hasActiveContract(contracts.data.gas) &&
    (hasActivePlan(contracts.data.gas, 'Simpler Energy') ||
      hasActivePlan(contracts.data.electricity, 'Simpler Energy')) &&
    hasDirectDebit &&
    energyCreditsPromo;

  const showHomePlanFixedOffer =
    contracts.data &&
    hasActiveContract(contracts.data.electricity) &&
    hasActiveContract(contracts.data.gas) &&
    (hasExactActivePlan(contracts.data.gas, '2 Year Fixed') ||
      hasExactActivePlan(contracts.data.electricity, '2 Year Fixed') ||
      hasExactActivePlan(contracts.data.gas, '1 Year Fixed + Greener Energy') ||
      hasExactActivePlan(
        contracts.data.electricity,
        '1 Year Fixed + Greener Energy',
      ) ||
      hasExactActivePlan(contracts.data.gas, '1 Year Fixed Loyalty') ||
      hasExactActivePlan(contracts.data.electricity, '1 Year Fixed Loyalty') ||
      (!removeEnergyCredits1YearFixedDecision.enabled &&
        (hasExactActivePlan(contracts.data.gas, '1 Year Fixed') ||
          hasExactActivePlan(contracts.data.electricity, '1 Year Fixed')))) &&
    hasDirectDebit &&
    energyCreditsPromoFixed;

  if (hasGas && !hasHomePlan && accountId) {
    const currentDate = new Date();

    const isDiscountPromo =
      isAfter(currentDate, DISCOUNT_START_DATE) &&
      isBefore(currentDate, DISCOUNT_END_DATE) &&
      homeplanDiscountPromo;

    if (isDiscountPromo) {
      offers.push(<HomePlanDiscountPromoOffer accountId={accountId} />);
      offerPromos.push('homePlanDiscountPromo');
    } else if (homeplanOffer) {
      const showQ1HomePlanOffer =
        homeplanQ1PromoOfferDecision.enabled &&
        isAfter(currentDate, OHS_Q1_OFFER_START_DATE) &&
        isBefore(currentDate, OHS_Q1_OFFER_END_DATE);

      offers.push(
        <HomePlanOffer
          showHomePlanSvtOffer={showHomePlanSvtOffer}
          showQ1HomePlanOffer={showQ1HomePlanOffer}
          showHomePlanFixedOffer={showHomePlanFixedOffer}
          accountId={accountId}
        />,
      );

      offerPromos.push('homePlanPromo');
    }
  }

  if (
    optimizely?.isFeatureEnabled(SHOW_ABS_IN_MORE_OFFERS) &&
    accountId &&
    !isQ2AbsOnlyOffer
  ) {
    offers.push(<AnnualBoilerService accountId={accountId} />);
  }

  if (
    optimizely &&
    optimizely.isFeatureEnabled(HOME_RECOVER_OFFER) &&
    accountId
  ) {
    const currentDate = new Date();
    const showQ1Offer =
      homeplanQ1PromoOfferDecision.enabled &&
      isAfter(currentDate, OHS_Q1_OFFER_START_DATE) &&
      isBefore(currentDate, OHS_Q1_OFFER_END_DATE);

    offers.push(
      <HomeRecoverOffer showQ1Offer={showQ1Offer} accountId={accountId} />,
    );
    offerPromos.push('homerecover');
  }

  if (hasGas) {
    offers.push(<HomeHeatOffer />);
    offerPromos.push('homeheat');
  }

  if (isPostCodeSouthEast) {
    offerPromos.push('zeroCarbonHeating');
  }

  if (
    optimizely &&
    optimizely.isFeatureEnabled(DRIVE_ANYTIME) &&
    !hasChargeAnytime
  ) {
    offers.push(
      <OptimizelyFeature feature={DRIVE_ANYTIME}>
        {isEnabled => (isEnabled ? <SmartCharger /> : null)}
      </OptimizelyFeature>,
    );
    offerPromos.push('smartCharger');
  }

  if (optimizely && optimizely.isFeatureEnabled(DRIVE_ANYTIME) && !smartMeter) {
    offers.push(
      <OptimizelyFeature feature={DRIVE_ANYTIME}>
        {isEnabled => (isEnabled ? <SmartMeterBooking /> : null)}
      </OptimizelyFeature>,
    );
    offerPromos.push('smartMeterBooking');
  }

  if (
    optimizely &&
    optimizely.isFeatureEnabled(DRIVE_ANYTIME) &&
    !hasChargeAnytime
  ) {
    offers.push(
      <OptimizelyFeature feature={DRIVE_ANYTIME}>
        {isEnabled => (isEnabled ? <Anytime /> : null)}
      </OptimizelyFeature>,
    );
    offerPromos.push('ovoDriveAnytime');
  }

  const footnoteContent = [
    <>
      OVO Energy Ltd is registered in England and Wales, company number
      06890795. Registered office: 1 Rivergate, Temple Quay, Bristol, BS1 6ED.
      <br></br>
    </>,
  ];

  if (offerPromos.some(promo => corgiPromos.includes(promo))) {
    footnoteContent.unshift(
      <>
        OVO Energy Ltd is an appointed representative of CORGI HomePlan (for
        general insurance distribution) which is authorised and regulated by the
        Financial Conduct Authority under firm reference number 824122
      </>,
      <>CORGI HomePlan Ltd and OVO Energy Ltd are part of the OVO Group Ltd.</>,
    );
  }

  const isIneligible =
    beyondAccountStatusData?.beyondAccountStatus.status ===
    BeyondAccountStatus.Ineligible;

  const showBeyondBanner =
    beyondAccountStatusData?.beyondAccountStatus.status && !isIneligible;

  const beyondBannerContent = {
    top: {
      heading: 'Unlock our best offers with Beyond.',
      subHeading: 'Only via the OVO app',
      ctaText: 'Learn more',
    },
    bottom: {
      heading: 'Get exclusive discounts and ways to save up to £1,500¹.',
      subHeading: 'With Beyond, via the OVO app',
      ctaText: 'Start saving',
    },
  };

  return (
    <FootnoteProvider markerType="number">
      <Section id={OFFERS_AND_UPGRADES_SECTION_ID}>
        <Margin bottom={showBeyondBanner ? 4 : 6}>
          <Card>
            {showPathToZeroOffers ? (
              <Media query={`${breakpoints.mobileNav}`}>
                {matches => (
                  <Heading1>
                    Featured {matches && <br />} offers and upgrades
                  </Heading1>
                )}
              </Media>
            ) : (
              <Heading1>Offers &amp; upgrades</Heading1>
            )}
          </Card>
        </Margin>

        {showBeyondBanner && <BeyondBanner {...beyondBannerContent.top} />}

        {accountId ? (
          <>
            {!!showPathToZeroOffers && <PtzOffers />}
            {offers.length ? (
              <>
                <AnalyticsWrapper
                  name={VIEWED_OFFERS_SCREEN}
                  properties={{ offerPromos }}
                >
                  <Row isNested data-testid="offers-row">
                    {offers.map((offer, index) => (
                      <Col medium={6} key={`offer-${index}`} as={DisplayFlex}>
                        {offer}
                      </Col>
                    ))}
                  </Row>
                </AnalyticsWrapper>
                {showBeyondBanner && (
                  <BeyondBanner {...beyondBannerContent.bottom} />
                )}
                <FootnoteCommon content={footnoteContent} />
                <FootnoteYield
                  heading={<Heading4>Important Information</Heading4>}
                />
              </>
            ) : (
              <Card>
                <P>
                  We don't have any offers available at the moment, please check
                  back later!
                </P>
              </Card>
            )}
          </>
        ) : (
          <OffersLoading />
        )}
      </Section>
    </FootnoteProvider>
  );
};
