type Watts = number;
type IsoDateString = string;
type UnixTime = number;
export type TenSecondSampleResponse = {
  readingTime: IsoDateString;
  reading: Watts;
};

export type TenSecondDataResponse = { data: Array<TenSecondSampleResponse> };

export type TenSecondSample = {
  readingTime: UnixTime;
  reading: Watts;
};

export const toTenSecondSample = (
  x: TenSecondSampleResponse,
): TenSecondSample => ({
  ...x,
  readingTime: new Date(x.readingTime).getTime(),
});
