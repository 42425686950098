import { AnalyticsClick } from '@ovotech/ui-tools';
import {
  addMonths,
  format,
  subMonths,
  differenceInCalendarMonths,
} from 'date-fns';
import React from 'react';
import {
  NavChevronRight,
  NavChevronLeft,
  MonthNavSection,
  MonthlyNavHeading,
} from './MonthlyNavigator.styled';
import {
  EVS_INLIFE_MONTHLY_NAVIGATION_PREVIOUS,
  EVS_INLIFE_MONTHLY_NAVIGATION_NEXT,
} from '@/src/constants/analytics-subjects';
import { ROUTE_EV } from '@/src/constants/routes';
import NavigationLink from '@/src/components/PreviousNextNavigation/NavigationLink';
import { MonthYear } from '@/src/types/State';
import { useChargeAnytimeBoltonState } from '../../hooks/useChargeAnytimeBoltonState';

const getRoute = (date: Date) => ({
  pathname: `${ROUTE_EV}/${format(date, 'YYYY/MM')}`,
});

const getPrevMonth = (date: Date) => subMonths(date, 1);

const getNextMonth = (date: Date) => addMonths(date, 1);

const getPrevMonthRoute = (date: Date) => getRoute(getPrevMonth(date));

const getNextMonthRoute = (date: Date) => getRoute(getNextMonth(date));

const toDisplayFormat = (date: Date) => format(date, 'MMMM YYYY');

// When using date-fns, if given 1 as the month
// number, it will show Feburary.
export const FNS_MONTH_INDEX_CORRECTION = 1;
const convertMonthYearToDateObj = (date: MonthYear) =>
  new Date(date.year, date.month - FNS_MONTH_INDEX_CORRECTION, 1);

type Props = {
  dateNavigatedTo: MonthYear;
};

const MonthlyNavigator = ({ dateNavigatedTo }: Props) => {
  const { anytimeBoltonActive, anytimeBoltonActiveFrom } =
    useChargeAnytimeBoltonState();

  const dateObj = convertMonthYearToDateObj(dateNavigatedTo);
  const currentDateObj = new Date();
  const differenceInCalenderMonths = differenceInCalendarMonths(
    currentDateObj,
    dateObj,
  );
  const isNavigatedToCurrentMonth =
    toDisplayFormat(dateObj) === toDisplayFormat(currentDateObj);

  const disablePreviousMonth = () => {
    if (anytimeBoltonActive) {
      if (anytimeBoltonActiveFrom) {
        if (dateObj.getTime() <= anytimeBoltonActiveFrom.getTime()) {
          return true;
        }
        return false;
      }
      if (differenceInCalenderMonths > 13) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  return (
    <MonthNavSection data-testid="month-navigator">
      <AnalyticsClick name={EVS_INLIFE_MONTHLY_NAVIGATION_PREVIOUS}>
        <NavigationLink
          data-testid="month-navigator-to-left"
          to={getPrevMonthRoute(dateObj)}
          disabled={disablePreviousMonth()}
          aria-label={`Navigate to ${toDisplayFormat(getPrevMonth(dateObj))}`}
        >
          <NavChevronLeft />
        </NavigationLink>
      </AnalyticsClick>
      <MonthlyNavHeading> {toDisplayFormat(dateObj)} </MonthlyNavHeading>
      <AnalyticsClick name={EVS_INLIFE_MONTHLY_NAVIGATION_NEXT}>
        <NavigationLink
          data-testid="month-navigator-to-right"
          to={getNextMonthRoute(dateObj)}
          disabled={isNavigatedToCurrentMonth}
          aria-label={`Navigate to ${toDisplayFormat(getNextMonth(dateObj))}`}
        >
          <NavChevronRight />
        </NavigationLink>
      </AnalyticsClick>
    </MonthNavSection>
  );
};

export default MonthlyNavigator;
