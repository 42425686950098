import { P, Card, Radio, RadioField, PrimaryCTAButton } from '@ovotech/nebula';
import { useAnalytics } from '@ovotech/ui-tools';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom-v5';
import styled from 'styled-components';
import { EVAnytimeEligibilitySectionHeader } from './EVAnytimeEligibilitySectionHeader';
import { EV } from '@/src/constants/analytics';
import { ROUTE_EV } from '@/src/constants/routes';
import { hasSmartMeter, hasSMETS2Meters } from '@/src/pages/Renewal/selectors';
import { State } from '@/src/types/State';
import { changeSmartChargerSelection } from '@/src/redux/ducks/evs/evAnytime';
import { ReactComponent as SmartChargerIcon } from '@/src/resources/smart-charger.svg';
import { EVAnytimeStepProps } from '../../../types';
import { useFeature } from '@/src/utils/optimizely';
import {
  ANDERSEN_CHARGE_ANYTIME,
  HYPERVOLT_CHARGE_ANYTIME,
} from '@/src/constants/feature-flags';
import { useChargeAnytimeBoltonState } from '../../../hooks/useChargeAnytimeBoltonState';
import { SmartChargers, smartChargerSelections } from '@monovo/ev-anytime';

const CardContentWrapper = styled.div`
  max-width: 640px;
`;

const SectionContainer = styled.div`
  margin-bottom: 25px;
`;

const StyledP = styled(P)`
  color: #2f3749;
`;

export const EVAnytimeSmartCharger = ({
  moveNext,
}: {
  moveNext: EVAnytimeStepProps['moveNext'];
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { anytimeBoltonActive } = useChargeAnytimeBoltonState();
  const { dispatch: analyticsDispatch } = useAnalytics();
  const [hypervoltChargeAnytimeEnabled] = useFeature(HYPERVOLT_CHARGE_ANYTIME);
  const [andersenChargeAnytimeEnabled] = useFeature(ANDERSEN_CHARGE_ANYTIME);

  const OPTIONS = useMemo(() => {
    const exclusions = [];
    if (!hypervoltChargeAnytimeEnabled) {
      exclusions.push(SmartChargers.Hypervolt);
    }
    if (!andersenChargeAnytimeEnabled) {
      exclusions.push(SmartChargers.Andersen);
    }
    return smartChargerSelections(exclusions);
  }, [hypervoltChargeAnytimeEnabled, andersenChargeAnytimeEnabled]);

  const { smartChargerSelection } = useSelector(
    ({ evAnytime }: State) => evAnytime,
  );
  const hasNoSmartMeter = !useSelector(hasSmartMeter);
  const hasSMETS2SmartMeter = useSelector(hasSMETS2Meters);

  if (anytimeBoltonActive) {
    history.push(ROUTE_EV);
  }

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: EV.ELIGIBILITY
        .EVS_OVO_CHARGE_ANYTIME_ELIGIBILITY_SMART_CHARGER_VIEWED,
      properties: {
        hasNoSMETS2SmartMeter: !hasSMETS2SmartMeter,
        hasNoSmartMeter,
      },
    });
    // `analyticsDispatch` method is not memoized and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNoSmartMeter, hasSMETS2SmartMeter]);

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: 'EVS - EVS OVO Drive Anytime Smartcharger Eligibility Viewed',
      properties: {
        hasNoSMETS2SmartMeter: !hasSMETS2SmartMeter,
        hasNoSmartMeter,
      },
    });
    // `analyticsDispatch` method is not memoized and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNoSmartMeter, hasSMETS2SmartMeter]);

  useEffect(() => {
    // Preselect default value
    dispatch(changeSmartChargerSelection(OPTIONS[0].value));
  }, [OPTIONS, dispatch]);

  const goToNextPage = () => {
    analyticsDispatch({
      type: 'click',
      name: EV.ELIGIBILITY
        .EVS_OVO_CHARGE_ANYTIME_ELIGIBILITY_SMART_CHARGER_SUBMIT_CLICKED,
      properties: {
        chargerName: smartChargerSelection,
      },
    });

    analyticsDispatch({
      type: 'click',
      name: 'EVS - EVS OVO Charge Anytime Charger Select submit clicked',
      properties: {
        chargerName: smartChargerSelection,
      },
    });

    moveNext();
  };

  const handleSelectionChange = (selected: SmartChargers) => {
    dispatch(changeSmartChargerSelection(selected));
  };

  return (
    <Card>
      <CardContentWrapper>
        <SectionContainer>
          <EVAnytimeEligibilitySectionHeader Icon={SmartChargerIcon}>
            What EV charger do you have at home?
          </EVAnytimeEligibilitySectionHeader>
          <StyledP>
            If you don’t have one of the chargers below, or don’t have a
            charger, don’t worry: you can still connect to Charge Anytime, given
            the rest of your set-up is compatible.
            <br />
            You can find the charger model name on the sticker on the side of
            the charger.
          </StyledP>

          <RadioField label="" name="selected">
            {OPTIONS.map(r => (
              <Radio
                id={r.value}
                key={r.value}
                value={r.value}
                label={r.text}
                checked={smartChargerSelection === r.value}
                onChange={() => handleSelectionChange(r.value)}
              />
            ))}
          </RadioField>
        </SectionContainer>
        <PrimaryCTAButton onClick={goToNextPage}>
          Next: tell us about your EV and home
        </PrimaryCTAButton>
      </CardContentWrapper>
    </Card>
  );
};
