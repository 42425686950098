import {
  Heading3,
  P,
  Stack,
  Card,
  Heading4,
  Strong,
  Margin,
} from '@ovotech/nebula';
import React from 'react';
import { roundTo1DP } from '@/src/utils/number';
import {
  CardWithRightIcon,
  CardWithRightIconTime,
  StyledTableRow,
  StyledTableCell,
  StyledStrong,
  StyledHeading3,
  Overlay,
  BarChart,
  StyledBarOverlayContainer,
  StyledEllipse,
  StyledHr,
  StyledBr,
  StyledP,
} from './EVInsights.styled';
import { ReactComponent as AutomationIcon } from '@/src/resources/automation-icon.svg';
import { ReactComponent as BatteryIcon } from '@/src/resources/green-battery-icon.svg';
import { EVsInlifeInsights } from '@/src/types/State';
import { useChargeAnytimeBoltonState } from '../../hooks/useChargeAnytimeBoltonState';

const minutesInOneMonth = 43200;
const convertMinutesIntoHours = (minutesInNumber: number) => {
  const minutes = minutesInNumber % 60;
  const hours = (minutesInNumber - minutes) / 60;
  return `${hours}h ${minutes}m`;
};
export const EVInsights = ({
  insightData,
}: {
  insightData: EVsInlifeInsights | undefined;
}) => {
  const { anytimeBoltonActive } = useChargeAnytimeBoltonState();

  const hasCurrentMonthData = Boolean(
    insightData?.currentMonth.boostingMinutes &&
      insightData?.currentMonth.averagePluginTimeHoursPerDay &&
      insightData.currentMonth.chargingMinutes,
  );

  const hasLastMonthData = Boolean(
    insightData?.previousMonth.boostingMinutes &&
      insightData?.previousMonth.averagePluginTimeHoursPerDay &&
      insightData.previousMonth.chargingMinutes,
  );

  const hasBoostingData = Boolean(
    insightData?.previousMonth.boostingMinutes ||
      insightData?.currentMonth.boostingMinutes,
  );

  const hasChargingData = Boolean(
    insightData?.previousMonth.chargingMinutes ||
      insightData?.currentMonth.chargingMinutes,
  );

  if (insightData && (hasCurrentMonthData || hasLastMonthData)) {
    return (
      <>
        <StyledHeading3 as={Heading3}>
          Your home-charging insights
        </StyledHeading3>
        <StyledP as={P}>
          They’re based on how long you’ve charged your car
        </StyledP>
        <Stack spaceBetween={4} data-testid="ev-boost-section">
          {hasChargingData && (
            <CardWithRightIconTime as={Card} image={AutomationIcon}>
              <Heading4>
                Charge for longer at home for an even greener impact
              </Heading4>
              <P>You’ve plugged in your charger for about:</P>
              {hasCurrentMonthData && (
                <StyledTableRow
                  aria-colcount={3}
                  data-testid="insights-current-month-plugin-hours"
                >
                  <StyledTableCell width="16%">
                    <Strong>This month </Strong>
                  </StyledTableCell>
                  <StyledBarOverlayContainer>
                    <Overlay />
                    <BarChart
                      color={'#B6D727'}
                      amount={
                        (insightData?.currentMonth
                          ?.averagePluginTimeHoursPerDay /
                          24) *
                        100
                      }
                    />
                  </StyledBarOverlayContainer>
                  <StyledTableCell width="30%">
                    <StyledStrong>{`${roundTo1DP(
                      insightData?.currentMonth.averagePluginTimeHoursPerDay,
                    )} hrs a day`}</StyledStrong>{' '}
                    <span>(so far)</span>
                  </StyledTableCell>
                </StyledTableRow>
              )}
              {hasLastMonthData && (
                <StyledTableRow
                  aria-colcount={3}
                  data-testid="insights-previous-month-plugin-hours"
                >
                  <StyledTableCell width="16%">
                    <Strong>Last month </Strong>
                  </StyledTableCell>
                  <StyledBarOverlayContainer>
                    <Overlay />
                    <BarChart
                      color={'#B6D727'}
                      amount={
                        (insightData?.previousMonth
                          ?.averagePluginTimeHoursPerDay /
                          24) *
                        100
                      }
                    />
                  </StyledBarOverlayContainer>
                  <StyledTableCell width="25%">
                    <StyledStrong>{`${roundTo1DP(
                      insightData?.previousMonth.averagePluginTimeHoursPerDay,
                    )} hrs a day`}</StyledStrong>
                  </StyledTableCell>
                </StyledTableRow>
              )}
              <P>
                Just by keeping your car plugged in at home for longer, your
                clever charger can work out the greenest time to start topping
                it up. It takes the pressure off the national grid, making it
                easier for the UK to be powered by lovely, green renewables.
              </P>
            </CardWithRightIconTime>
          )}
          {!anytimeBoltonActive && hasBoostingData && (
            <CardWithRightIcon
              data-testid="boosting-data"
              as={Card}
              image={BatteryIcon}
            >
              <Heading4>Boost less to save carbon</Heading4>
              <P>Here’s how much time you spent boosting and charging:</P>
              {hasCurrentMonthData && (
                <>
                  <StyledTableRow
                    aria-colcount={3}
                    data-testid="insights-current-month-boost-hours"
                  >
                    <StyledTableCell width="16%">
                      <Strong>This month </Strong>
                    </StyledTableCell>
                    <StyledBarOverlayContainer>
                      <Overlay />

                      <BarChart
                        color={'#555C6B'}
                        amount={
                          (insightData?.currentMonth?.boostingMinutes /
                            minutesInOneMonth) *
                          100
                        }
                      />
                    </StyledBarOverlayContainer>

                    <StyledTableCell width="32%">
                      <StyledStrong>
                        {convertMinutesIntoHours(
                          insightData?.currentMonth.boostingMinutes,
                        )}
                      </StyledStrong>{' '}
                      <span>(so far)</span>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow aria-colcount={3}>
                    <StyledTableCell width="16%" />
                    <StyledBarOverlayContainer>
                      <Overlay />
                      <BarChart
                        color={'#6CBD27'}
                        amount={
                          (insightData?.currentMonth?.chargingMinutes /
                            minutesInOneMonth) *
                          100
                        }
                      />
                    </StyledBarOverlayContainer>
                    <StyledTableCell width="32%">
                      <StyledStrong>
                        {convertMinutesIntoHours(
                          insightData?.currentMonth.chargingMinutes,
                        )}
                      </StyledStrong>{' '}
                      <span>(so far)</span>
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              )}
              <StyledTableRow aria-colcount={3}>
                <StyledTableCell width="16%" />
                <StyledBr />
                <StyledHr />
              </StyledTableRow>
              {hasLastMonthData && (
                <>
                  <StyledTableRow
                    aria-colcount={3}
                    data-testid="insights-previous-month-boost-hours"
                  >
                    <StyledTableCell width="16%">
                      <Strong>Last month </Strong>
                    </StyledTableCell>
                    <StyledBarOverlayContainer>
                      <Overlay />
                      <BarChart
                        color={'#555C6B'}
                        amount={
                          (insightData?.previousMonth?.boostingMinutes /
                            minutesInOneMonth) *
                          100
                        }
                      />
                    </StyledBarOverlayContainer>
                    <StyledTableCell width="32%">
                      <StyledStrong>
                        {convertMinutesIntoHours(
                          insightData?.previousMonth.boostingMinutes,
                        )}
                      </StyledStrong>{' '}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow aria-colcount={3}>
                    <StyledTableCell width="16%" />
                    <StyledBarOverlayContainer>
                      <Overlay />
                      <BarChart
                        color={'#6CBD27'}
                        amount={
                          (insightData?.previousMonth?.chargingMinutes /
                            minutesInOneMonth) *
                          100
                        }
                      />
                    </StyledBarOverlayContainer>
                    <StyledTableCell width="32%">
                      <StyledStrong>
                        {convertMinutesIntoHours(
                          insightData?.previousMonth.chargingMinutes,
                        )}
                      </StyledStrong>{' '}
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              )}
              <Margin top={4}>
                <StyledTableRow aria-colcount={3}>
                  <StyledTableCell width="25%" />
                  <Strong>
                    <StyledEllipse color={'#555C6B'} />
                    Boosting
                  </Strong>

                  <Strong>
                    <StyledEllipse color={'#6CBD27'} />
                    Charging
                  </Strong>
                </StyledTableRow>
              </Margin>
              <P>
                By boosting less, you help the grid generate energy only when
                it’s needed. Paving the way for a greener system, watt by watt!
              </P>
            </CardWithRightIcon>
          )}
        </Stack>
      </>
    );
  } else {
    return null;
  }
};
