export const DISCOUNT_START_DATE = new Date('2023-01-12T00:00:01');
export const DISCOUNT_END_DATE = new Date('2023-04-20T23:59:59');
export const ABS_NORMAL_LINK =
  'https://www.ovoenergy.com/annual-boiler-service';
export const ABS_TERTIARY_LINK =
  'https://www.ovoenergy.com/path-to-zero/annual-boiler-service';
export const ABS_TERMS_LINK =
  'https://homeservices.ovoenergy.com/static/terms-abs-4d98f44d05fc44e394c55a5a2aa4dcfd.pdf?_gl=1*ttkfpy*_ga*MTA3NDUzNjQyOS4xNjc2OTgyNjc2*_ga_LBJ8PXL29R*MTY5MTU4OTY1OS4xNjAuMC4xNjkxNTg5NjY2LjAuMC4w';
export const EE_TERMS_LINK = 'https://www.ovoenergy.com/terms/energy-experts';
export const EE_TERTIARY_LINK =
  'https://www.ovoenergy.com/path-to-zero/energy-experts';
export const ABS_PATH_TO_ZERO_TERMS_LINK =
  'https://www.ovoenergy.com/terms/path-to-zero/annual-boiler-service';
export const TADO_TERMS_AND_CONDITIONS =
  'https://www.ovoenergy.com/terms/smart-thermostat';
export const DISCOVER_TADO =
  'https://www.ovoenergy.com/smart-home/smart-thermostat';
export const FREE_FOR_FOUR_OFFER_START_DATE = new Date('2023-10-03T08:00Z');
export const FREE_FOR_FOUR_OFFER_END_DATE = new Date('2023-12-12T08:00Z');
export const ANNUAL_BOILER_SERVICE_DESCRIPTION =
  'A Gas Safe engineer will service your boiler and check it’s running safely and efficiently.';
export const ANNUAL_BOILER_SERVICE_TITLE = 'Annual boiler service';
export const ANNUAL_BOILER_SERVICE_GET_SERVICE_PLAN = 'Get your service plan';
export const ANNUAL_BOILER_SERVICE_INFO_LINK_TEXT = 'What’s included?';

export const INCLUDED_LIST = [
  'A full assessment of how your home uses energy',
  'Energy-saving advice in a Home Health Report',
  'A brand new Energy Performance Certificate (EPC) worth £37',
];

export const BOILER_LIST = [
  'Less than 18 years old',
  'A brand we service',
  'In good working condition',
  'Not insured by OVO or CORGI HomePlan',
];

export const ANNUAL_BOILER_SERVICE_FF_VARIABLE = 'annual_boiler_service';
export const ENERGY_EXPERT_FF_VARIABLE = 'energy_expert';
export const SMART_THERMOSTAT_FF_VARIABLE = 'smart_thermostat';

export const BLACK_FRIDAY_OFFER_START_DATE = new Date('2023-11-21T00:00Z');
export const BLACK_FRIDAY_OFFER_END_DATE = new Date('2023-11-29T23:59Z');

export const OHS_Q1_OFFER_START_DATE = new Date('2024-01-09T00:00Z');
export const OHS_Q1_OFFER_END_DATE = new Date('2024-03-21T23:59Z');

export const ABS_HALF_PRICE_TITLE = 'Get your boiler ready for winter.';
export const ABS_HALF_PRICE_GET_PLAN = 'Get your boiler serviced before winter';
export const ABS_HALF_PRICE_LIST = [
  'Spread the cost of your boiler service',
  'Help keep your boiler running more efficiently for longer',
  'Save up to £42 a year',
  'Access our network of Gas Safe registered engineers',
];
