import { useFeatureContext } from '@ovotech/energy-cx';
import { Sandwich } from '@ovotech/ui-tools';
import { OverlayProvider } from '@react-aria/overlays';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { StyledApp, ErrorBoundary } from '@/src/components';
import { SmartBanner } from '@/src/components/SmartBanner/SmartBanner';
import { MAIN_PAGE_ERROR } from '@/src/constants/error-section-ids';
import {
  BILLING_HISTORY_CALLOUT,
  EXPAND_FIRST_BILLING_PERIOD,
  MAINTENANCE_MODE,
  MOVE_OUT_CTA,
  OMR_WITHOUT_MSN,
  SHOW_RECOMMENDED_DIRECT_DEBIT,
  METER_SUMMARY,
  BILLING_COMPREHENSION,
} from '@/src/constants/feature-flags';
import { sandwichIds } from '@/src/constants/sandwich-ids';
import { MaintenanceMode } from '@/src/pages/MaintenanceMode/MaintenanceMode';
import { State } from '@/src/types/State';
import {
  OptimizelyFeature,
  setLogger,
  optimizely,
} from '@/src/utils/optimizely';

interface Props {
  accountId: null | string;
  customerId: string;
  children?: ReactNode;
  hasDirectDebit: boolean;
  isDebtRisk: boolean;
  isFetched: boolean;
}

type SupplyMeterType = 'NO_SUPPLY' | 'TRAD' | 'SMART';

export const Main = ({
  accountId,
  children,
  customerId,
  hasDirectDebit,
  isDebtRisk,
  isFetched,
}: Props) => {
  /** for dev & uat envs: at this level, we set the logger to null
   * we will be able to see some Optimizely logs in the console
   * however, if we remove this line, the console will display an excessive number of Optimizely logs. */
  setLogger(null);

  const gasSupply = useSelector((state: State) => state.app.gasSupply);
  const gasSupplyIsSmart = gasSupply?.supplyPointInfo?.isSmart;
  const gasMeterType: SupplyMeterType = !gasSupply
    ? 'NO_SUPPLY'
    : gasSupplyIsSmart
    ? 'SMART'
    : 'TRAD';
  const elecSupply = useSelector((state: State) => state.app.elecSupply);
  const elecSupplyIsSmart = elecSupply?.supplyPointInfo?.isSmart;
  const elecMeterType: SupplyMeterType = !elecSupply
    ? 'NO_SUPPLY'
    : elecSupplyIsSmart
    ? 'SMART'
    : 'TRAD';

  const optimizelyUserAttributes = {
    platform: 'web',
    accountId,
    customerId,
    env: process.env.NX_OPTIMIZELY_ENV_ATTRIBUTE,
    isDebtRisk,
    isDirectDebitCustomer: hasDirectDebit,
    gasMeterType,
    elecMeterType,
  };

  optimizely.setUser({
    id: customerId,
    attributes: optimizelyUserAttributes,
  });

  const { features, setFeatures } = useFeatureContext();

  if (!features && isFetched) {
    optimizely
      .onReady()
      .then(({ success }) => {
        // Do not remove. No OEP features will be rendered until this has been set.
        if (success) {
          const estimationsEnabled = optimizely.isFeatureEnabled(
            BILLING_HISTORY_CALLOUT,
          );
          const expandFirstBillingPeriodEnabled = optimizely.isFeatureEnabled(
            EXPAND_FIRST_BILLING_PERIOD,
          );
          const moveOutExperiment = optimizely.isFeatureEnabled(MOVE_OUT_CTA)
            ? 'variant'
            : 'control';

          const omrWithoutMsn = optimizely.isFeatureEnabled(OMR_WITHOUT_MSN);
          const meterSummary = optimizely.isFeatureEnabled(METER_SUMMARY);

          const billingComprehension =
            (optimizely.decide(BILLING_COMPREHENSION, undefined, undefined, {
              ...optimizely?.user?.attributes,
              isDirectDebitCustomer: hasDirectDebit,
            }).variationKey as 'control' | 'variant1' | 'variant2') ??
            undefined;

          setFeatures({
            moveOutCTA: moveOutExperiment,
            hideRecommendedDirectDebit: !optimizely.isFeatureEnabled(
              SHOW_RECOMMENDED_DIRECT_DEBIT,
            ),
            billingHistoryCallout: estimationsEnabled,
            expandFirstBillingPeriod: expandFirstBillingPeriodEnabled,
            omrWithoutMsn,
            meterSummary,
            billingComprehension,
          });
        } else {
          throw new Error('Optimizely init failed');
        }
      })
      .catch(() => {
        setFeatures({});
      });
  }

  return (
    // @ts-ignore react-18 children props
    <ErrorBoundary errorId={MAIN_PAGE_ERROR}>
      <OptimizelyFeature feature={MAINTENANCE_MODE}>
        {isEnabled => {
          if (isEnabled) {
            return <MaintenanceMode />;
          } else {
            return (
              <>
                <OverlayProvider>
                  <SmartBanner />
                  <Sandwich id={sandwichIds.core.main}>
                    <StyledApp key={accountId as string}>{children}</StyledApp>
                  </Sandwich>
                </OverlayProvider>
              </>
            );
          }
        }}
      </OptimizelyFeature>
    </ErrorBoundary>
  );
};
