import { Dd, Dt, P, TextLink } from '@ovotech/nebula';
import { AnalyticsClick, useAccountContext } from '@ovotech/ui-tools';
import React from 'react';
import styled from 'styled-components';
import { useInterestRewardQuery } from '@/src/api';
import { HELP_OVO_ENERGY_INTEREST_REWARD_CALCULATION } from '@/src/constants/links';

const StyledDt = styled.dt`
  display: inline-block;
  margin-top: 0;
`;

const StyledDd = styled.dd`
  display: inline-block;
`;

export const InterestRewardSummary = () => {
  const { accountId } = useAccountContext();
  const { data, isError, isLoading } = useInterestRewardQuery(accountId);

  const interestReward = data?.billingSummary?.interestReward;

  if (isError) return <P>Something has gone wrong.</P>;
  if (isLoading || !interestReward) return null;

  return (
    <>
      <StyledDt as={Dt}>
        <AnalyticsClick name={'OvoInterestReward'}>
          <TextLink
            href={HELP_OVO_ENERGY_INTEREST_REWARD_CALCULATION}
            data-testid="interest-reward-summary-link"
            opensInNewWindow
          >
            OVO Interest Reward
          </TextLink>
        </AnalyticsClick>
        :
      </StyledDt>{' '}
      <StyledDd as={Dd} data-testid="interest-reward-summary-amount">
        {interestReward?.currentPercentage}%
      </StyledDd>
    </>
  );
};
