import { Card } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { tenSecondData } from '@/fixtures/ten-second-data';
import { StyledNoMarginH2 } from '../CarbonComparison/CarbonComparison.styled';
import { TenSecondChart } from './TenSecondChart';
import {
  TenSecondSample,
  TenSecondSampleResponse,
  toTenSecondSample,
} from './Types';
import { liveDataWSUrl } from '@/src/constants/endpoints';
import { GridContainer } from '@/src/pages/EnergyInsights/components';

const StyledCard = styled(Card)`
  padding-left: 0;
`;

const ConnectedTenSecChart = () => {
  const defaultData =
    process.env.NODE_ENV === 'development' ? tenSecondData : [];
  const { accountId } = useAccountContext();
  const [liveDataArr, setLiveDataArr] = useState<Array<TenSecondSample> | []>(
    defaultData,
  );

  useEffect(() => {
    let liveDataSocket: WebSocket;

    if (accountId) {
      liveDataSocket = new WebSocket(liveDataWSUrl(accountId));
      liveDataSocket.onopen = () => {
        // Need to send message to server to get it to start sending
        liveDataSocket.send('hello from the client!');

        liveDataSocket.onmessage = (e: any) => {
          const jsonData: Array<TenSecondSampleResponse> = JSON.parse(e.data);
          const mappedJsonData = jsonData.map(toTenSecondSample);
          setLiveDataArr(mappedJsonData);
        };
      };
      liveDataSocket.onerror = (e: any) => {
        console.error(e);
      };
    }

    return () => {
      liveDataSocket && liveDataSocket.close();
    };
  }, [accountId]);

  if (liveDataArr.length === 0) {
    return null;
  }

  return (
    <GridContainer>
      <StyledNoMarginH2>Your 10-second electricity use</StyledNoMarginH2>
      <StyledCard>
        <TenSecondChart data={liveDataArr as any} />
      </StyledCard>
    </GridContainer>
  );
};

export default ConnectedTenSecChart;
