import { useQuery } from 'react-query';
import { ENDPOINT_BILLING_GRAPHQL } from '@/src/constants/endpoints';
import { InterestRewardGraphqlResponse } from '@/src/types/Response';
import { apiService } from '@/src/services/api-service';

type QueryCacheKey = `orion-interest-reward-graphql-${string}`;
type AccountId = string | null | undefined;

const QUERY = `
query InterestReward($id: String!) {
  billingSummary(id: $id) {
    interestReward {
      currentPercentage
    }
  }
}`;

export function useInterestRewardQuery(accountId: AccountId) {
  return useQuery(
    [`orion-interest-reward-graphql-${accountId}` as QueryCacheKey],
    () =>
      apiService.graphql<InterestRewardGraphqlResponse | null>({
        url: ENDPOINT_BILLING_GRAPHQL,
        body: {
          query: QUERY,
          operationName: 'InterestReward',
          variables: {
            id: accountId,
          },
        },
      }),
    {
      enabled: !!accountId,
    },
  );
}
