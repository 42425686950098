export const EV_ANYTIME_SMART_CHARGER_LINK =
  'https://www.ovoenergy.com/electric-cars/smart-charger';

export enum SmartChargers {
  Indra = 'Indra',
  Ohme = 'Ohme',
  Hypervolt = 'Hypervolt',
  Andersen = 'Andersen',
  NoneOfTheAbove = 'None of the above',
  IDontChargeMyEVAtHome = 'I don’t charge my EV at home',
}

export const smartChargerSelections = (exclusions: Array<SmartChargers>) =>
  [
    {
      value: SmartChargers.Indra,
      text: 'Indra: Smart PRO/Smart Charger V3',
    },
    {
      value: SmartChargers.Ohme,
      text: 'Ohme: Home/Home Pro/ePod',
    },
    {
      value: SmartChargers.Hypervolt,
      text: 'Hypervolt: Home 3.0/Home 3 Pro',
    },
    {
      value: SmartChargers.Andersen,
      text: 'Andersen: A2 (2024)/A3',
    },
    {
      value: SmartChargers.NoneOfTheAbove,
      text: 'None of the above',
    },
    {
      value: SmartChargers.IDontChargeMyEVAtHome,
      text: 'I don’t charge my EV at home',
    },
  ].filter((i) => !exclusions.includes(i.value));
