import { Margin, Card, Heading3 } from '@ovotech/nebula';
import React from 'react';
import Media from 'react-media';
import { useSelector } from 'react-redux';

import { useGasChartData } from './useGasChartData';
import { useMonthGasUsageSummaries } from './useMonthGasUsageSummaries';
import { ErrorSection, PageWithNavigation, Section } from '@/src/components';
import AnalyticsWrapper from '@/src/components/AnalyticsWrapper/AnalyticsWrapper';
import { SkeletonCard } from '@/src/components/SkeletonLoading/SkeletonCard';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { SMART_THERMOSTATS_STATUS_ERROR } from '@/src/constants/error-section-ids';
import { State } from '@/src/types/State';
import { breakpoints } from '@/src/utils/mediaQuery';
import { getDateFromParams } from './utils';
import { WhatHappensNextCard } from '../../components/WhatHappensNextCard';
import { ConnectCard } from '../../components/ConnectCard';
import { ThermostatConnectedNotification } from '../../components/ThermostatConnectedNotification';
import { ThermostatInfoCard } from '../../components/ThermostatInfoCard';
import { GetASmartMeter } from '../../components/GetASmartMeter/GetASmartMeter';
import MonthlyNavigator from '../../components/MonthlyNavigator/MonthlyNavigator';
import { MonthlySummaries } from '../../components/MonthlySummaries';
import { GasUsageChart } from '../../components/GasUsageChart';
import { FeedbackPrompt } from '../../components/FeedbackPrompt';
import { getTadoStatus } from '../../hooks/getTadoStatus';
import { getTadoInfo } from '../../hooks/getTadoInfo';
import { useInstallType } from '../../hooks/useInstallType';

export const MyThermostatsPage = () => {
  const { tadoStatus, errorResponse } = getTadoStatus();
  const { tadoInfo } = getTadoInfo();
  const monthYearSelected = getDateFromParams();
  const installType = useInstallType();
  const {
    previousMonthGas,
    currentNavigatedToGas,
    isNavigatedToLatestMonth,
    error: gasMonthSummaryError,
  } = useMonthGasUsageSummaries(monthYearSelected);
  const smartMeter = useSelector(
    (state: State) => state.app.gasSupply?.supplyPointInfo?.isSmart,
  );

  const gasChartData = useGasChartData(monthYearSelected);

  if (errorResponse || gasMonthSummaryError || gasChartData.error) {
    return (
      <PageWithNavigation title="My Thermostat">
        <Margin top={8}>
          <Card>
            <ErrorSection
              headingComponent={Heading3}
              id={SMART_THERMOSTATS_STATUS_ERROR}
            />
          </Card>
        </Margin>
      </PageWithNavigation>
    );
  }

  if (!smartMeter) {
    return <GetASmartMeter />;
  }

  if (!tadoStatus)
    return (
      <PageWithNavigation title="Loading - My Thermostat">
        <Section data-testid="loading">
          <SkeletonCard />
        </Section>
      </PageWithNavigation>
    );

  if (!tadoStatus.connected) {
    return (
      <AnalyticsWrapper
        name={SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.NOT_CONNECTED.VIEWED}
      >
        <PageWithNavigation title="My Thermostat">
          <Margin top={8}>
            <Margin bottom={8}>
              <WhatHappensNextCard installType={installType} />
            </Margin>
            <ConnectCard authLink={tadoStatus.link} />
          </Margin>
        </PageWithNavigation>
      </AnalyticsWrapper>
    );
  }

  return (
    <AnalyticsWrapper
      name={SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.CONNECTED.VIEWED}
    >
      <PageWithNavigation title="My Thermostat">
        <Margin top={8}>
          <ThermostatConnectedNotification />
          {!!tadoInfo?.setPoint && (
            <ThermostatInfoCard
              temperature={tadoInfo.setPoint}
              name={tadoInfo.name}
            />
          )}
          <Media query={`${breakpoints.mediumUp}`}>
            <Margin bottom={1}>
              <MonthlyNavigator dateNavigatedTo={monthYearSelected} />
              <MonthlySummaries
                monthOnLeft={previousMonthGas}
                monthOnRight={currentNavigatedToGas}
                isNavigatedToLatestMonth={isNavigatedToLatestMonth}
              />
              <GasUsageChart
                gasDailyUsageData={gasChartData.gasDailyUsageData}
                monthYear={monthYearSelected}
              />
            </Margin>
          </Media>
          {!tadoStatus.isInitialCollectionPeriod && <FeedbackPrompt />}
        </Margin>
      </PageWithNavigation>
    </AnalyticsWrapper>
  );
};
