import { homeProfileModalTestIDs } from './components/HomeProfileModal/testIDs';
import { homeProfilePromptTestIDs } from './components/HomeProfilePrompt/testIDs';
import { hubTestIDs } from './pages/Hub/testIDs';

export const energyInsightsTestIDs = {
  hub: hubTestIDs,
  homeProfilePrompt: homeProfilePromptTestIDs,
  homeProfileModal: homeProfileModalTestIDs,
  common: {
    termsLink: 'energy-insights-terms-link',
  },
};
