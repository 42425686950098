import { UsageView } from '@ovotech/energy-cx';
import { Stack } from '@ovotech/nebula';
import React from 'react';
import { HomeProfileWidget } from './components/HomeProfileWidget';
import testIDs from './components/HomeProfileWidget/testIDs';
import { useHomeProfileWidget } from './components/HomeProfileWidget/useHomeProfileWidget';
import { PageWithNavigationAndSectionPadding } from '@/src/components';

export const UsagePageView = () => {
  const {
    data: homeProfileWidgetData,
    widgetPosition: homeProfileWidgetPosition,
    onClose,
  } = useHomeProfileWidget();

  return (
    <PageWithNavigationAndSectionPadding title="Your energy use">
      <Stack spaceBetween={4}>
        {homeProfileWidgetData && homeProfileWidgetPosition === 'top' && (
          <div data-testid={testIDs.widgetPositionedAtTheTop}>
            <HomeProfileWidget {...homeProfileWidgetData} onClose={onClose} />
          </div>
        )}

        <UsageView />

        {homeProfileWidgetData && homeProfileWidgetPosition === 'bottom' && (
          <div data-testid={testIDs.widgetPositionedAtTheBottom}>
            <HomeProfileWidget {...homeProfileWidgetData} onClose={onClose} />
          </div>
        )}
      </Stack>
    </PageWithNavigationAndSectionPadding>
  );
};
