import { Col, Row, VisuallyHidden } from '@ovotech/nebula';
import React, { Fragment } from 'react';
import Link from '../Link/Link';
import {
  StyledCrossIcon,
  StyledHamburgerIcon,
  StyledHeaderContents,
  StyledLogoAndMenuButton,
  StyledMenuToggleButton,
  StyledMobileNav,
  StyledNavHeader,
} from './Header.styled';
import { HeaderWrapper } from './HeaderWrapper';

import { StyledSkipLink } from '@/src/components/Navigation/Links/SkipLink.styled';

import {
  HeaderLinks,
  MobileDiminishedHeaderLinks,
  MobileHeaderLinks,
} from '@/src/components/Navigation/Navigation/HeaderLinks';

import { ROUTE_HOME } from '@/src/constants/routes';
import { StyledDarkLogo, StyledLightLogo } from '@/src/core/theme';
import { AccountSupplyPointFragmentFragment } from '@/src/api/kapi/__generated__/graphql';

type Props = {
  path: string;
  smartEligible: boolean;
  isMobileMenuOpen: boolean;
  hasMultipleAccounts: boolean;
  hasSmartMeterBooking?: boolean;
  supplies: readonly AccountSupplyPointFragmentFragment[];
  closeMobileMenu: () => void;
  toggleMobileMenu: () => void;
};

export const Header = ({
  path,
  supplies,
  smartEligible,
  isMobileMenuOpen,
  hasMultipleAccounts,
  hasSmartMeterBooking,
  closeMobileMenu,
  toggleMobileMenu,
}: Props) => {
  return (
    <HeaderWrapper>
      <StyledNavHeader isMobileMenuOpen={isMobileMenuOpen}>
        <Row>
          <Col>
            <StyledSkipLink to="#main">Skip to main content</StyledSkipLink>
            <StyledHeaderContents>
              <StyledLogoAndMenuButton>
                <Link aria-hidden="true" tabIndex={-1} to={ROUTE_HOME}>
                  {isMobileMenuOpen ? <StyledDarkLogo /> : <StyledLightLogo />}
                </Link>
                <VisuallyHidden>Orion</VisuallyHidden>
                <StyledMenuToggleButton
                  isMobileMenuOpen={isMobileMenuOpen}
                  aria-expanded={isMobileMenuOpen}
                  onClick={toggleMobileMenu}
                  aria-label={isMobileMenuOpen ? 'close menu' : 'menu'}
                >
                  {isMobileMenuOpen ? (
                    <Fragment>
                      Close <StyledCrossIcon focusable="false" />
                    </Fragment>
                  ) : (
                    <Fragment>
                      Menu <StyledHamburgerIcon focusable="false" />
                    </Fragment>
                  )}
                </StyledMenuToggleButton>
              </StyledLogoAndMenuButton>
              <HeaderLinks
                path={path}
                isMobileMenuOpen={isMobileMenuOpen}
                hasMultipleAccounts={hasMultipleAccounts}
                closeMobileMenu={closeMobileMenu}
              />
            </StyledHeaderContents>
          </Col>
        </Row>
        <StyledMobileNav isMobileMenuOpen={isMobileMenuOpen}>
          <MobileHeaderLinks
            path={path}
            supplies={supplies}
            smartEligible={smartEligible}
            hasMultipleAccounts={hasMultipleAccounts}
            hasSmartMeterBooking={hasSmartMeterBooking}
            closeMobileMenu={closeMobileMenu}
          />
          <MobileDiminishedHeaderLinks
            path={path}
            hasMultipleAccounts={hasMultipleAccounts}
            closeMobileMenu={closeMobileMenu}
          />
        </StyledMobileNav>
      </StyledNavHeader>
    </HeaderWrapper>
  );
};
