import { Margin, P, SecondaryCTAButton, Strong } from '@ovotech/nebula';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import Media from 'react-media';
import { StyledFeedback } from './Feedback.styled';
import { breakpoints } from '@/src/utils/mediaQuery';

export const Feedback = ({
  children,
  surveyId,
}: {
  children: React.ReactNode;
  surveyId: string;
}) => {
  const cookieName = `CLICKED_${surveyId}`;
  const cookie = Cookies.get(cookieName);
  const [feedbackClicked, setFeedbackClicked] = useState<boolean>(
    Boolean(cookie),
  );

  const onFeedbackClick = () => {
    const oneHour = new Date(new Date().getTime() + 60 * 60 * 1000);
    Cookies.set(cookieName, 'true', {
      expires: oneHour,
    });
    setFeedbackClicked(true);
  };

  if (feedbackClicked) {
    return null;
  }

  return (
    <Media query={`${breakpoints.mediumUp}`}>
      {matches =>
        matches ? (
          <StyledFeedback>
            <Margin top={1}>
              <P>
                <Strong>{children}</Strong>
              </P>
            </Margin>
            <SecondaryCTAButton
              data-testid={`trigger-survey-${surveyId}`}
              onClick={onFeedbackClick}
              iconRight="chevron-right"
            >
              Yes, please
            </SecondaryCTAButton>
          </StyledFeedback>
        ) : null
      }
    </Media>
  );
};
