import { isVehicleEligible, Make, Model } from './anytime-vehicles';
import { SmartChargers } from './anytime-chargers';

export type IneligibleReasons = {
  hasSolarPanels: boolean;
  incompatibleCar: boolean;
  hasNoInternetAccess: boolean;
  doesntChargeEvAtHome: boolean;
  notSmartMeter: boolean;
  invalidMeterReadFrequency: boolean;
  onlyGasSupply: boolean;
  invalidOther: boolean;
  numberOfReasons: number;
};

export type useEligibilityCheckArgs = {
  make: Make;
  model: Model;
  year: number;
  internetAccess: boolean;
  solarPanels: boolean;
  ineligibleNotSmartMeter: boolean;
  ineligibleInvalidMeterReadFrequency: boolean;
  ineligibleOnlyGas: boolean;
  ineligibleOther: boolean;
  smartCharger: SmartChargers;
  hasSmartMeter: boolean;
  payAsYouDriveActive: boolean;
  payAsYouDriveVolvoEnabled: boolean;
};

export const useEligibilityCheck = ({
  make,
  model,
  ineligibleOnlyGas,
  ineligibleOther,
  ineligibleNotSmartMeter,
  ineligibleInvalidMeterReadFrequency,
  year,
  solarPanels,
  internetAccess,
  smartCharger,
  hasSmartMeter,
  payAsYouDriveActive,
  payAsYouDriveVolvoEnabled,
}: Partial<useEligibilityCheckArgs>): IneligibleReasons => {
  const isOhme = smartCharger === SmartChargers.Ohme;
  const invalidOther = payAsYouDriveActive && ineligibleOther;
  const eligibleCharger =
    (smartCharger &&
      [
        SmartChargers.Indra,
        SmartChargers.Hypervolt,
        SmartChargers.Andersen,
      ].includes(smartCharger)) ||
    (isOhme && !solarPanels);

  const ineligibleReasons: IneligibleReasons = invalidOther
    ? {
        doesntChargeEvAtHome: false,
        incompatibleCar: false,
        hasSolarPanels: false,
        hasNoInternetAccess: false,
        notSmartMeter: false,
        invalidMeterReadFrequency: false,
        onlyGasSupply: false,
        invalidOther: true,
        numberOfReasons: 0,
      }
    : {
        doesntChargeEvAtHome:
          smartCharger === SmartChargers.IDontChargeMyEVAtHome,
        incompatibleCar:
          !eligibleCharger &&
          !isVehicleEligible(
            make,
            model,
            Number(year),
            payAsYouDriveVolvoEnabled
          ) &&
          !isOhme,
        hasSolarPanels: !eligibleCharger && !!solarPanels,
        hasNoInternetAccess: !eligibleCharger && !internetAccess && !isOhme,
        notSmartMeter: payAsYouDriveActive
          ? !!ineligibleNotSmartMeter
          : !hasSmartMeter,
        invalidMeterReadFrequency:
          !!payAsYouDriveActive && !!ineligibleInvalidMeterReadFrequency,
        onlyGasSupply: !!payAsYouDriveActive && !!ineligibleOnlyGas,
        invalidOther: false,
        numberOfReasons: 0,
      };

  ineligibleReasons.numberOfReasons = Object.values(
    ineligibleReasons
  ).reduce<number>((num, isValid) => {
    return num + (isValid ? 1 : 0);
  }, 0);

  return ineligibleReasons;
};
