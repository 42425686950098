import {
  P,
  Heading4,
  TextLink,
  Margin,
  Card,
  ErrorNotification,
  SecondaryCTAButton,
} from '@ovotech/nebula';
import { AnalyticsClick, AnalyticsView } from '@ovotech/ui-tools';
import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom-v5';
import { routes } from '../../OvoDriveAnytime';
import { AnytimeNotEligibleSection } from '../../components/AnytimeNotEligible/AnytimeNotEligibleSection';
import { EVAnytimeHeader } from '../../components/EVAnytime';
import { useKaluzaSmartCharger } from '../../hooks/useKaluzaSmartCharger';
import {
  LoadingStyledCard,
  StyledLogoWrapper,
} from './EVAnytimeConsent.styled';
import {
  StyledFeaturesImageWrapper,
  StyledFeaturesImage,
} from './EVAnytimeHomepage.styled';
import {
  StyledSuccessCard,
  StyledSuccessTextWrapper,
  StyledHeadstartCard,
  StyledHeadstartTextWrapper,
  StyledEVSpinLink,
  LoadingAnimationWrapper,
  StyledP,
  StyledHeadstartImage,
} from './EVAnytimeSignupConfirmation.styled';
import { PageWithNavigation } from '@/src/components';
import { EV } from '@/src/constants/analytics';
import {
  CHARGE_ANYTIME_EMAIL_ADDRESS,
  ROUTE_EV,
  ROUTE_EV_ANYTIME,
} from '@/src/constants/routes';
import { PRODUCT_IDS } from '@/src/constants/products';
import { SmartChargers, State } from '@/src/types/State';
import AnytimeSuccessMobile from '@/src/resources/anytime-success-mobile.png';
import AnytimeSuccess from '@/src/resources/anytime-success.png';
import { ReactComponent as ChargingIcon } from '@/src/resources/charger.svg';
import TempImage from '@/src/resources/headstart-image.png';
import { ReactComponent as OVOIcon } from '@/src/resources/ovo-icon2.svg';
import { activateAnytimeProductStart } from '@/src/redux/ducks/evs/evAnytime';
import { useChargeAnytimeBoltonState } from '@/src/pages/EVs/hooks/useChargeAnytimeBoltonState';
import { hasSmartMeter as hasSmartMeterSelector } from '@/src/pages/Renewal/selectors';

const circles: Array<any> = [];
for (let i = 0; i < 12; i++) {
  circles.push(<div className={'circle' + (i + 1) + ' circle'}></div>);
}

const LoadingCard = () => {
  return (
    <>
      <EVAnytimeHeader title={'Add Charge Anytime'} />
      <AnalyticsView name={EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_LOADING_VIEWED}>
        <AnalyticsView name="EVS - EVS OVO Drive Anytime loading page viewed">
          <LoadingStyledCard>
            <StyledLogoWrapper>
              <OVOIcon />
              <LoadingAnimationWrapper>{circles}</LoadingAnimationWrapper>
              <ChargingIcon />
            </StyledLogoWrapper>
            <StyledP>
              Hang on a speedy second whilst we get the info we need from your
              charger{' '}
            </StyledP>
          </LoadingStyledCard>
        </AnalyticsView>
      </AnalyticsView>
    </>
  );
};

const SuccessCard = () => {
  const { smartChargerSelection } = useSelector(
    ({ evAnytime }: State) => evAnytime,
  );
  return (
    <>
      <EVAnytimeHeader title={'Congratulations!'} showDescription={false} />
      <AnalyticsView
        name={EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_SUCCESS_VIEWED}
        properties={{ smartChargerSelection }}
      >
        <AnalyticsView
          name="EVS - EVS OVO Drive Anytime success viewed"
          properties={{ smartChargerSelection }}
        >
          <StyledSuccessCard data-testId="anytime-success-page">
            <StyledSuccessTextWrapper>
              <P>
                You're all set up. We'll smart charge your car using the
                schedule that you set in your charger manufacturer app. If you
                want to change the time your car is ready, you do this using
                that app.
              </P>
              <P>
                To double-check that Charge Anytime is set up correctly, open
                your charger app and check the tariff page to make sure you're
                being charged the correct amount.
              </P>
              <P>
                To see your charging info and how much you're saving, check{' '}
                <TextLink as={Link} to={ROUTE_EV} opensInNewWindow>
                  Your EV hub
                </TextLink>
                . You'll receive the Charge Anytime credits for any time you've
                used smart charging on your next bill.
              </P>
            </StyledSuccessTextWrapper>
            <StyledFeaturesImageWrapper>
              <StyledFeaturesImage
                image={AnytimeSuccess}
                imageMobile={AnytimeSuccessMobile}
              />
            </StyledFeaturesImageWrapper>
          </StyledSuccessCard>
        </AnalyticsView>
      </AnalyticsView>

      <StyledHeadstartCard data-test-id="headstart-card">
        <StyledHeadstartImage src={TempImage} alt=""></StyledHeadstartImage>
        <StyledHeadstartTextWrapper>
          <Heading4>Get a head start</Heading4>
          <P>
            Race on over to the Your EV hub and check out your charging costs,
            Charge Anytime charging credit, personalised insights, and exclusive
            offers, as well as all the latest EV news.
          </P>
          <div>
            <AnalyticsClick
              name={
                EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_SUCCESS_SPIN_BUTTON_CLICKED
              }
            >
              <StyledEVSpinLink
                data-event-name={
                  'EVS - EVS OVO Drive Anytime success spin button clicked'
                }
                href={`${ROUTE_EV}`}
                fullWidth="never"
                data-testId="go-to-your-ev"
              >
                Take Your EV for a spin
              </StyledEVSpinLink>
            </AnalyticsClick>
          </div>
        </StyledHeadstartTextWrapper>
      </StyledHeadstartCard>
    </>
  );
};

type ErrorCardProps = {
  type: string;
  title: string;
  description: string;
};

const ErrorCard = (props: ErrorCardProps) => {
  const { type, title, description } = props;
  const errorName =
    type === 'bolton'
      ? EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_BOLTON_ERROR_VIEWED
      : EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_KALUZA_ERROR_VIEWED;
  const oldErrorName =
    type === 'bolton'
      ? 'EVS - EVS OVO Drive Anytime bolton error viewed'
      : 'EVS - EVS OVO Drive Anytime Kaluza error viewed';
  const { smartChargerSelection } = useSelector(
    ({ evAnytime }: State) => evAnytime,
  );

  const ohmeFlow = smartChargerSelection === 'Ohme';

  return (
    <>
      <Card>
        <AnalyticsView name={errorName} properties={{ smartChargerSelection }}>
          <AnalyticsView
            name={oldErrorName}
            properties={{ smartChargerSelection }}
          >
            <Margin bottom={6}>
              <ErrorNotification
                id="AnytimeCancelError"
                title={title}
                data-testId="anytime-error-page"
              >
                <P>{description}</P>
              </ErrorNotification>
            </Margin>
          </AnalyticsView>
        </AnalyticsView>
        {type === 'bolton' && (
          <AnalyticsClick
            name={
              EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_BOLTON_ERROR_TRY_AGAIN_CLICKED
            }
          >
            <SecondaryCTAButton
              data-event-name={
                'EVS - EVS OVO Drive Anytime Kaluza try again button clicked'
              }
              as={Link}
              to={`${ROUTE_EV_ANYTIME}/${routes.consent}`}
            >
              Try again
            </SecondaryCTAButton>
          </AnalyticsClick>
        )}
        {type === 'kaluza' && (
          <AnalyticsClick
            name={EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_KALUZA_ERROR_BACK_CLICKED}
          >
            <SecondaryCTAButton
              data-event-name={
                'EVS - EVS OVO Drive Anytime Kaluza back button clicked'
              }
              as={Link}
              to={
                ohmeFlow
                  ? (process.env.NX_EV_ANYTIME_OHME_IDENTITY_URL as string)
                  : (process.env.NX_EV_ANYTIME_KALUZA_IDENTITY_URL as string)
              }
            >
              Try again
            </SecondaryCTAButton>
          </AnalyticsClick>
        )}
      </Card>
    </>
  );
};

export const EVAnytimeSignupConfirmation = memo(() => {
  const kaluzaErrorTitle = 'Sorry, there’s a bit of a problem';
  const kaluzaErrorDescription = `We couldn’t connect to your EV charger. Please try again, or email us at ${CHARGE_ANYTIME_EMAIL_ADDRESS} and we’ll be happy to help.`;
  const boltonErrorTitle = 'Sorry, there’s a bit of a problem';
  const boltonErrorDescription = `Please try again. If this doesn’t work, please get in touch with us at ${CHARGE_ANYTIME_EMAIL_ADDRESS}`;
  const { connection, error: kaluzaError } = useKaluzaSmartCharger();
  const { activate } = useSelector(({ evAnytime }: State) => evAnytime);
  const { hasChargeAnytime, isFetching } = useChargeAnytimeBoltonState();
  const hasSmartMeter = useSelector(hasSmartMeterSelector);
  const { smartChargerSelection, isFetching: isFetchingSmartCharger } =
    useSelector(({ evAnytime }: State) => evAnytime);
  const hasSmartCharger = [SmartChargers.Indra, SmartChargers.Ohme].includes(
    smartChargerSelection,
  );

  const isEligible =
    hasSmartMeter &&
    !hasChargeAnytime &&
    hasSmartCharger &&
    connection &&
    !kaluzaError;

  const dispatch = useDispatch();
  useEffect(() => {
    if (isEligible) {
      dispatch(activateAnytimeProductStart());
    }
  }, [dispatch, isEligible]);

  if (isFetching || isFetchingSmartCharger) {
    return (
      <PageWithNavigation title="Charge Anytime">
        <LoadingCard />
      </PageWithNavigation>
    );
  }

  if (
    (hasChargeAnytime && connection && !kaluzaError) ||
    (activate?.[PRODUCT_IDS.anytime]?.success && connection && !kaluzaError)
  ) {
    return (
      <PageWithNavigation title="Charge Anytime">
        <SuccessCard />
      </PageWithNavigation>
    );
  }

  if (activate?.[PRODUCT_IDS.anytime]?.error) {
    return (
      <PageWithNavigation title="Charge Anytime">
        <ErrorCard
          type="bolton"
          title={boltonErrorTitle}
          description={boltonErrorDescription}
        />
      </PageWithNavigation>
    );
  }

  if (kaluzaError) {
    return (
      <PageWithNavigation title="Charge Anytime">
        <ErrorCard
          type="kaluza"
          title={kaluzaErrorTitle}
          description={kaluzaErrorDescription}
        />
      </PageWithNavigation>
    );
  }
  if (!isFetching && !connection && !isEligible && hasChargeAnytime === null) {
    return (
      <PageWithNavigation title="Charge Anytime">
        <AnytimeNotEligibleSection />
      </PageWithNavigation>
    );
  }

  return null;
});
