/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query Bootstrap($customerId: ID!) {\n    customer_nextV1(id: $customerId) {\n      id\n      customerAccountRelationships {\n        edges {\n          node {\n            account {\n              accountNo\n              id\n              accountSupplyPoints {\n                ...AccountSupplyPoint\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n\n  fragment AccountSupplyPoint on AccountSupplyPoint {\n    startDate\n    supplyPoint {\n      sprn\n      fuelType\n      meterTechnicalDetails {\n        meterSerialNumber\n        mode\n        type\n      }\n      address {\n        addressLines\n        postCode\n      }\n    }\n  }\n": types.BootstrapDocument,
    "\n  mutation UpdateSupplyPointConsent($input: UpdateSupplyPointConsentInput!) {\n    updateSupplyPointConsent(input: $input) {\n      sprn\n    }\n  }\n": types.UpdateSupplyPointConsentDocument,
    "\n  query AccountMHHSConsent($accountId: ID!) {\n    account(id: $accountId) {\n      id\n      accountSupplyPoints {\n        supplyPoint {\n          sprn\n          fuelType\n          meterTechnicalDetails {\n            type\n            status\n          }\n        }\n        consent {\n          sprn\n          billingConsent {\n            consentLevel\n            consentType\n            from\n            to\n          }\n          settlementConsent {\n            consentLevel\n            consentType\n            from\n            to\n          }\n        }\n      }\n    }\n  }\n": types.AccountMhhsConsentDocument,
    "\n    mutation updateCustomer(\n      $customerId: String!\n      $realm: String!\n      $operations: [JSONPatchOperation!]!\n    ) {\n      updateCustomer(operations: $operations, realm: $realm, id: $customerId)\n    }\n  ": types.UpdateCustomerDocument,
    "\n  query MarketingPreferences($customerId: ID!) {\n    customer(id: $customerId) {\n      marketingPreferences\n    }\n  }\n": types.MarketingPreferencesDocument,
    "\n  query ProfileNextV1($customerId: ID!) {\n    customer_nextV1(id: $customerId) {\n      id\n      givenName\n      familyName\n      title\n      contactEmail\n      contactPhoneNumbers\n      contactAddress {\n        addressee\n        postalAddress {\n          line1\n          line2\n          town\n          region\n          postalCode\n          country\n        }\n      }\n    }\n  }\n": types.ProfileNextV1Document,
    "\n  query SupplyPointByAccount($accountId: ID!) {\n    account(id: $accountId) {\n      accountSupplyPoints {\n        ...AccountSupplyPointFragment\n      }\n    }\n  }\n\n  fragment AccountSupplyPointFragment on AccountSupplyPoint {\n    startDate\n    end {\n      date\n    }\n    supplyPoint {\n      status {\n        current {\n          status\n        }\n      }\n      fuelType\n      meterTechnicalDetails {\n        meterSerialNumber\n        mode\n        type\n      }\n      address {\n        addressLines\n        postCode\n      }\n    }\n  }\n": types.SupplyPointByAccountDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Bootstrap($customerId: ID!) {\n    customer_nextV1(id: $customerId) {\n      id\n      customerAccountRelationships {\n        edges {\n          node {\n            account {\n              accountNo\n              id\n              accountSupplyPoints {\n                ...AccountSupplyPoint\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n\n  fragment AccountSupplyPoint on AccountSupplyPoint {\n    startDate\n    supplyPoint {\n      sprn\n      fuelType\n      meterTechnicalDetails {\n        meterSerialNumber\n        mode\n        type\n      }\n      address {\n        addressLines\n        postCode\n      }\n    }\n  }\n"): (typeof documents)["\n  query Bootstrap($customerId: ID!) {\n    customer_nextV1(id: $customerId) {\n      id\n      customerAccountRelationships {\n        edges {\n          node {\n            account {\n              accountNo\n              id\n              accountSupplyPoints {\n                ...AccountSupplyPoint\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n\n  fragment AccountSupplyPoint on AccountSupplyPoint {\n    startDate\n    supplyPoint {\n      sprn\n      fuelType\n      meterTechnicalDetails {\n        meterSerialNumber\n        mode\n        type\n      }\n      address {\n        addressLines\n        postCode\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateSupplyPointConsent($input: UpdateSupplyPointConsentInput!) {\n    updateSupplyPointConsent(input: $input) {\n      sprn\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSupplyPointConsent($input: UpdateSupplyPointConsentInput!) {\n    updateSupplyPointConsent(input: $input) {\n      sprn\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AccountMHHSConsent($accountId: ID!) {\n    account(id: $accountId) {\n      id\n      accountSupplyPoints {\n        supplyPoint {\n          sprn\n          fuelType\n          meterTechnicalDetails {\n            type\n            status\n          }\n        }\n        consent {\n          sprn\n          billingConsent {\n            consentLevel\n            consentType\n            from\n            to\n          }\n          settlementConsent {\n            consentLevel\n            consentType\n            from\n            to\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AccountMHHSConsent($accountId: ID!) {\n    account(id: $accountId) {\n      id\n      accountSupplyPoints {\n        supplyPoint {\n          sprn\n          fuelType\n          meterTechnicalDetails {\n            type\n            status\n          }\n        }\n        consent {\n          sprn\n          billingConsent {\n            consentLevel\n            consentType\n            from\n            to\n          }\n          settlementConsent {\n            consentLevel\n            consentType\n            from\n            to\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation updateCustomer(\n      $customerId: String!\n      $realm: String!\n      $operations: [JSONPatchOperation!]!\n    ) {\n      updateCustomer(operations: $operations, realm: $realm, id: $customerId)\n    }\n  "): (typeof documents)["\n    mutation updateCustomer(\n      $customerId: String!\n      $realm: String!\n      $operations: [JSONPatchOperation!]!\n    ) {\n      updateCustomer(operations: $operations, realm: $realm, id: $customerId)\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MarketingPreferences($customerId: ID!) {\n    customer(id: $customerId) {\n      marketingPreferences\n    }\n  }\n"): (typeof documents)["\n  query MarketingPreferences($customerId: ID!) {\n    customer(id: $customerId) {\n      marketingPreferences\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ProfileNextV1($customerId: ID!) {\n    customer_nextV1(id: $customerId) {\n      id\n      givenName\n      familyName\n      title\n      contactEmail\n      contactPhoneNumbers\n      contactAddress {\n        addressee\n        postalAddress {\n          line1\n          line2\n          town\n          region\n          postalCode\n          country\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ProfileNextV1($customerId: ID!) {\n    customer_nextV1(id: $customerId) {\n      id\n      givenName\n      familyName\n      title\n      contactEmail\n      contactPhoneNumbers\n      contactAddress {\n        addressee\n        postalAddress {\n          line1\n          line2\n          town\n          region\n          postalCode\n          country\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SupplyPointByAccount($accountId: ID!) {\n    account(id: $accountId) {\n      accountSupplyPoints {\n        ...AccountSupplyPointFragment\n      }\n    }\n  }\n\n  fragment AccountSupplyPointFragment on AccountSupplyPoint {\n    startDate\n    end {\n      date\n    }\n    supplyPoint {\n      status {\n        current {\n          status\n        }\n      }\n      fuelType\n      meterTechnicalDetails {\n        meterSerialNumber\n        mode\n        type\n      }\n      address {\n        addressLines\n        postCode\n      }\n    }\n  }\n"): (typeof documents)["\n  query SupplyPointByAccount($accountId: ID!) {\n    account(id: $accountId) {\n      accountSupplyPoints {\n        ...AccountSupplyPointFragment\n      }\n    }\n  }\n\n  fragment AccountSupplyPointFragment on AccountSupplyPoint {\n    startDate\n    end {\n      date\n    }\n    supplyPoint {\n      status {\n        current {\n          status\n        }\n      }\n      fuelType\n      meterTechnicalDetails {\n        meterSerialNumber\n        mode\n        type\n      }\n      address {\n        addressLines\n        postCode\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;