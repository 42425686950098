import React, { useEffect, useRef } from 'react';
import {
  Route,
  useLocation,
  Redirect,
  useRouteMatch,
} from 'react-router-dom-v5';

import { EVAnytimeConsentPage } from './pages/Anytime/EVAnytimeConsentPage';
import { EVAnytimeDisconnect } from './pages/Anytime/EVAnytimeDisconnect';
import { EVAnytimeHomepage } from './pages/Anytime/EVAnytimeHomepage';
import { EVAnytimeSignupConfirmation } from './pages/Anytime/EVAnytimeSignupConfirmation';
import { EVsInlifePageAnytime } from './pages/Anytime/EVsInlifePageAnytime';
import { defaultDateRedirectPath } from './utils';
import { SwitchWithNotFound } from '@/src/components';
import { DRIVE_ANYTIME } from '@/src/constants/feature-flags';
import { OptimizelyFeature } from '@/src/utils/optimizely';

export const routes = {
  eligibility: '/eligibility',
  disconnect: '/disconnect',
  homepage: '/homepage',
  confirmation: '/confirmation',
  consent: '/consent',
};

const OvoDriveAnytime = () => {
  const { path } = useRouteMatch();
  const { pathname, search } = useLocation();

  const positions = useRef(Object.create(null));
  useEffect(() => {
    if (pathname in positions.current) {
      const { x, y } = positions.current[pathname];
      window.scrollTo(x, y);
    } else {
      window.scrollTo(0, 0);
    }

    const handler = () => {
      positions.current[pathname] = { x: window.scrollX, y: window.scrollY };
    };
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [pathname]);

  return (
    <OptimizelyFeature feature={DRIVE_ANYTIME}>
      {isEnabled => {
        return (
          isEnabled && (
            <SwitchWithNotFound>
              <Route
                exact
                path={`${path}/:year(\\d{4})/:month(\\d{2})`}
                component={EVsInlifePageAnytime}
              />
              <Route
                exact
                path={`${path}${routes.disconnect}`}
                component={EVAnytimeDisconnect}
              />
              <Route
                exact
                path={`${path}${routes.homepage}`}
                component={EVAnytimeHomepage}
              />
              <Route
                exact
                path={`${path}${routes.confirmation}`}
                component={EVAnytimeSignupConfirmation}
              />
              <Route
                exact
                path={`${path}${routes.consent}`}
                component={EVAnytimeConsentPage}
              />
              <Redirect
                to={{
                  pathname: defaultDateRedirectPath(path, new Date()),
                  search,
                }}
              />
            </SwitchWithNotFound>
          )
        );
      }}
    </OptimizelyFeature>
  );
};

export default OvoDriveAnytime;
