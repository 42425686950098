import { Heading2, Margin, P } from '@ovotech/nebula';
import React from 'react';
import {
  ABS_HALF_PRICE_TITLE,
  ABS_HALF_PRICE_LIST,
  ABS_HALF_PRICE_GET_PLAN,
} from '../../constants';
import { FootnoteReferenced } from '@/src/components';
import { footnoteABSQ2Promo } from '../FooterTermsAndConditions';
import Offer from './Offer/Offer';
import { NEW_OFFERS_PAGE } from '@/src/constants/analytics';
import AnnualBoilerServiceImage from '@/src/resources/annual-boiler-service.png';
import { UnorderedListSection } from '@/src/pages/Offers/components/UnorderedListSection';

export const AbsHalfPriceOffer = () => {
  return (
    <Offer
      offerId={NEW_OFFERS_PAGE.BOILER_SERVICE_ID}
      image={AnnualBoilerServiceImage}
      linkUrl={`${process.env.NX_KANTAN_ABS_SERVICE_PLAN_JOURNEY_URL}/?utm_medium=banner&utm_source=offers-and-upgrades&utm_campaign=abs_q2_24_offer`}
      linkText={ABS_HALF_PRICE_GET_PLAN}
    >
      <Heading2>{ABS_HALF_PRICE_TITLE}</Heading2>
      <P>
        For a limited time, you can get our annual boiler service plan half
        price – that’s £3.50 a month
        <FootnoteReferenced content={footnoteABSQ2Promo} />
      </P>
      <Margin bottom={2}>
        <UnorderedListSection title={''} list={ABS_HALF_PRICE_LIST} />
      </Margin>
    </Offer>
  );
};
