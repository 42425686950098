import {
  P,
  Card,
  Radio,
  Margin,
  RadioField,
  SelectField,
  PrimaryCTAButton,
  SecondaryCTAButton,
  TextLink,
} from '@ovotech/nebula';
import { AnalyticsClick, useAnalytics } from '@ovotech/ui-tools';
import React, { useState, useMemo, FormEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { EVAnytimeEligibilitySectionHeader } from './EVAnytimeEligibilitySectionHeader';
import { RequiredFieldsError } from './types';
import { EV } from '@/src/constants/analytics';
import { State } from '@/src/types/State';
import { ReactComponent as EVCarIcon } from '@/src/resources/car.svg';
import { ReactComponent as LinkIcon } from '@/src/resources/link.svg';
import { ReactComponent as SolarPanelIcon } from '@/src/resources/solar-panel.svg';
import { mqMediumDown } from '@/src/utils/mediaQuery';
import { EVAnytimeStepProps } from '../../../types';
import {
  updateCarMake,
  updateCarModel,
  updateCarYear,
  updateInternetAccess,
  updateSolarPanels,
} from '@/src/redux/ducks/evs/evAnytime';
import {
  makeOptions,
  yearOptions,
  isNotListedSelected,
  modelOptions,
  VEHICLE_ENQUIRY_LINK,
} from '@monovo/ev-anytime';

const CardContentWrapper = styled.div`
  max-width: 600px;
`;

const SectionContainer = styled.div`
  margin-bottom: 25px;
`;

const SectionDivider = styled.div`
  ${({ theme: { space } }) => `
    height: 0;
    width: 100%;
    margin: ${space[6]} 0;
    border-bottom: 1px solid #D1D6DE
  `}
`;

const StyledP = styled(P)`
  color: #2f3749;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  ${mqMediumDown(`
    flex-direction: column-reverse;
  `)};
`;

const containsTrueValue = (values: Record<string, boolean | undefined>) =>
  Object.values(values).some(v => v);

export const EVAnytimeEligibilityQuestions = ({
  movePrevious,
  moveNext,
}: EVAnytimeStepProps) => {
  const dispatch = useDispatch();
  const { dispatch: analyticsDispatch } = useAnalytics();
  const { smartChargerSelection } = useSelector(
    ({ evAnytime }: State) => evAnytime,
  );

  const [make, setMake] = useState<string | undefined>('');
  const [model, setModel] = useState<string | undefined>('');
  const [year, setYear] = useState<string | undefined>('');
  const [hasInternetAccess, setHasInternetAccess] = useState<
    string | undefined
  >(undefined);
  const [hasSolarPanels, setHasSolarPanels] = useState<string | undefined>(
    undefined,
  );
  const [formErrors, setFormErrors] = useState<RequiredFieldsError>({
    make: false,
    model: false,
    year: false,
    hasSolarPanels: false,
    hasInternetAccess: false,
  });

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: EV.ELIGIBILITY
        .EVS_OVO_CHARGE_ANYTIME_ELIGIBILITY_HOME_SET_UP_VIEWED,
      properties: { chargerName: smartChargerSelection },
    });
    // `dispatch` method is not memoized and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartChargerSelection]);

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: 'EVS - EVS OVO Charge Anytime D2V Home Set Up Viewed',
      properties: { chargerName: smartChargerSelection },
    });
    // `dispatch` method is not memoized and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartChargerSelection]);

  const models = useMemo(() => {
    if (!make) return [];
    return modelOptions(make);
  }, [make]);

  const isMakeListed = !isNotListedSelected(make);
  const isModelListed = !isNotListedSelected(model);

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const errors = {
      make: !make,
      model: isMakeListed && !model,
      year: isMakeListed && isModelListed && !year,
      hasSolarPanels: !hasSolarPanels,
      hasInternetAccess: !hasInternetAccess,
    };

    const formHasErrors = containsTrueValue(errors);

    setFormErrors(errors);

    if (formHasErrors) return;

    analyticsDispatch({
      type: 'click',
      name: EV.ELIGIBILITY
        .EVS_OVO_CHARGE_ANYTIME_ELIGIBILITY_HOME_SET_UP_SUBMIT_CLICKED,
      properties: {
        smartChargerSelection: smartChargerSelection,
        make: make,
        model: model,
        year: year,
        internetAccess: hasInternetAccess,
        solarPanels: hasSolarPanels,
      },
    });

    analyticsDispatch({
      type: 'click',
      name: 'EVS - EVS OVO Charge Anytime D2V Home Set Up submit clicked',
      properties: {
        make,
        model,
        year,
        hasInternetAccess,
        hasSolarPanels,
      },
    });

    moveNext();
  };

  const onMakeValueChange = (value: string | undefined) => {
    if (value && formErrors.make) {
      setFormErrors(existingErrors => ({ ...existingErrors, make: false }));
    }

    setMake(value);
    setModel('');
    setYear('');

    dispatch(updateCarMake(value));
    dispatch(updateCarModel(''));
    dispatch(updateCarYear(''));
  };

  const onModelValueChange = (value: string | undefined) => {
    if (value && formErrors.model) {
      setFormErrors(existingErrors => ({ ...existingErrors, model: false }));
    }

    setModel(value);
    setYear('');
    dispatch(updateCarModel(value));
    dispatch(updateCarYear(''));
  };

  const onYearValueChange = (value: string | undefined) => {
    if (value && formErrors.year) {
      setFormErrors(existingErrors => ({ ...existingErrors, year: false }));
    }

    setYear(value);
    dispatch(updateCarYear(value));
  };

  const onHasInternetAccessValueChange = (value: string | undefined) => {
    if (value && formErrors.hasInternetAccess) {
      setFormErrors(existingErrors => ({
        ...existingErrors,
        hasInternetAccess: false,
      }));
    }
    const internetAccess = value === 'yes';
    setHasInternetAccess(value);
    dispatch(updateInternetAccess(internetAccess));
  };

  const onHasSolarPanelsValueChange = (value: string | undefined) => {
    if (value && formErrors.hasSolarPanels) {
      setFormErrors(existingErrors => ({
        ...existingErrors,
        hasSolarPanels: false,
      }));
    }
    const solarPanels = value === 'yes';
    setHasSolarPanels(value);
    dispatch(updateSolarPanels(solarPanels));
  };

  const yearOfManufactureHint = (
    <>
      <P style={{ color: 'GrayText' }}>
        If you're unsure, use{' '}
        <TextLink opensInNewWindow href={VEHICLE_ENQUIRY_LINK}>
          this tool
        </TextLink>{' '}
        to find out.
      </P>
    </>
  );

  return (
    <form onSubmit={onSubmit}>
      <Card>
        <CardContentWrapper>
          <SectionContainer>
            <EVAnytimeEligibilitySectionHeader Icon={EVCarIcon}>
              What EV do you have?
            </EVAnytimeEligibilitySectionHeader>
            <StyledP>
              At the moment Charge Anytime is only compatible with a number of
              EVs, but we’re constantly working on extending the list of
              eligible vehicles.
            </StyledP>
            <Margin vertical={3}>
              <SelectField
                id="make"
                name="make"
                label="Make"
                value={make}
                error={formErrors.make && 'Please select a vehicle make'}
                onValueChange={onMakeValueChange}
              >
                <option disabled value="">
                  Select make...
                </option>
                {makeOptions.map(make => (
                  <option id={make.value} value={make.value} key={make.value}>
                    {make.label}
                  </option>
                ))}
              </SelectField>
            </Margin>
            {isMakeListed && (
              <Margin vertical={3}>
                <SelectField
                  id="model"
                  name="model"
                  label="Model"
                  value={model}
                  error={formErrors.model && 'Please select a vehicle model'}
                  onValueChange={onModelValueChange}
                >
                  <option disabled value="">
                    Select model...
                  </option>
                  {models.map(model => (
                    <option
                      id={model.value}
                      value={model.value}
                      key={model.value}
                    >
                      {model.label}
                    </option>
                  ))}
                </SelectField>
              </Margin>
            )}
            {isMakeListed && isModelListed && (
              <Margin vertical={3}>
                <SelectField
                  id="year"
                  name="year"
                  label="Year of manufacture"
                  data-testid="year"
                  hint={yearOfManufactureHint}
                  value={year}
                  error={
                    formErrors.year && 'Please select the year of manufacture'
                  }
                  onValueChange={onYearValueChange}
                >
                  <option disabled value="">
                    Select year...
                  </option>
                  {yearOptions.map(option => (
                    <option
                      id={`${option.value}`}
                      value={option.value}
                      key={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </SelectField>
              </Margin>
            )}
          </SectionContainer>

          <SectionContainer>
            <EVAnytimeEligibilitySectionHeader Icon={LinkIcon}>
              Can your EV access the internet when you charge at home via Wi-Fi
              or mobile data?
            </EVAnytimeEligibilitySectionHeader>

            <StyledP>
              Your EV needs to be able to communicate with Charge Anytime via
              internet.
            </StyledP>
            <RadioField
              label=""
              name="hasInternetAccess"
              error={
                formErrors.hasInternetAccess &&
                'Please tell us if your vehicle can access the internet'
              }
              onValueChange={onHasInternetAccessValueChange}
            >
              <Radio
                id="hasInternetAccess"
                label="My EV can access the internet"
                value="yes"
              />
              <Radio
                id="hasNoInternetAccess"
                label="My EV doesn’t have access to the internet"
                value="no"
              />
            </RadioField>
          </SectionContainer>

          <SectionDivider />

          <SectionContainer>
            <EVAnytimeEligibilitySectionHeader Icon={SolarPanelIcon}>
              Do you have solar panels at home?
            </EVAnytimeEligibilitySectionHeader>

            <StyledP>
              At the moment solar panels are not supported, but we’re working on
              making this available soon.
            </StyledP>
            <RadioField
              label=""
              name="hasSolarPanels"
              error={
                formErrors.hasSolarPanels &&
                'Please tell us if you have solar panels at home'
              }
              onValueChange={onHasSolarPanelsValueChange}
            >
              <Radio
                id="hasSolarPanels"
                label="I have solar panels at home"
                value="yes"
              />
              <Radio
                id="hasNoSolarPanels"
                label="I don’t have solar panels at home"
                value="no"
              />
            </RadioField>
          </SectionContainer>

          <ButtonsContainer>
            <AnalyticsClick
              name={
                EV.ELIGIBILITY
                  .EVS_OVO_CHARGE_ANYTIME_ELIGIBILITY_HOME_SET_UP_BACK_CLICKED
              }
            >
              <SecondaryCTAButton
                style={{ textAlign: 'center' }}
                type="button"
                data-event-name={
                  'Click-EVS - EVS OVO Charge Anytime D2V Home Set Up back clicked [Orion Web]'
                }
                onClick={movePrevious}
              >
                Previous: tell us about your EV charger
              </SecondaryCTAButton>
            </AnalyticsClick>
            <PrimaryCTAButton type="submit">
              Submit your answers
            </PrimaryCTAButton>
          </ButtonsContainer>
        </CardContentWrapper>
      </Card>
    </form>
  );
};
