import { Heading3, Heading4 } from '@ovotech/nebula';
import { withComponents } from '@ovotech/ui-tools';
import { Components } from '@ovotech/ui-tools/dist/copy/types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom-v5';
import { bindActionCreators, Dispatch } from 'redux';
import ProductsBolton from './components/ProductsBolton';
import ProductsBundle from './components/ProductsBundle';
import selector from './utils/selector';

import { Callout } from '@/src/components';

import { PRODUCT_DISPLAY_NAMES, PRODUCT_IDS } from '@/src/constants/products';
import { ROUTE_EV_ANYTIME } from '@/src/constants/routes';
import { PRODUCTS_SECTION_ID } from '@/src/constants/sectionIds';

import { routes } from '@/src/pages/EVs/OvoDriveAnytime';
import {
  cancelProductStart,
  getProductsStart,
} from '@/src/redux/ducks/products/products';

import { Action } from '@/src/types/Action';
import { BoltonInstance, ProductId } from '@/src/types/Products';
import { ProductsUpdate, State } from '@/src/types/State';
import {
  chargeAnytimeProducts,
  isChargeAnytimeProduct,
} from '@/src/pages/EVs/hooks/useChargeAnytimeBoltonState';

type Props = ReturnType<typeof selector> & {
  getProductsStart: typeof getProductsStart;
  cancelProductStart: typeof cancelProductStart;
  components: Components;
} & RouteComponentProps;

type CancellationSuccessProps = {
  cancellationStates: ProductsUpdate;
};

const cancellableProducts: Array<ProductId> = [
  PRODUCT_IDS.greenElectricity,
  PRODUCT_IDS.greenGas,
  PRODUCT_IDS.greenBundle,
  PRODUCT_IDS.ovoFoundation,
  PRODUCT_IDS.greenerEnergy,
  PRODUCT_IDS.greenerElectricity,
  ...chargeAnytimeProducts,
];
const defaultCancel = {
  isUpdating: false,
  error: false,
  success: false,
};

const CancellationSuccess = ({
  cancellationStates,
}: CancellationSuccessProps) => (
  <div data-testid="cancellation-success">
    {Object.keys(cancellationStates).map(
      // @ts-ignore Object.keys iterates over keyof, get the hint TS
      (key: keyof ProductsUpdate) =>
        cancellationStates[key] &&
        cancellationStates[key]!.success && (
          <Callout key={key} data-testid="products-cancellation-success">
            <Heading3 style={{ marginBottom: '0' }}>
              Your {PRODUCT_DISPLAY_NAMES[key]} Upgrade has been cancelled.
            </Heading3>
            <Heading4>
              Your subscription will terminate on your next billing date.
            </Heading4>
          </Callout>
        ),
    )}
  </div>
);

class Products extends Component<Props> {
  componentDidMount() {
    this.props.getProductsStart();
  }

  cancelAddon = (productId: ProductId) => {
    if (isChargeAnytimeProduct(productId)) {
      this.props.history.push({
        pathname: `${ROUTE_EV_ANYTIME}${routes.disconnect}`,
      });
    } else {
      this.props.cancelProductStart(productId);
    }
  };

  render() {
    const {
      boltons,
      bundle,
      cancellationStates,
      showSuccessMessage,
      components: { Row, Col, Margin },
    } = this.props;

    if ((!boltons || !boltons.length) && !bundle && !showSuccessMessage) {
      return null;
    }

    return (
      <Margin id={PRODUCTS_SECTION_ID} top={12}>
        <Row isNested>
          {showSuccessMessage ? (
            <Col>
              <Margin bottom={4}>
                <CancellationSuccess cancellationStates={cancellationStates} />
              </Margin>
            </Col>
          ) : null}
          {bundle ? (
            <Col key={bundle.productId}>
              <Margin bottom={boltons.length ? 6 : undefined}>
                <ProductsBundle
                  bundle={bundle}
                  canCancel={cancellableProducts.includes(bundle.productId)}
                  onCancel={() => this.cancelAddon(bundle.productId)}
                  cancellation={defaultCancel}
                />
              </Margin>
            </Col>
          ) : null}

          {boltons.map((bolton: BoltonInstance, index: number) => (
            <Col
              key={bolton.productId}
              medium={bolton.productId === PRODUCT_IDS.ovoFoundation ? 12 : 6}
            >
              <Margin bottom={boltons.length === index + 1 ? undefined : 6}>
                <ProductsBolton
                  key={bolton.productId}
                  bolton={bolton}
                  canCancel={cancellableProducts.includes(bolton.productId)}
                  onCancel={() => this.cancelAddon(bolton.productId)}
                  cancellation={
                    cancellationStates[bolton.productId] || defaultCancel
                  }
                />
              </Margin>
            </Col>
          ))}
        </Row>
      </Margin>
    );
  }
}

const mapStateToProps = ({ products }: State) =>
  selector(products.activated, products.cancel);

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
  bindActionCreators(
    {
      getProductsStart,
      cancelProductStart,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withComponents(withRouter(Products)));
