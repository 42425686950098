import React from 'react';
import { HeaderTitle, HeaderDescription } from './EVAnytimeHeader.styled';

type HeaderProps = {
  title: string;
  showDescription?: boolean;
};

export const EVAnytimeHeader = (props: HeaderProps) => {
  const { title, showDescription } = props;
  return (
    <>
      <HeaderTitle>{title}</HeaderTitle>
      {showDescription && (
        <HeaderDescription>
          A supercharged add-on for your OVO Energy plan
        </HeaderDescription>
      )}
    </>
  );
};

EVAnytimeHeader.defaultProps = {
  showDescription: true,
};
