import React, { useState } from 'react';
import {
  EVAnytimeHeader,
  EVAnytimeIsUnavailable,
  EVAnytimeSmartCharger,
  EVAnytimeEligibilityQuestions,
  OvoDriveAnytimeEligibilityConfirmation,
} from '../../components/EVAnytime';
import { PageWithNavigation } from '@/src/components';
import { ALLOW_ANYTIME_SIGNUP } from '@/src/constants/feature-flags';
import { useFeature } from '@/src/utils/optimizely';
import { EVAnytimeStepProps } from '../../types';

const renderPageContent = (
  PageStep: number,
  moveNext: () => void,
  movePrevious: () => void,
  movePreviousTwo: () => void,
) => {
  const props: EVAnytimeStepProps = { moveNext, movePrevious, movePreviousTwo };
  const steps = [
    <EVAnytimeSmartCharger {...props} key={1} />,
    <EVAnytimeEligibilityQuestions {...props} key={2} />,
    <OvoDriveAnytimeEligibilityConfirmation {...props} key={3} />,
  ];

  return steps[PageStep - 1];
};

export const EVAnytimeEligibilityPage = () => {
  const [pageStep, setPageStep] = useState(1);
  const [allowAnytimeSignUp] = useFeature(ALLOW_ANYTIME_SIGNUP);

  const moveNext = () => {
    setPageStep(previousState => previousState + 1);
  };

  const movePrevious = () => {
    setPageStep(previousState => previousState - 1);
  };

  const movePreviousTwo = () => {
    setPageStep(previousState => previousState - 2);
  };

  return (
    <PageWithNavigation title="EV Charge Anytime - Eligibility">
      {allowAnytimeSignUp ? (
        <>
          <EVAnytimeHeader title="Check your setup" showDescription={false} />
          {renderPageContent(pageStep, moveNext, movePrevious, movePreviousTwo)}
        </>
      ) : (
        <EVAnytimeIsUnavailable />
      )}
    </PageWithNavigation>
  );
};
