import { Margin, P, PrimaryCTALink, Small } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import React, { useEffect, useState } from 'react';
import { DirectDebitCalculatorSettingsAndGraph } from './DirectDebitCalculatorSettingsAndGraph';
import { ErrorPanel } from './ErrorPanel';
import { LoadingPanel } from './LoadingPanel';
import {
  useBillingQuery,
  useContractsQuery,
  useDirectDebitQuery,
} from '@/src/api';
import { PageWithNavigation, RouterLink, Section } from '@/src/components';
import { DIRECT_DEBIT_CALCULATOR as DIRECT_DEBIT_CALCULATOR_ANALYTICS } from '@/src/constants/analytics';
import { DD_CALCULATOR } from '@/src/constants/analytics-subjects';
import { ROUTE_ADJUST_DD_AMOUNT_CALCULATION } from '@/src/constants/routes';
import { DD_CALCULATOR_SECTION_ID } from '@/src/constants/sectionIds';
import { Pounds } from '@/src/types/Response';
import { getPoundValue } from '@/src/utils/money';

export const DirectDebitCalculator = () => {
  const { accountId } = useAccountContext();

  const latestBilling = useBillingQuery(accountId ?? '', 'latest'); // TODO: move `useBillingQuery` into orion ui codebase and migrate to React Query
  const contracts = useContractsQuery(accountId);
  const directDebit = useDirectDebitQuery(accountId);

  const [newDirectDebitAmount, setNewDirectDebitAmount] = useState<string>(
    getPoundValue(
      directDebit?.data?.amount ?? { amount: '10.00', currencyUnit: 'GBP' },
    ),
  );

  useEffect(() => {
    if (directDebit?.data?.amount) {
      setNewDirectDebitAmount(getPoundValue(directDebit?.data?.amount));
    }
  }, [directDebit?.data?.amount]);

  const hasDirectDebit = directDebit.data?.amount !== null;
  const ownsDirectDebit = directDebit.data?.customerOwnsMandate !== false;

  const isLoading =
    directDebit.isLoading ||
    contracts.status !== 'success' ||
    latestBilling.status !== 'data';

  const balance: Pounds | null =
    latestBilling.status === 'data' &&
    latestBilling.data?.billingSummary !== null &&
    'latestPeriod' in latestBilling.data.billingSummary
      ? latestBilling.data.billingSummary.latestPeriod.data.closingBalance
      : null;

  const isError =
    directDebit.isError ||
    contracts.status === 'error' ||
    latestBilling.status === 'error' ||
    balance === null;

  const analyticsProps = JSON.stringify({
    currentDirectDebit: directDebit?.data?.amount?.amount,
    ownsDd: ownsDirectDebit,
    hasDirectDebit: hasDirectDebit,
    newDirectDebit: newDirectDebitAmount,
  });

  return (
    <PageWithNavigation
      title="Direct Debit calculator"
      analytics={{ name: DD_CALCULATOR }}
    >
      <Section id={DD_CALCULATOR_SECTION_ID} data-testid="dd-calc">
        <Margin bottom={6}>
          {isLoading ? (
            <LoadingPanel />
          ) : isError ? (
            <ErrorPanel />
          ) : (
            balance !== null &&
            contracts.status === 'success' && (
              <DirectDebitCalculatorSettingsAndGraph
                directDebitAmount={directDebit?.data?.amount}
                balance={balance}
                contracts={contracts.data}
                newDirectDebitAmount={newDirectDebitAmount}
                setNewDirectDebitAmount={setNewDirectDebitAmount}
              />
            )
          )}
          {hasDirectDebit &&
            (ownsDirectDebit ? (
              <PrimaryCTALink
                to={ROUTE_ADJUST_DD_AMOUNT_CALCULATION}
                as={RouterLink}
                data-event-name={
                  DIRECT_DEBIT_CALCULATOR_ANALYTICS.CLICKED_CHANGE_DIRECT_DEBIT_AMOUNT
                }
                data-event-props={analyticsProps}
              >
                <span
                  data-event-name={
                    DIRECT_DEBIT_CALCULATOR_ANALYTICS.CLICKED_CHANGE_DIRECT_DEBIT_AMOUNT
                  }
                  data-event-props={analyticsProps}
                >
                  Change Direct Debit amount
                </span>
              </PrimaryCTALink>
            ) : (
              <P>
                <Small>
                  Sorry, this Direct Debit can only be changed by the person who
                  set it up.
                </Small>
              </P>
            ))}
        </Margin>
      </Section>
    </PageWithNavigation>
  );
};
