import React from 'react';
import { HomePlanSvtPlusBCQ1PromoOffer } from './HomePlanSvtPlusBCQ1PromoOffer';
import { HomePlanPromoOffer } from './HomePlanPromoOffer';
import { HomePlanSvtPlusBC } from './HomePlanSvtPlusBC';
import { HomePlanQ1Offer } from '@/src/pages/Offers/components/cards/HomePlanQ1Offer';
import { HomePlanFixedPlusBC } from './HomePlanFixedPlusBC';
import { HomePlanFixedPlusBCQ1PromoOffer } from './HomePlanFixedPlusBCQ1PromoOffer';

interface HomePlanOfferProps {
  showQ1Offer?: boolean;
  showHomePlanSvtOffer?: boolean;
  showQ1HomePlanOffer?: boolean;
  showQ4HomePlanOffer?: boolean;
  showHomePlanFixedOffer?: boolean;
  accountId: string;
}

export const HomePlanOffer = ({
  showHomePlanSvtOffer,
  showQ1HomePlanOffer,
  showHomePlanFixedOffer,
  accountId,
}: HomePlanOfferProps) => {
  if (showQ1HomePlanOffer && !showHomePlanSvtOffer && !showHomePlanFixedOffer) {
    return <HomePlanQ1Offer accountId={accountId} />;
  }

  if (showQ1HomePlanOffer && showHomePlanSvtOffer && !showHomePlanFixedOffer) {
    return <HomePlanSvtPlusBCQ1PromoOffer accountId={accountId} />;
  }

  if (showQ1HomePlanOffer && showHomePlanFixedOffer && !showHomePlanSvtOffer) {
    return <HomePlanFixedPlusBCQ1PromoOffer accountId={accountId} />;
  }

  if (!showQ1HomePlanOffer && showHomePlanFixedOffer && !showHomePlanSvtOffer) {
    return <HomePlanFixedPlusBC accountId={accountId} />;
  }

  if (showHomePlanSvtOffer && !showHomePlanFixedOffer) {
    return <HomePlanSvtPlusBC accountId={accountId} />;
  }

  return <HomePlanPromoOffer accountId={accountId} />;
};
