import { Card, Heading2, Li, Margin, P, Ul } from '@ovotech/nebula';

import React from 'react';
import Media from 'react-media';
import { BuyNowButton } from './BuyNowButton';
import {
  CardWrapper,
  HeaderWrapper,
  FooterWrapper,
  StyledImageWrapper,
  StyledMobileImageWrapper,
  ListContainer,
} from './CutWasteCard.styled';

import {
  CutWasteDescriptionFootnote,
  CutWasteSecondDescriptionFootnote,
  CutWasteTitleFootnote,
} from './footnotes';
import ImgMobile from '@/src/resources/cut-waste-mobile.png';
import Img from '@/src/resources/cut-waste.png';
import { breakpoints } from '@/src/utils/mediaQuery';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { AnalyticsClick } from '@ovotech/ui-tools';

export const CutWasteCard = () => {
  return (
    <CardWrapper>
      <Card>
        <Media query={`${breakpoints.mediumUp}`}>
          <StyledImageWrapper src={Img} />
        </Media>
        <Media query={`${breakpoints.mediumDown}`}>
          <StyledMobileImageWrapper src={ImgMobile} />
        </Media>
        <HeaderWrapper>
          <Heading2>
            Tips to help you cut waste and save energy
            <CutWasteTitleFootnote></CutWasteTitleFootnote>'
          </Heading2>
          <P>
            We’ve teamed up with tado° to make their thermostats even better for
            OVO members when you connect your tado° to your OVO account. To get
            the most out of this you’ll need a gas smart meter
            <CutWasteDescriptionFootnote />, it’s not essential but it will
            allow you to:
          </P>
        </HeaderWrapper>
        <ListContainer>
          <Ul>
            <Li>Get personalised heat saving tips</Li>
            <Li>See how much your heating costs you each day</Li>
            <Li>See exactly how much it costs to heat your home</Li>
          </Ul>
        </ListContainer>
        <FooterWrapper>
          <P>
            Our team is working on new ways to make this better all the time
            <CutWasteSecondDescriptionFootnote />. By getting your tado° now,
            you’re helping to shape the future of this clever tech.
          </P>
        </FooterWrapper>
        <Margin top={4}>
          <AnalyticsClick
            name={
              SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.PRODUCT_PAGE.CLICKED.BUY_NOW
            }
            properties={{ buttonPlacement: 'tips' }}
          >
            <BuyNowButton />
          </AnalyticsClick>
        </Margin>
      </Card>
    </CardWrapper>
  );
};
