export enum ForecastOption {
  END_OF_CONTRACTS = 'END_OF_CONTRACTS',
  NEXT_12_MONTHS = 'NEXT_12_MONTHS',
  BY_31_OF_MARCH = 'BY_31_OF_MARCH',
}

export enum GraphType {
  MONTHLY_END_BALANCE = 'MONTHLY_END_BALANCE',
  MONTHLY_ESTIMATED_COST = 'MONTHLY_ESTIMATED_COST',
}
